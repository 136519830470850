import classNames from 'classnames';
import React, {cloneElement} from 'react';

import Template1Img from '../../../assets/images/template1.png';
import Template2Img from '../../../assets/images/template2.png';
import Template3Img from '../../../assets/images/template3.png';
import Template4Img from '../../../assets/images/template4.png';
import styles from './ImageWithCardDesign.module.scss';
import {Props} from './ImageWithCardDesign.types';
const ImageMap = {
  template1: Template1Img,
  template2: Template2Img,
  template3: Template3Img,
  template4: Template4Img,
};
export const ImageWithCardDesign = ({
  cardDesign,
  children,
  holidayCards,
}: Props) => {
  if (cardDesign === undefined) {
    return <>{children}</>;
  }
  const idx = holidayCards.findIndex(
    item => item.name === (cardDesign ?? 'NA')
  );

  if (idx === 0) {
    return <div className={styles['null-card']}>{children}</div>;
  }
  const _children = cloneElement(children as React.ReactElement, {
    className: classNames(
      styles['scene-image'],
      idx < 3 && styles['scene-rotate']
    ),
  });
  return (
    <div className={styles.cardDesign}>
      {_children}
      <div
        className={styles['card-design-box']}
        style={{
          backgroundImage: cardDesign
            ? `url(${ImageMap[cardDesign as keyof typeof ImageMap]})`
            : '',
          backgroundPosition: `calc(-100% * ${idx - 1}) 0`,
        }}
      />
    </div>
  );
};
