// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Draft} from 'modules/draft/models/Draft';
import {SceneAsset} from 'modules/scene-asset/models/SceneAsset';
import {SceneAssetType} from 'modules/scene-asset/types';
import {TaskType as StoryboardTaskType} from 'modules/storyboard/types';
import {Task} from 'modules/task/models/Task';

import {
  BilingualStoryClosedScene,
  BilingualStorySceneJSON,
  TaskType,
} from '../types';
import {isSceneTask, processSceneAssets} from '../utils';
import {BaseScene} from './BaseScene';

export class BilingualStoryScene extends BaseScene {
  static fromJSON(
    {
      id,
      type,
      prompt,
      last_prompt,
      subtitle,
      native_subtitle,
      shot_type,
      characters,
      image,
      video,
      draft,
      task,
      animate_prompt,
      current_asset,
      assets,
    }: BilingualStorySceneJSON,
    tasks?: Task<StoryboardTaskType>[]
  ) {
    const _task = task && tasks ? tasks.find(t => t.id === task) : undefined;
    if (task && !_task) {
      throw new Error(`Task with id ${task} not found`);
    }
    if (_task && !isSceneTask(_task)) {
      throw new Error(`Invalid task type: ${_task.type}`);
    }
    const {currentAsset, assetsList} = processSceneAssets({
      image,
      video,
      current_asset,
      assets,
    });
    return new BilingualStoryScene(
      id,
      type,
      currentAsset,
      assetsList,
      prompt,
      last_prompt,
      subtitle,
      native_subtitle,
      shot_type,
      characters,
      draft ? Draft.fromJSON(draft) : undefined,
      _task as Task<TaskType> | undefined,
      animate_prompt
    );
  }

  constructor(
    id: string,
    type: string,
    currentAsset?: SceneAsset<SceneAssetType>,
    assets?: SceneAsset<SceneAssetType>[],
    prompt?: string,
    lastPrompt?: string,
    subtitle?: string,
    readonly nativeSubtitle?: string,
    shotType?: number,
    characters?: BilingualStorySceneJSON['characters'],
    draft?: Draft,
    task?: Task<TaskType>,
    animatePrompt?: string
  ) {
    super(
      id,
      type,
      currentAsset,
      assets,
      prompt,
      lastPrompt,
      subtitle,
      shotType,
      characters,
      draft,
      task,
      animatePrompt
    );
  }

  isValid() {
    if (!this.currentAsset) return false;
    return !(
      !this.subtitle &&
      !this.nativeSubtitle &&
      this.currentAsset.type === 'color' &&
      !this.draft?.isValid()
    );
  }

  patch(
    data: Partial<{
      [K in
        | 'prompt'
        | 'subtitle'
        | 'nativeSubtitle'
        | 'draft'
        | 'task'
        | 'animatePrompt'
        | 'currentAsset'
        | 'assets']: BilingualStoryScene[K];
    }>
  ) {
    const {
      id,
      type,
      prompt,
      lastPrompt,
      subtitle,
      nativeSubtitle,
      shotType,
      characters,
      draft,
      task,
      animatePrompt,
      currentAsset,
      assets,
    } = Object.assign({}, this, data);
    return new BilingualStoryScene(
      id,
      type,
      currentAsset,
      assets,
      prompt,
      lastPrompt,
      subtitle,
      nativeSubtitle,
      shotType,
      characters,
      draft,
      task,
      animatePrompt
    );
  }

  toJSON(): BilingualStorySceneJSON {
    return {
      ...super.toJSON(),
      native_subtitle: this.nativeSubtitle,
    };
  }

  toClosed(): BilingualStoryClosedScene {
    return {
      ...super.toClosed(),
      native_subtitle: this.nativeSubtitle,
    };
  }
}
