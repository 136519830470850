// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  FrontErrorEnum,
  PoseImagePostTaskParam,
  staticCombiner,
} from 'api/frontend';
import * as server from 'api/server';
import axios from 'axios';
import {AnimateInfo} from 'components/AnimateSelect';
import {combine} from 'components/Combine';
import {ActionType, InsertType} from 'components/SceneList/SceneActionMenu';
import {useAPI} from 'contexts/APIContext';
import {AppError, ErrorType, useError} from 'contexts/ErrorContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {useUserContext} from 'contexts/UserContext';
import levenshtein from 'js-levenshtein';
import {useVisible} from 'lib/hooks';
import {getImageByUrl, getImageSizeByUrl} from 'lib/image';
import {formatAspectRatio} from 'lib/ratio';
import {noop} from 'lodash';
import {
  CreateTask,
  FindTask,
  PollingCallback,
  useTaskManager,
} from 'modules/ai-frontend/services';
import {StyleRefImages, TaskType} from 'modules/ai-frontend/types';
import {CustomizedCharacter} from 'modules/character/models/CustomizedCharacter';
import {
  BilingualDialogueIdeaPromptPolicy,
  BilingualStoryIdeaPromptPolicy,
  GeneralStoryIdeaPromptPolicy,
  PromptPolicy,
  ScriptPromptPolicy,
  ShortVideoIdeaPromptPolicy,
} from 'modules/project/models/PromptPolicy';
import {ProjectType, Size} from 'modules/project/types';
import {PROFICIENCY_LEVEL_OPTIONS, TONE_OPTIONS} from 'modules/project/utils';
import {BilingualDialogueScene} from 'modules/scene/models/BilingualDialogueScene';
import {BilingualStoryScene} from 'modules/scene/models/BilingualStoryScene';
import {HolidayGreetingScene} from 'modules/scene/models/HolidayGreetingScene';
import {Scene} from 'modules/scene/models/Scene';
import {AnimateOptionTypeEnums} from 'modules/scene/utils';
import {BilingualDialogueStoryboard} from 'modules/storyboard/models/BilingualDialogueStoryboard';
import {BilingualStoryStoryboard} from 'modules/storyboard/models/BilingualStoryStoryboard';
import {HolidayGreetingStoryboard} from 'modules/storyboard/models/HolidayGreetingStoryboard';
import {Storyboard} from 'modules/storyboard/models/Storyboard';
import {
  MergeTaskCallbackParams,
  useStoryboard,
} from 'modules/storyboard/services';
import {
  AFTaskType,
  ExecuteTaskParams,
  StoryboardParams,
  TaskParams,
  TaskType as StoryboardTaskType,
} from 'modules/storyboard/types';
import {
  checkoutAFTaskType,
  checkoutStoryboardAction,
  checkoutStoryboardTemplate,
  makeEmptyScene,
  storyboardHasStyle,
} from 'modules/storyboard/utils';
import {useUserAsset} from 'modules/user-asset/services';
import {nanoid} from 'nanoid';
import {useAwsFileUpload} from 'pages/WorkspacePage/modules';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {requestInvisibleCaptchaToken} from 'utils/captcha';
import {drawImage} from 'utils/draw-image';

import {StoryboardPage} from './StoryboardPage';
import {
  HookParam,
  HookReturn,
  LoadingType,
  PreSubmitTask,
  PreSubmitTaskParams,
} from './StoryboardPage.type';

function useHook({
  projectId,
  storyboard,
  updateStoryboard,
  onBack,
}: HookParam<ProjectType>): HookReturn<ProjectType> {
  const [selectedSceneIndex, setSelectedSceneIndex] = useState(0);
  const [loading, setLoading] = useState<LoadingType>(false);
  const [newSceneIds, setNewSceneIds] = useState<string[]>([]);
  const [toastVisible, hideToast, showToast, errorInfo] =
    useVisible<
      [
        (
          | 'overloaded'
          | 'sceneError'
          | 'animationAllOverloaded'
          | 'animationOverloaded'
          | 'pendingTaskLimit'
        ),
        unknown?
      ]
    >(false);
  const {updateScene} = useStoryboard(updateStoryboard);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const {report} = useError();
  const {getStyleDataByName} = useResourceManager();
  const onAddNewScenes = useCallback((newSceneIds: string[]) => {
    setNewSceneIds(newSceneIds);
    const timer = timerRef.current;
    if (timer) clearTimeout(timer);
    timerRef.current = setTimeout(() => {
      setNewSceneIds(newSceneIds.filter(id => !newSceneIds.includes(id)));
    }, 3000);
  }, []);
  const {uploadFile} = useAwsFileUpload(noop, noop);
  const ratio = useMemo(
    () => formatAspectRatio(storyboard.size),
    [storyboard.size]
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const getImage = useCallback(async (objectKey: string) => {
    const res = await server.getSignedUrl([{fileName: objectKey}]);
    const src = res.data[0];
    if (!src) throw new Error(`Failed to get ${objectKey} signed url`);
    const {width, height} = await getImageSizeByUrl(src);
    return {src, width, height};
  }, []);

  const {getImageWithObjectKey, refreshImage} = useUserAsset({
    getImage,
  });

  const saveScene = useCallback(
    async (sceneId: string) => {
      const currentScene = storyboard.scenes?.find(
        scene => scene.id === sceneId
      );
      if (
        !currentScene ||
        !currentScene.draft?.isValid() ||
        !currentScene.currentAsset ||
        currentScene.draft.image
      )
        return;
      const image =
        currentScene.currentAsset.type === 'image'
          ? await getImageByUrl(staticCombiner(currentScene.currentAsset.value))
          : undefined;
      const width = storyboard.size[0];
      const height = storyboard.size[1];
      const objects = await Promise.all(
        currentScene.draft.objects
          .filter(o => o.isValid())
          .map(async object => {
            const {src} = await getImageWithObjectKey(object.asset!);
            const img = await getImageByUrl(src);
            return {
              image: img,
              x: object.x * width,
              y: object.y * height,
              width: object.width * width,
              height: object.height * height,
            };
          })
      );
      const {file, url} = await drawImage(
        [
          ...(image
            ? [{image, x: 0, y: 0, width: image.width, height: image.height}]
            : []),
          ...objects,
        ],
        width,
        height,
        currentScene.currentAsset.type === 'color'
          ? currentScene.currentAsset.value
          : undefined
      );
      const objectKey = (await uploadFile(
        {file},
        'cdn-st',
        'scene_image'
      )) as string;
      updateStoryboard((prevStoryboard: Storyboard<ProjectType>) => {
        return prevStoryboard.patch({
          scenes: prevStoryboard.scenes?.map(scene => {
            if (scene.id === sceneId)
              return scene.patch({
                draft: currentScene.draft?.patch({
                  image: objectKey,
                }),
              });
            return scene;
          }),
        });
      }, false);
      return {url, objectKey, sceneId};
    },
    [
      getImageWithObjectKey,
      storyboard.scenes,
      storyboard.size,
      updateStoryboard,
      uploadFile,
    ]
  );

  const onNext = useCallback(async () => {
    setLoading('next');
    try {
      const savedList = await Promise.all(
        storyboard.scenes?.map(scene => saveScene(scene.id)) || []
      );
      const savedMap = savedList.reduce(
        (map, saved) =>
          !saved ? map : {...map, [saved.sceneId]: saved.objectKey},
        {} as Record<string, string>
      );
      updateStoryboard((prevStoryboard: Storyboard<ProjectType>) => {
        return prevStoryboard.patch({
          scenes: prevStoryboard.scenes?.map(scene => {
            if (!savedMap[scene.id]) return scene;
            return scene.patch({
              draft: scene.draft?.patch({image: savedMap[scene.id]}),
            });
          }),
          closed: true,
        });
      }, false);
    } catch (error) {
      report(ErrorType.Service, error);
    }
    setLoading(false);
  }, [report, saveScene, storyboard, updateStoryboard]);

  const onInsert = useCallback(
    (
      type: InsertType,
      sceneId: string,
      params?: Partial<Scene<ProjectType>>
    ) => {
      const newScene = makeEmptyScene(storyboard, params);
      updateStoryboard((prevStoryboard: Storyboard<ProjectType>) => {
        if (!prevStoryboard.scenes) return prevStoryboard;
        const currentSceneIndex = prevStoryboard.scenes?.findIndex(
          scene => scene.id === sceneId
        );
        const insertIdx =
          type === 'insert_after'
            ? currentSceneIndex + 1
            : Math.max(currentSceneIndex, 0);
        const newScenes = [
          ...prevStoryboard.scenes.slice(0, insertIdx),
          newScene,
          ...prevStoryboard.scenes.slice(insertIdx),
        ];
        //默认选中新创建的场景
        if (type === 'insert_after') {
          setSelectedSceneIndex(currentSceneIndex + 1);
        } else {
          setSelectedSceneIndex(currentSceneIndex);
        }
        return prevStoryboard.patch({scenes: newScenes});
      }, false);

      onAddNewScenes([...newSceneIds, newScene.id]);
    },
    [newSceneIds, onAddNewScenes, updateStoryboard, storyboard]
  );

  const {executeTask, preSubmitTask} = useNewHook({
    projectId,
    storyboard,
    updateStoryboard,
    showErrorInfo: showToast,
    onAddNewScenes,
    onBack,
    onInsert,
    setSelectedSceneIndex,
  });

  const onAction = useCallback(
    async <T extends InsertType | ActionType | 'regenerate_scene_by_prompt'>(
      type: T,
      sceneId: string,
      insertParams?: T extends InsertType
        ? Partial<Scene<ProjectType>>
        : undefined
    ) => {
      const currentScene = storyboard.scenes?.find(
        scene => sceneId === scene.id
      );
      if (
        !storyboard ||
        !currentScene ||
        !storyboard.scenes ||
        storyboard.scenes.length === 0
      )
        return;
      const styleData = storyboardHasStyle(storyboard)
        ? getStyleDataByName(storyboard.style)
        : undefined;

      switch (type) {
        case 'split_scene':
        case 'regenerate_scene_by_prompt':
          {
            const currentSceneIndex = storyboard.scenes.findIndex(
              scene => scene.id === sceneId
            );
            const params = getCreateStoryboardTaskParam(
              storyboard,
              type,
              currentScene,
              currentSceneIndex,
              styleData && styleData.name,
              styleData && styleData.ref_images
            );
            await executeTask(
              type,
              ...([
                {
                  params: {
                    template: checkoutStoryboardTemplate(storyboard),
                  },
                  data: params,
                },
                [sceneId],
              ] as unknown as ExecuteTaskParams<
                'split_scene' | 'regenerate_scene_by_prompt'
              >)
            );
          }

          break;
        case 'merge_prev_scene':
        case 'merge_next_scene':
          {
            const currentSceneIndex = storyboard.scenes.findIndex(
              scene => scene.id === sceneId
            );
            const otherScene =
              type === 'merge_next_scene'
                ? storyboard.scenes[currentSceneIndex + 1]
                : storyboard.scenes[currentSceneIndex - 1];
            if (
              currentScene.task === undefined &&
              currentScene.subtitle === '' &&
              currentScene.currentAsset!.type === 'color'
            ) {
              onAction('delete_scene', currentScene.id);
            } else if (
              otherScene.task === undefined &&
              otherScene.subtitle === '' &&
              otherScene.currentAsset!.type === 'color'
            ) {
              onAction('delete_scene', otherScene.id);
            } else {
              const params = getCreateStoryboardTaskParam(
                storyboard,
                type,
                currentScene,
                currentSceneIndex,
                styleData && styleData.name,
                styleData && styleData.ref_images
              );
              await executeTask(
                'merge_scenes',
                ...([
                  {
                    params: {
                      template: checkoutStoryboardTemplate(storyboard),
                    },
                    data: params,
                  },
                  [sceneId, otherScene.id],
                ] as unknown as ExecuteTaskParams<'merge_scenes'>)
              );
            }
          }

          break;
        case 'delete_scene':
          {
            // 当场景数量大于1的时候才可以删除场景
            const newScenes =
              storyboard.scenes.length > 1
                ? storyboard.scenes.filter(scene => scene.id !== sceneId)
                : storyboard.scenes;

            updateStoryboard(storyboard.patch({scenes: newScenes}), false);

            const sceneIndex = storyboard.scenes.findIndex(
              scene => scene.id === sceneId
            );

            if (sceneIndex === storyboard.scenes.length - 1) {
              setSelectedSceneIndex(sceneIndex - 1);
            }
          }
          break;
        case 'insert_after':
        case 'insert_before':
          {
            onInsert(type, sceneId, insertParams);
          }
          break;
      }
    },
    [storyboard, getStyleDataByName, executeTask, updateStoryboard, onInsert]
  );

  const generateStoryboardTask = useCallback(
    async (storyboard: Storyboard<ProjectType>, checkWaitTime: boolean) => {
      try {
        const waitSeconds = checkWaitTime
          ? (
              await preSubmitTask(TaskType.Storyboard, {
                template: checkoutStoryboardTemplate(storyboard),
              })
            ).estimatedWaitingTime
          : 0;
        if (waitSeconds > 3600) {
          throw new Error('Overloaded');
        }

        const styleData = storyboardHasStyle(storyboard)
          ? getStyleDataByName(storyboard.style)
          : undefined;
        await executeTask<'generate_storyboard'>(
          'generate_storyboard',
          {
            params: {
              template: checkoutStoryboardTemplate(storyboard),
            },
            data: {
              size: storyboard.size,
              style:
                storyboard instanceof HolidayGreetingStoryboard
                  ? ''
                  : styleData?.name ?? '',
              user_input: {
                prompt: storyboard.prompt ?? '',
                language: storyboard.language,
                native_language:
                  storyboard instanceof BilingualStoryStoryboard ||
                  storyboard instanceof BilingualDialogueStoryboard
                    ? storyboard.nativeLanguage
                    : undefined,

                keep_user_content: storyboard.promptPolicy.keepUserContent,
                paragraph_as_shots:
                  storyboard.promptPolicy instanceof ScriptPromptPolicy &&
                  storyboard.promptPolicy.paragraphAsShots,
                ...getTone(storyboard.promptPolicy),
                ...getProficiencyLevel(storyboard.promptPolicy),
                keywords:
                  storyboard instanceof BilingualStoryStoryboard ||
                  storyboard instanceof BilingualDialogueStoryboard
                    ? storyboard.vocabulary
                    : undefined,
                ...getHolidayParam(storyboard),
              },
              figure_style:
                storyboard instanceof HolidayGreetingStoryboard
                  ? storyboard.figureStyle
                  : undefined,
              holiday_images:
                storyboard instanceof HolidayGreetingStoryboard
                  ? storyboard.images.map(getCompleteImage)
                  : undefined,
              style_ref_images: styleData?.ref_images,
              customized_characters:
                storyboard instanceof BilingualDialogueStoryboard
                  ? storyboard.customizedCharacters
                  : undefined,
            },
          },
          null
        );
      } catch (err) {
        if (err instanceof Error && err.message === 'Overloaded') {
          showToast(['overloaded']);
        } else {
          if (!(err instanceof AppError)) {
            report(ErrorType.Service, err);
          }
          onBack();
        }
      }
    },
    [preSubmitTask, getStyleDataByName, executeTask, showToast, onBack, report]
  );

  const onGenerateAnimation = useCallback(
    async (
      sceneId: string,
      size: Size,
      ref_image: string,
      // 针对 animate all 调用时, animate all 内已做check,忽略所有check
      shouldPreSubmitTask: boolean,
      shouldCheckWaitingTime: boolean,
      animateInfo: AnimateInfo,
      sessionId?: string
    ) => {
      const styleData = storyboardHasStyle(storyboard)
        ? getStyleDataByName(storyboard.style)
        : undefined;
      try {
        if (shouldPreSubmitTask) {
          const {estimatedWaitingTime, availableQuota, price, availableCredit} =
            await preSubmitTask(TaskType.ImageConditioningVideo, {
              model: animateInfo.model,
            });
          if (price * 1 > availableCredit) {
            throw new Error('NoCredit');
          }
          if (availableQuota < 1) {
            throw new Error('PendingTaskLimit');
          }
          if (shouldCheckWaitingTime && estimatedWaitingTime > 3600) {
            throw new Error('AnimationOverloaded');
          }
        }
        const currentScene = storyboard.scenes!.find(
          scene => scene.id === sceneId
        );
        await executeTask(
          'image_conditioning_video',
          {
            data: {
              size,
              ref_image,
              style: styleData?.name,
              ...(animateInfo.model === AnimateOptionTypeEnums.Base
                ? {}
                : {
                    prompt:
                      animateInfo.prompt === ''
                        ? undefined
                        : animateInfo.prompt,
                    image_prompt: currentScene!.currentPrompt!,
                  }),
            },
            params: {model: animateInfo.model},
          },
          [sceneId],
          sessionId
        );
      } catch (err) {
        if (err instanceof Error && err.message === 'NoCredit') {
          report(ErrorType.NoCredit, err);
          return;
        }
        if (err instanceof Error && err.message === 'AnimationOverloaded') {
          showToast(['animationOverloaded']);
          return;
        }
        if (err instanceof Error && err.message === 'PendingTaskLimit') {
          showToast(['pendingTaskLimit']);
          return;
        }
        if (
          axios.isAxiosError(err) &&
          err.response &&
          err.response.status === 429
        ) {
          throw err;
        }
        report(ErrorType.Service, err);
      }
    },
    [
      getStyleDataByName,
      storyboard,
      executeTask,
      preSubmitTask,
      report,
      showToast,
    ]
  );

  const onGenerateAnimationOfCurrentScene = useCallback(
    async (shouldCheckWaitingTime: boolean, animateInfo: AnimateInfo) => {
      if (!storyboard.scenes) return;
      const currentScene = storyboard.scenes[selectedSceneIndex];
      if (!currentScene || !currentScene.currentAsset) return;
      if (currentScene.currentAsset.type !== 'image') return;
      await onGenerateAnimation(
        currentScene.id,
        storyboard.size,
        currentScene.currentAsset.value,
        true,
        shouldCheckWaitingTime,
        animateInfo
      );
    },
    [onGenerateAnimation, selectedSceneIndex, storyboard]
  );

  const onGenerateAnimationAll = useCallback(
    async (shouldCheckWaitingTime: boolean, animateInfo: AnimateInfo) => {
      if (!storyboard.scenes) return;
      try {
        const {availableQuota, estimatedWaitingTime, price, availableCredit} =
          await preSubmitTask(TaskType.ImageConditioningVideo, {
            model: animateInfo.model,
          });
        const animateScenes = storyboard.scenes.filter(
          scene => scene.canAnimate
        );
        if (price * animateScenes.length > availableCredit) {
          throw new Error('NoCredit');
        }
        if (availableQuota < animateScenes.length) {
          throw new Error('PendingTaskLimit');
        }
        if (shouldCheckWaitingTime && estimatedWaitingTime > 3600) {
          throw new Error('AnimationAllOverloaded');
        }

        const sessionId = nanoid();
        updateStoryboard(storyboard.patch({taskSession: sessionId}), false);

        const tasks: Promise<void>[] = [];
        for (const scene of animateScenes) {
          if (
            (scene.task === undefined || scene.task.isEndedStatus) &&
            scene.currentAsset &&
            scene.currentAsset.type === 'image'
          ) {
            const task = onGenerateAnimation(
              scene.id,
              storyboard.size,
              scene.currentAsset.value,
              false,
              false,
              animateInfo,
              sessionId
            );
            tasks.push(task);
          }
        }
        await Promise.all(tasks);
      } catch (err) {
        if (err instanceof Error && err.message === 'NoCredit') {
          report(ErrorType.NoCredit, err);
          return;
        }
        if (err instanceof Error && err.message === 'AnimationAllOverloaded') {
          showToast(['animationAllOverloaded']);
          return;
        }
        if (err instanceof Error && err.message === 'PendingTaskLimit') {
          showToast(['pendingTaskLimit']);
          return;
        }
        report(ErrorType.Service, err);
      }
    },
    [
      preSubmitTask,
      onGenerateAnimation,
      report,
      showToast,
      storyboard,
      updateStoryboard,
    ]
  );

  const currentRef = useRef<string | null>(null);
  useEffect(() => {
    if (!projectId) return;
    if (currentRef.current) return;
    currentRef.current = storyboard.id;
    if (storyboard.task) return;
    generateStoryboardTask(storyboard, true);
  }, [generateStoryboardTask, projectId, storyboard]);

  const executeTaskInEditor = useCallback(
    (
      type: 'regenerate_scene_by_prompt' | 'regenerate_scene_by_pose_prompt',
      scene: Scene<ProjectType>,
      otherParam?: {
        objectKeys: string | string[];
        pose_description: string;
      }
    ) => {
      const styleData = storyboardHasStyle(storyboard)
        ? getStyleDataByName(storyboard.style)
        : undefined;
      switch (type) {
        case 'regenerate_scene_by_prompt':
          {
            const params = getCreateStoryboardTaskParam(
              storyboard,
              type,
              scene,
              selectedSceneIndex,
              styleData?.name,
              styleData?.ref_images
            );
            executeTask(
              type,
              ...([
                {
                  params: {
                    template: checkoutStoryboardTemplate(storyboard),
                  },
                  data: params,
                },
                [scene.id],
              ] as unknown as ExecuteTaskParams<'regenerate_scene_by_prompt'>)
            );
          }
          break;
        case 'regenerate_scene_by_pose_prompt':
          {
            const params: PoseImagePostTaskParam = {
              size: storyboard.size,
              style: styleData?.name ?? '',
              prompt: scene?.currentPrompt || '',
              ref_images: {
                mask: otherParam!.objectKeys[0],
                depth: otherParam!.objectKeys[1],
                open_pose: otherParam!.objectKeys[2],
                characters: getCharactersImage(
                  scene?.characters ? scene.characters.map(c => c.name) : [],
                  (storyboard?.characters?.map(item => item.name) || []) as any
                ),
              },
              //pose_description如果是选择的pose,则使用poseList中的description,否则使用posePrompt,---sunlei
              pose_description: otherParam!.pose_description,
              shot_type: scene?.shotType || 0,
            };
            executeTask(type, {data: params}, [scene.id]).then(() => {
              //去掉loading
              setLoading(false);
            });
          }
          break;
      }
    },
    [executeTask, getStyleDataByName, selectedSceneIndex, storyboard]
  );
  return {
    storyboard,
    selectedSceneIndex,
    loading,
    errorToastInfo: [toastVisible, errorInfo || []],
    newSceneIds,
    projectId,
    ratio,
    executeTaskInEditor,
    onBack,
    hideToast,
    changeLoading: setLoading,
    setSelectedSceneIndex,
    onNext,
    onAction,
    updateStoryboard,
    generateStoryboardTask,
    onGenerateAnimationOfCurrentScene,
    onGenerateAnimationAll,
    getImageWithObjectKey,
    refreshImage,
    updateScene,
    saveScene,
  };
}

function useNewHook({
  projectId,
  storyboard,
  updateStoryboard,
  showErrorInfo,
  onAddNewScenes,
  onBack,
  onInsert,
  setSelectedSceneIndex,
}: {
  projectId: string;
  storyboard: Storyboard<ProjectType>;
  updateStoryboard: HookParam<ProjectType>['updateStoryboard'];
  showErrorInfo: (errorType: ['overloaded' | 'sceneError', unknown?]) => void;
  onAddNewScenes: (newSceneIds: string[]) => void;
  onBack: () => void;
  onInsert: (type: InsertType, sceneId: string) => void;
  setSelectedSceneIndex: (index: number) => void;
}) {
  const projectIdRef = useRef(projectId);
  useEffect(() => {
    projectIdRef.current = projectId;
  }, [projectId]);

  const {aiFrontendClient} = useAPI();
  const {report} = useError();
  const {showNotification} = useNotificationContext();

  const {updateCredit} = useUserContext();
  const {addTask, mergeTask} = useStoryboard(updateStoryboard);

  const mergeTaskCallback = useCallback(
    (...[task, remarks]: MergeTaskCallbackParams) => {
      if (task.status === 'failure') {
        if (
          remarks.failureCode ===
          FrontErrorEnum.THE_PROMPT_CONTAINS_VIOLATIVE_CONTENT
        ) {
          report(ErrorType.ContentViolation);
        } else if (task.type === 'image_conditioning_video') {
          if (
            remarks['scenes'] === undefined ||
            !remarks['scenes'][0] ||
            remarks['scenes'][0].idx === -1
          )
            return;
          showErrorInfo(['sceneError', remarks['scenes'][0].idx + 1 + '']);
        } else if (
          task.type === 'split_scene' &&
          remarks.failureCode ===
            FrontErrorEnum.STORYBOARD_EMPTY_IMAGE_PROMPT_IS_NOT_SUPPORTED
        ) {
          //在当前场景之后插入一个空场景
          remarks.scenes &&
            remarks.scenes[0] &&
            onInsert('insert_after', remarks.scenes[0].id);
        } else {
          report(ErrorType.Service);
        }
        if (task.type === 'generate_storyboard') {
          onBack && onBack();
        }
      } else if (task.status === 'success') {
        if (task.type === 'image_conditioning_video') {
          updateCredit();
          if (
            remarks['scenes'] === undefined ||
            !remarks['scenes'][0] ||
            remarks['scenes'][0].idx === -1
          )
            return;
          showNotification({
            message: 'Scene n video generation success',
            type: 'SUCCESS',
            messageParams: {n: remarks['scenes'][0].idx + 1},
          });
        } else if (task.type === 'split_scene') {
          updateCredit();
          remarks.scenes &&
            onAddNewScenes(remarks.scenes?.map(scene => scene.id));
        } else if (task.type === 'merge_scenes') {
          if (remarks.scenes) {
            onAddNewScenes(remarks.scenes?.map(scene => scene.id));
            remarks.scenes[0] && setSelectedSceneIndex(remarks.scenes[0].idx);
          }
        } else if (task.type === 'generate_storyboard') {
          updateCredit();
        }
      }
    },
    [
      onAddNewScenes,
      onBack,
      onInsert,
      report,
      setSelectedSceneIndex,
      showErrorInfo,
      showNotification,
      updateCredit,
    ]
  );

  const pollingCallback = useCallback(
    (...params: Parameters<PollingCallback<AFTaskType<StoryboardTaskType>>>) =>
      mergeTask(...params, mergeTaskCallback),
    [mergeTask, mergeTaskCallback]
  );

  const createTask: CreateTask<
    AFTaskType<StoryboardTaskType>,
    StoryboardParams<StoryboardTaskType>
  > = useMemo(
    () => aiFrontendClient.createTask.bind(aiFrontendClient),
    [aiFrontendClient]
  );

  const findTask: FindTask<AFTaskType<StoryboardTaskType>> = useMemo(
    () => aiFrontendClient.findTask.bind(aiFrontendClient),
    [aiFrontendClient]
  );

  const {execute, poll, clearAll} = useTaskManager<
    AFTaskType<StoryboardTaskType>,
    StoryboardParams<StoryboardTaskType>
  >(createTask, findTask, pollingCallback);

  const executeTask = useCallback(
    async <T extends StoryboardTaskType>(
      type: T,
      ...[{params, data}, sceneIds, sessionId]: ExecuteTaskParams<T>
    ) => {
      let captchaToken: string | undefined;
      let loop = true;
      while (loop) {
        try {
          const taskId = await execute(
            checkoutAFTaskType(type),
            {
              ...(type === 'generate_storyboard' ||
              type === 'split_scene' ||
              type === 'merge_scenes' ||
              type === 'regenerate_scene_by_prompt'
                ? {
                    params: {
                      ...params,
                      ...(type === 'split_scene' ||
                      type === 'merge_scenes' ||
                      type === 'regenerate_scene_by_prompt'
                        ? {action: checkoutStoryboardAction(type)}
                        : {}),
                    },
                  }
                : type === 'image_conditioning_video'
                ? {params}
                : {}),
              data,
              projectId: projectIdRef.current,
            } as unknown as TaskParams<T>,
            {captchaToken}
          );
          addTask(type, taskId, sceneIds, sessionId);
          loop = false;
        } catch (err) {
          if (
            err instanceof AppError &&
            err.type === ErrorType.NoCaptchaToken
          ) {
            if (captchaToken === undefined) {
              captchaToken = await requestInvisibleCaptchaToken();
              continue;
            } else {
              throw new Error('NoCaptchaToken');
            }
          }
          loop = false;

          if (axios.isAxiosError(err) && type === 'image_conditioning_video') {
            throw err;
          }
          if (type === 'generate_storyboard') {
            throw err;
          }
          if (axios.isAxiosError(err) && err?.status === 402) {
            report(ErrorType.NoCredit, err);
          } else if (!(err instanceof AppError)) {
            // appError 已经在 aiFrontendResponseRejected 中 report 过了
            report(ErrorType.Service, err);
          }
        }
      }
    },
    [addTask, execute, report]
  );

  useEffect(() => {
    clearAll();
  }, [clearAll, storyboard?.id]);

  useEffect(() => {
    if (!storyboard?.tasks) return;
    for (const {type, id, isEndedStatus} of storyboard.tasks) {
      if (isEndedStatus) continue;
      poll(checkoutAFTaskType(type), id, {immediately: true});
    }
  }, [poll, storyboard?.tasks]);

  const preSubmitTask: PreSubmitTask = useCallback(
    async (type: PreSubmitTaskParams[0], params: PreSubmitTaskParams[1]) => {
      return await aiFrontendClient.preSubmitTask(type, params);
    },
    [aiFrontendClient]
  );
  const updateProjectId = useCallback((id: string) => {
    projectIdRef.current = id;
  }, []);
  return {executeTask, preSubmitTask, updateProjectId};
}

export type ExecuteTask = ReturnType<typeof useNewHook>['executeTask'];

export const StoryboardPageContainer = combine(useHook, [
  'projectId',
  'storyboard',
  'onBack',
  'updateStoryboard',
])(StoryboardPage);

export function transferScene(type: string, taskScene: Scene<ProjectType>) {
  return {
    subtitle: taskScene.subtitle || '',
    native_subtitle:
      taskScene instanceof BilingualStoryScene ||
      taskScene instanceof BilingualDialogueScene
        ? taskScene.nativeSubtitle
        : undefined,
    shot_type: taskScene.shotType,
    ...(type === 'regenerate_scene_by_prompt'
      ? {
          prompt: taskScene.currentPrompt ?? '',
          prompt_diff_ratio: !taskScene.lastPrompt
            ? 1
            : Math.round(
                Math.max(
                  0,
                  levenshtein(taskScene.lastPrompt, taskScene.currentPrompt!) /
                    taskScene.lastPrompt.length
                ) * 100
              ) / 100,
        }
      : {}),
    ...(taskScene instanceof HolidayGreetingScene && taskScene.holidayImage
      ? {
          holiday_image: taskScene.holidayImage,
        }
      : {}),
    characters:
      taskScene.characters && taskScene.characters.length > 0
        ? taskScene.characters
        : undefined,
    speaker:
      taskScene instanceof BilingualDialogueScene
        ? taskScene.speaker
        : undefined,
  };
}
export function getTone(promptPolicy: PromptPolicy<ProjectType>) {
  if (
    promptPolicy instanceof GeneralStoryIdeaPromptPolicy ||
    promptPolicy instanceof ShortVideoIdeaPromptPolicy
  ) {
    if (typeof promptPolicy.tone === 'string') {
      return {tone: promptPolicy.tone};
    } else {
      if (promptPolicy.tone.value) {
        return {
          tone: 'Other',
          tone_other: promptPolicy.tone.value,
        };
      } else {
        return {tone: TONE_OPTIONS[0].value as string};
      }
    }
  } else {
    return {};
  }
}
function getHolidayParam(storyboard: Storyboard<ProjectType>) {
  if (storyboard instanceof HolidayGreetingStoryboard) {
    if (typeof storyboard.holiday === 'string') {
      return {holiday_name: storyboard.holiday};
    } else {
      return {
        holiday_name: storyboard.holiday.value || 'general holiday',
      };
    }
  } else {
    return {};
  }
}
export function getProficiencyLevel(promptPolicy: PromptPolicy<ProjectType>) {
  if (
    promptPolicy instanceof BilingualStoryIdeaPromptPolicy ||
    promptPolicy instanceof BilingualDialogueIdeaPromptPolicy
  ) {
    if (typeof promptPolicy.proficiencyLevel === 'string') {
      return {proficiency_level: promptPolicy.proficiencyLevel};
    } else {
      if (promptPolicy.proficiencyLevel.value) {
        return {
          proficiency_level: 'Other',
          proficiency_level_other: promptPolicy.proficiencyLevel.value,
        };
      } else {
        return {
          proficiency_level: PROFICIENCY_LEVEL_OPTIONS[0].value as string,
        };
      }
    }
  } else {
    return {};
  }
}

function getCreateStoryboardTaskParam(
  storyboard: Storyboard<ProjectType>,
  type:
    | 'generate_storyboard'
    | 'split_scene'
    | 'merge_prev_scene'
    | 'merge_next_scene'
    | 'regenerate_scene_by_prompt',
  currentScene: Scene<ProjectType>,
  currentSceneIndex: number,
  styleValue?: string,
  style_ref_images?: StyleRefImages
) {
  const storyboardInfo = {
    script: storyboard.prompt,
    characters:
      storyboard.characters?.map(character => ({
        name: character.name,
        description: character.description,
        image: character.image!,
        character_type: character.characterType,
      })) || [],
    scenes: [transferScene(type, currentScene)],
    title: storyboard.title,
    hashtags: storyboard.hashtags,
    abstract: storyboard.description,
    native_title:
      storyboard instanceof BilingualStoryStoryboard ||
      storyboard instanceof BilingualDialogueStoryboard
        ? storyboard.nativeTitle
        : undefined,
  };
  if (['merge_next_scene', 'merge_prev_scene'].includes(type)) {
    const otherScene =
      type === 'merge_next_scene'
        ? storyboard.scenes![currentSceneIndex + 1]
        : storyboard.scenes![currentSceneIndex - 1];
    storyboardInfo.scenes = [
      storyboardInfo.scenes[0],
      transferScene(type, otherScene),
    ];
  }

  const user_input = {
    prompt: storyboard.prompt,
    language: storyboard.language,
    native_language:
      storyboard instanceof BilingualStoryStoryboard ||
      storyboard instanceof BilingualDialogueStoryboard
        ? storyboard.nativeLanguage
        : undefined,
    keep_user_content: storyboard.promptPolicy.keepUserContent,
    paragraph_as_shots:
      storyboard.promptPolicy instanceof ScriptPromptPolicy &&
      storyboard.promptPolicy.paragraphAsShots,
    ...getTone(storyboard.promptPolicy),
    ...getProficiencyLevel(storyboard.promptPolicy),
    keywords:
      storyboard instanceof BilingualStoryStoryboard ||
      storyboard instanceof BilingualDialogueStoryboard
        ? storyboard.vocabulary
        : undefined,
    ...getHolidayParam(storyboard),
  };
  return {
    size: storyboard.size,
    style: styleValue ?? '',
    user_input,
    storyboard: storyboardInfo,
    style_ref_images,
    figure_style:
      storyboard instanceof HolidayGreetingStoryboard
        ? storyboard.figureStyle
        : undefined,
  };
}
function getCharactersImage(
  characterNames: string[] | undefined,
  characters: CustomizedCharacter[] | undefined
) {
  if (!characterNames || !characters) {
    return [];
  }
  const imgUrl = characters.find(c => characterNames.includes(c.name))?.image;
  return imgUrl ? [imgUrl] : [];
}
function getCompleteImage(assetId: string) {
  return 's3://' + process.env.REACT_APP_USER_ASSET_BUCKET + '/' + assetId;
}
