// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LockedIcon} from 'assets/svg/outline/Locked.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useTranslation} from 'react-i18next';

import styles from './FigureStyle.module.scss';
import {Props} from './FigureStyle.types';

export function FigureStyle({
  value: selected,
  resUrl,
  effectList,
  onChange,
}: Props) {
  const {t} = useTranslation('resourceBuilder');
  return (
    <ul className={classNames(styles['style-list'], 'style-list')}>
      {effectList.map(({image, display_name, name}) => {
        const isSelected = name === selected;

        return (
          <li
            className={classNames(styles.style, isSelected && styles.active)}
            key={name}
            onClick={() => {
              if (!isSelected) {
                onChange(name);
              }
            }}
          >
            <div className={styles['preview-box']}>
              <img
                src={resUrl + image}
                className={styles.preview}
                draggable={false}
              />
              <div className={styles.box}>
                <LockedIcon className={styles.locked} />
              </div>
            </div>

            <Popover
              triggerElement={
                <p className={classNames(styles['style-name'], 'style-name')}>
                  {t(display_name)}
                </p>
              }
              trigger="hover"
              content={
                <p className={classNames(styles['style-name-popover'])}>
                  {t(display_name)}
                </p>
              }
              direction="right"
              isControlByOverflow={true}
            />
          </li>
        );
      })}
    </ul>
  );
}
