// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {encrypt_data} from 'modules/ai-frontend/utils';

import {axiosFactory} from './axiosFactory';

const PROJECT_ID_KEY = 'X-ST-PROJECT-ID';
export interface Response {
  code: number;
  message: string | string[];
  error?: number;
}
export interface ResponseData<T> extends Response {
  data: T;
}

export const serverAiApi = axiosFactory({
  baseURL: process.env.REACT_APP_FRONTEND_URL as string,
  timeout: 30000,
});

export async function removeImageBgPostTask(
  object_id: string
): Promise<string> {
  return serverAiApi.post('/tools/remove-image-bg', {
    image: object_id,
  });
}
export type GetImageByIdTypeResponse = {
  id: string;
  create_time: string;
  status: string;
  type: string;
  progress: string | null;
  original_image: string;
  asset: string;
};

export async function getImageById(
  id: string
): Promise<GetImageByIdTypeResponse> {
  return serverAiApi.get(`/tools/remove-image-bg/${id}`);
}

export type GetImageListResponse = {
  fields: Record<string, string>;
  object_id: string;
  url: string;
};
//
//target=remove_image_bg时，content_type暂时支持image/jpeg，image/png，image/webp，文件大小限制为25M
//target=scene_image时，只支持 image/jpeg，文件大小限制为10M
export async function getUploadForm(
  content_type: string,
  target: string
): Promise<GetImageListResponse> {
  return serverAiApi.post('/assets/upload-form?target=' + target, {
    content_type,
  });
}
//target=pose_scene_ref 时，content_type={"mask": "image/png","depth":"image/jpeg","open_pose":"image/jpeg"}
export async function getSomeUploadForm(
  content_type: Record<string, string>,
  target: string
): Promise<Record<string, GetImageListResponse>> {
  return serverAiApi.post('/assets/upload-form?target=' + target, {
    content_type,
  });
}
export async function commitPoseTask(
  prompt: string,
  projectId: string
): Promise<string> {
  const {version, time}: {version: number; time: number} =
    await serverAiApi.get('/crypto');
  const final_data = encrypt_data(
    {prompt, context_id: projectId},
    version,
    time
  );
  return serverAiApi.post('/motions?mode=pose', final_data, {
    headers: {
      [PROJECT_ID_KEY]: projectId,
    },
  });
}
export type getPoseJsonByTaskIdResponse = {
  asset: string;
  status: 'success' | 'failure' | 'processing';
};
export async function getPoseJsonByTaskId(
  taskId: string
): Promise<getPoseJsonByTaskIdResponse> {
  return serverAiApi.get(`/motions/${taskId}`);
}
