// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';

import styles from './ScriptPreviewer.module.scss';
import {Props} from './ScriptPreviewer.types';

export function ScriptPreviewer({
  scripts,
  isRtl,
  isNativeRtl,
  nativeScripts,
}: Props) {
  return (
    <ul
      className={classNames(
        styles.container,
        nativeScripts && styles['double-script-container']
      )}
    >
      {scripts.map((script, index) => {
        if (!(script || (nativeScripts && nativeScripts[index]))) return null;
        return (
          <li key={index}>
            {script && (
              <div
                className={classNames(styles.script, styles['double-script'], {
                  'rtl-element-global': isRtl,
                })}
              >
                {script}
              </div>
            )}
            {nativeScripts && nativeScripts[index] && (
              <div
                className={classNames(styles.script, {
                  'rtl-element-global': isNativeRtl,
                })}
              >
                {nativeScripts[index]}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}
