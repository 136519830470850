// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {nanoid} from 'nanoid';

import {fromJSON, SceneAsset} from './models/SceneAsset';
import {SceneAssetType} from './types';

export const DEFAULT_COLOR_ASSET_VALUE = '#1F1F1F';

export function generateSceneHistoryId() {
  return nanoid();
}

export function makeSceneAsset<T extends SceneAssetType>(
  type: T,
  params: T extends 'video'
    ? {value: string; ref_image: string}
    : T extends 'image' | 'color'
    ? {value: string}
    : never
): SceneAsset<T> {
  if (type === 'video') {
    if ('ref_image' in params) {
      return fromJSON({
        id: generateSceneHistoryId(),
        type,
        value: params.value,
        ref_image: params.ref_image,
      });
    }
    throw new Error('ref_image is required for video scene asset');
  }
  if (type === 'image' || type === 'color') {
    return fromJSON({
      id: generateSceneHistoryId(),
      type,
      value: params.value,
    });
  }
  throw new Error('Invalid scene asset type');
}

// empty scene only have color scene asset
export function makeEmptySceneAsset() {
  return makeSceneAsset('color', {value: DEFAULT_COLOR_ASSET_VALUE});
}
