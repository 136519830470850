// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios, {AxiosInstance} from 'axios';

type RequestInterceptorParams = Parameters<
  AxiosInstance['interceptors']['request']['use']
> extends [onFulfilled?: infer A, onRejected?: infer B, options?: infer C]
  ? {onFulfilled?: A; onRejected?: B; options?: C}
  : never;

type ResponseInterceptorParams = Parameters<
  AxiosInstance['interceptors']['response']['use']
> extends [onFulfilled?: infer A, onRejected?: infer B, options?: infer C]
  ? {onFulfilled?: A; onRejected?: B; options?: C}
  : never;

export abstract class APIClient {
  static createAxios({
    baseURL,
    timeout = 10000,
  }: {
    baseURL?: string;
    timeout?: number;
  }) {
    return axios.create({baseURL, timeout, withCredentials: true});
  }

  constructor(protected readonly a: AxiosInstance) {}

  setRequestInterceptor(...params: RequestInterceptorParams[]) {
    this.a.interceptors.request.clear();
    for (const {onFulfilled, onRejected, options} of params) {
      this.a.interceptors.request.use(onFulfilled, onRejected, options);
    }
  }

  setResponseInterceptor(...params: ResponseInterceptorParams[]) {
    this.a.interceptors.response.clear();
    for (const {onFulfilled, onRejected, options} of params) {
      this.a.interceptors.response.use(onFulfilled, onRejected, options);
    }
  }
}
