// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Holiday, Size} from 'modules/project/types';

import {BasePreference} from './BasePreference';

export class HolidayGreetingPreference extends BasePreference {
  static fromJSON(json: Record<string, any>): HolidayGreetingPreference {
    return new HolidayGreetingPreference(
      json.size,
      json.language,
      json.bgm,
      json.voiceover,
      json.voiceoverSpeed,
      json.holiday,
      json.figureStyle,
      json.cardDesign,
      json.overlayEffect
    );
  }
  constructor(
    size?: Size,
    language?: string,
    readonly bgm?: string,
    readonly voiceover?: string,
    readonly voiceoverSpeed?: 'normal' | 'fast',
    readonly holiday?: Holiday,
    readonly figureStyle?: string,
    readonly cardDesign?: string,
    readonly overlayEffect?: string
  ) {
    super(size, language);
  }
  patch(data: Partial<HolidayGreetingPreference>): HolidayGreetingPreference {
    const {
      size,
      language,
      bgm,
      voiceover,
      voiceoverSpeed,
      holiday,
      figureStyle,
      cardDesign,
      overlayEffect,
    } = Object.assign({}, this, data);
    return new HolidayGreetingPreference(
      size,
      language,
      bgm,
      voiceover,
      voiceoverSpeed,
      holiday,
      figureStyle,
      cardDesign,
      overlayEffect
    );
  }
}
