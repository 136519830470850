// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as FastIcon} from 'assets/svg/3.0/FastIcon.svg';
import {ReactComponent as SlowIcon} from 'assets/svg/3.0/SlowIcon.svg';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {BGMSizer} from '../BGMSizer';
import styles from './BGMFilter.module.scss';
import {Props} from './BGMFilter.types';

export function BGMFilter({bgmList, filterOption, onChange}: Props) {
  const {t} = useTranslation('project');
  const {t: resourceBuilderT} = useTranslation('resourceBuilder');

  const {flowList, emotionList} = useMemo(() => {
    const {flowList, emotionList} = bgmList.reduce(
      (value, item) => {
        if (!value.flowList.includes(item.flow)) {
          value.flowList.push(item.flow);
        }
        item.emotions.forEach(emotion => {
          if (!value.emotionList.includes(emotion)) {
            value.emotionList.push(emotion);
          }
        });
        return value;
      },
      {flowList: [] as string[], emotionList: [] as string[]}
    );
    // 按照首字母顺序进行展示
    return {flowList: flowList.sort(), emotionList: emotionList.sort()};
  }, [bgmList]);

  const iconFromFlow = useCallback((tag: string) => {
    switch (tag) {
      case 'Slow':
        return SlowIcon;
      case 'Fast':
        return FastIcon;
      default:
        return undefined;
    }
  }, []);

  return (
    <div className={styles.container}>
      <BGMSizer
        defaultLabel={t('All tempo')}
        tags={flowList}
        selectedTag={filterOption.flow}
        onChange={flow => onChange({...filterOption, flow})}
        iconFromTag={iconFromFlow}
        displayNameFromTag={tag => resourceBuilderT(`BGM_${tag}`)}
      />
      <BGMSizer
        defaultLabel={t('All emotion')}
        tags={emotionList}
        selectedTag={filterOption.emotion}
        onChange={emotion => onChange({...filterOption, emotion})}
        displayNameFromTag={resourceBuilderT}
      />
      {(filterOption.flow !== null || filterOption.emotion !== null) && (
        <div
          className={styles.clear}
          onClick={() => onChange({flow: null, emotion: null})}
        >
          <span>{t('Clear')}</span>
        </div>
      )}
    </div>
  );
}
