// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';

import {BilingualDialogueComposition} from './models/BilingualDialogueComposition';
import {BilingualStoryComposition} from './models/BilingualStoryComposition';
import {Composition} from './models/Composition';
import {GeneralStoryComposition} from './models/GeneralStoryComposition';
import {HolidayGreetingComposition} from './models/HolidayGreetingComposition';
import {ShortVideoComposition} from './models/ShortVideoComposition';

export function getProjectTypeByComposition<T extends ProjectType>(
  composition: Composition<T>
): T {
  if (composition instanceof BilingualStoryComposition) {
    return 'bilingual_story' as T;
  } else if (composition instanceof GeneralStoryComposition) {
    return 'general_story' as T;
  } else if (composition instanceof HolidayGreetingComposition) {
    return 'holiday_greeting' as T;
  } else if (composition instanceof ShortVideoComposition) {
    return 'short_video' as T;
  } else if (composition instanceof BilingualDialogueComposition) {
    return 'bilingual_dialogue' as T;
  } else {
    throw new Error(`Unknown composition type: ${composition}`);
  }
}
