// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {AvatarCharacter} from 'contexts/ResourceManager.type';

import styles from './CharacterSelectContent.module.scss';
interface Props {
  characters: AvatarCharacter[];
  value: string;
  onChange: (avatar: string) => void;
  setVisible: (visible: boolean) => void;
  resUrl: string;
  title: string;
}
export function CharacterSelectContent({
  characters,
  value,
  onChange,
  setVisible,
  resUrl,
  title,
}: Props) {
  return (
    <div className={styles['dropdown']}>
      <div className={styles['header']}>{title}</div>
      <div className={styles['body']}>
        {characters.map(option => (
          <div
            key={option.name}
            className={classNames(
              styles['item'],
              value === option.name && styles['selected']
            )}
            onClick={() => {
              setVisible(false);
              onChange(option.name);
            }}
          >
            <img draggable={false} src={`${resUrl}${option.image}`} />
          </div>
        ))}
      </div>
    </div>
  );
}
