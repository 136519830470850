// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as Icon} from 'assets/svg/3.0/CardDesign.svg';
import None from 'assets/svg/3.0/None.svg';
import classNames from 'classnames';
import {useResourceManager} from 'contexts/ResourceManager';
import {Collapse} from 'pages/components/Collapse';
import {useTranslation} from 'react-i18next';

import styles from './CardDesign.module.scss';
import {Props} from './CardDesign.types';

export function CardDesign({cardDesign, onChange, ratio}: Props) {
  const {t} = useTranslation('project');
  const {getHolidayCards, resUrl} = useResourceManager();
  return (
    <Collapse
      icon={<Icon className={styles['label-icon']} />}
      title={t('Card design')}
    >
      <div className={styles.content}>
        <div
          key={''}
          style={{
            backgroundImage: `url(${None})`,
            aspectRatio: ratio && ratio.split(':').join('/'),
          }}
          className={classNames(
            styles['card-design-box'],
            styles['none'],
            cardDesign === null && styles['isSelected']
          )}
          onClick={() => onChange(null)}
        ></div>
        {getHolidayCards()
          .filter(card => card.name !== 'NA')
          .map(card => {
            return (
              <div
                key={card.name}
                style={
                  {
                    backgroundImage: `url(${resUrl + card.image})`,
                  } as React.CSSProperties
                }
                className={classNames(
                  styles['card-design-box'],
                  cardDesign === card.name && styles['isSelected']
                )}
                onClick={() => onChange(card.name)}
              />
            );
          })}
      </div>
    </Collapse>
  );
}
