// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {createProject, getGalleryById, updateProject} from 'api/server';
import {combine} from 'components/Combine';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useUserContext} from 'contexts/UserContext';
import {useDebouncedAsyncFunction} from 'lib/hooks';
import {formatAspectRatio} from 'lib/ratio';
import {GeneralStoryProjectJSON} from 'modules/project/types';
import {convertProjectVersion, duplicateGallery} from 'modules/project/utils';
import {useCallback, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {generateIdPath, PROJECT_PATH} from 'utils/path';

import {useGalleryProjects, useProjectOrGalleryModal} from '../modules';
import {GalleryItemType} from '../WorkspacePage.types';
import {ProjectOrGallery} from './ProjectOrGallery';

function useHook() {
  //获取navigate的state参数
  const location = useLocation();
  const {
    galleryList: galleryItemList,
    hasMore,
    isLoading: isGalleryFetchLoading,
    isRefreshFromFirstPage,
    loadMoreRef,
    galleryTagList,
    changeTag,
    activeTag,
  } = useGalleryProjects(50, location.state?.tag);

  const [visible, hideModalVisible, projectContent] =
    useProjectOrGalleryModal();
  const [currentGalleryId, setCurrentGalleryId] = useState<string | null>(null);
  const {userInfo} = useUserContext();
  const navigate = useNavigate();
  const {showNotification} = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);

  const onUseGallery = useCallback(
    async (gallery: GeneralStoryProjectJSON) => {
      try {
        setIsLoading(true);
        setCurrentGalleryId(null);
        const newProject = duplicateGallery(gallery);
        newProject.author_id = userInfo.userId;
        const {data: projectId} = await createProject({
          frameRatio: formatAspectRatio(newProject.size),
          projectJsonContent: JSON.stringify(newProject),
          lang: newProject.language,
        });
        await updateProject({
          projectId,
          projectName: `${gallery.storyboard?.title} copy`,
          lang: newProject.language,
        });
        showNotification({
          type: 'SUCCESS',
          message: 'A new project created',
        });
        navigate(generateIdPath(PROJECT_PATH, projectId));
      } catch {
        // TODO: 使用失败
      }
    },
    [userInfo.userId, showNotification, navigate]
  );

  const useGalleryWithGalleryId = useCallback(
    async (galleryId: string) => {
      const gallery = await getGalleryById(galleryId);
      if (!gallery) return;
      const project = convertProjectVersion(
        gallery as unknown as GeneralStoryProjectJSON //todo 整个模块都要改成通用类，先做其他的，后面再改
      );
      onUseGallery({
        ...project,
        histories: [],
      } as unknown as GeneralStoryProjectJSON);
    },
    [onUseGallery]
  );
  //将 useGalleryWithGalleryId 用debounce防抖处理
  const debouncedUseGalleryWithGalleryId = useDebouncedAsyncFunction(
    useGalleryWithGalleryId,
    300
  );

  const onclickProjectOrGalleryItem = useCallback((item: GalleryItemType) => {
    setCurrentGalleryId(item.galleryId);
  }, []);

  return {
    isLoading,
    useGalleryWithGalleryId: debouncedUseGalleryWithGalleryId,
    onUseGallery,
    projectOrGalleryItemList: galleryItemList,
    visible,
    hideModalVisible,
    projectContent,
    pageType: 'gallery',
    currentGalleryId,
    onclickProjectOrGalleryItem,
    setCurrentGalleryId,
    hasMore,
    isGalleryFetchLoading,
    isRefreshFromFirstPage,
    loadMoreRef,
    galleryTagList,
    changeTag,
    activeTag,
  };
}
export const GalleryContainer = combine(useHook)(ProjectOrGallery);
