// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';

import {BilingualDialogueProjectConfig} from './BilingualDialogueProjectConfig';
import {BilingualStoryProjectConfig} from './BilingualStoryProjectConfig';
import {GeneralStoryProjectConfig} from './GeneralStoryProjectConfig';
import {HolidayGreetingProjectConfig} from './HolidayGreetingProjectConfig';
import {ShortVideoProjectConfig} from './ShortVideoProjectConfig';

export type ProjectConfig<T extends ProjectType> = T extends 'general_story'
  ? GeneralStoryProjectConfig
  : T extends 'holiday_greeting'
  ? HolidayGreetingProjectConfig
  : T extends 'short_video'
  ? ShortVideoProjectConfig
  : T extends 'bilingual_story'
  ? BilingualStoryProjectConfig
  : T extends 'bilingual_dialogue'
  ? BilingualDialogueProjectConfig
  : never;

export function patch<C extends ProjectConfig<ProjectType>>(
  config: C,
  data: Partial<C>
): C {
  if (config instanceof GeneralStoryProjectConfig) {
    return config.patch(data as Partial<GeneralStoryProjectConfig>) as C;
  } else if (config instanceof HolidayGreetingProjectConfig) {
    return config.patch(data as Partial<HolidayGreetingProjectConfig>) as C;
  } else if (config instanceof BilingualStoryProjectConfig) {
    return config.patch(data as Partial<BilingualStoryProjectConfig>) as C;
  } else if (config instanceof BilingualDialogueProjectConfig) {
    return config.patch(data as Partial<BilingualDialogueProjectConfig>) as C;
  } else if (config instanceof ShortVideoProjectConfig) {
    return config.patch(data as Partial<ShortVideoProjectConfig>) as C;
  } else {
    throw new Error(`Unknown config type: ${config}`);
  }
}
