// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';
import {fromJSON, SceneAsset} from 'modules/scene-asset/models/SceneAsset';
import {SceneAssetType} from 'modules/scene-asset/types';
import {
  DEFAULT_COLOR_ASSET_VALUE,
  generateSceneHistoryId,
} from 'modules/scene-asset/utils';
import {TaskType as StoryboardTaskType} from 'modules/storyboard/types';
import {Task} from 'modules/task/models/Task';

import {AnimateModelMap, BaseSceneJSON, ClosedScene, TaskType} from './types';

const TASK_TYPES: TaskType[] = [
  'split_scene',
  'merge_scenes',
  'regenerate_scene_by_prompt',
  'regenerate_scene_by_pose_prompt',
  'image_conditioning_video',
];

const MAX_SCENE_ASSETS_COUNT = 10;

export function isSceneTask(
  task: Task<StoryboardTaskType>
): task is Task<TaskType> {
  return TASK_TYPES.includes(task.type as TaskType);
}

export function getScriptCopyContent(scenes: ClosedScene<ProjectType>[]) {
  return (
    scenes
      ?.reduce((resArr: string[], scene) => {
        let speaker = '';
        let str = '';
        if ('speaker' in scene) {
          speaker = scene.speaker ? `${scene.speaker}: ` : '';
        }
        scene.subtitle && (str += `${speaker + scene.subtitle}`);
        if ('native_subtitle' in scene && scene.native_subtitle) {
          str += `${str ? '\n' : ''}${speaker + scene.native_subtitle}`;
        }
        str && resArr.push(`\n${str}\n`);
        return resArr;
      }, [])
      .join('') || ''
  );
}
export const AnimateOptionTypeEnums = {
  Base: 'base',
  Vira: 'vira',
} satisfies AnimateModelMap;

export function processSceneAssets({
  current_asset,
  assets = [],
  video,
  image,
}: Pick<BaseSceneJSON, 'image' | 'video' | 'assets' | 'current_asset'>): {
  currentAsset: SceneAsset<SceneAssetType>;
  assetsList: SceneAsset<SceneAssetType>[];
} {
  if (!assets.length) {
    // 这是老的数据 需要转换成新的结构
    if (image) {
      current_asset = generateSceneHistoryId();
      assets.push({
        id: current_asset,
        type: 'image',
        value: image,
      });
    }
    if (video && image) {
      current_asset = generateSceneHistoryId();
      assets.push({
        id: current_asset,
        type: 'video',
        value: video,
        ref_image: image,
      });
    }
    if (!image && !video) {
      current_asset = generateSceneHistoryId();
      assets.push({
        id: current_asset,
        type: 'color',
        value: DEFAULT_COLOR_ASSET_VALUE,
      });
    }
  }
  const assetsList = assets.map(h => fromJSON(h));
  const currentAsset = assetsList.find(h => h.id === current_asset);

  if (currentAsset) {
    return {currentAsset, assetsList};
  }
  if (assetsList.length) {
    return {
      currentAsset: assetsList[assetsList.length - 1],
      assetsList,
    };
  }
  throw Error('current_history_id should have history');
}

export function appendSceneAssets(
  asset: SceneAsset<SceneAssetType>,
  assets?: SceneAsset<SceneAssetType>[]
) {
  if (!assets) return;
  const newAssets = [...assets];

  while (newAssets.length >= MAX_SCENE_ASSETS_COUNT) {
    let indexToRemove = 0;
    if (asset.type !== 'image') {
      const imageAssetsCount = newAssets.filter(a => a.type === 'image').length;
      if (!imageAssetsCount) {
        throw new Error('No image asset found');
      }
      if (imageAssetsCount === 1) {
        indexToRemove = newAssets.findIndex(a => a.type !== 'image');
      }
    }
    newAssets.splice(indexToRemove, 1);
  }
  newAssets.push(asset);
  return newAssets;
}
