import {ReactComponent as NoneIcon} from 'assets/svg/3.0/None.svg';
import classNames from 'classnames';
import {Uploader} from 'components/Uploader';
import {FileToUpload} from 'components/Uploader/Uploader.type';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './WithDrop.module.scss';
type Props = {
  children: React.ReactNode;
  maxFileLength: number;
  uploadImage: (files: FileToUpload[]) => Promise<void>;
};

export function WithDrop({children, uploadImage, maxFileLength}: Props) {
  const [isDragging, setIsDragging] = useState(false);

  const {t} = useTranslation('project');

  const handleDrop = async function () {
    setIsDragging(false);
  };

  return (
    <div
      className={classNames(styles.container, 'with-drop', {
        [styles.dragging]: isDragging,
      })}
      onDrop={handleDrop}
      onDragOver={_ => {
        setIsDragging(true);
      }}
    >
      {children}
      <div
        className={styles.mask}
        onDragLeave={_ => {
          console.log('drag leave');
          setIsDragging(false);
        }}
      >
        <Uploader
          accept={['image/jpeg', 'image/png', 'image/webp']}
          uploadFileAndEditAsset={uploadImage}
          icon={
            maxFileLength > 0 ? (
              <span className={styles['drop-area']}>
                <span>{t('Drop the image here.')}</span>
                <span>{t('A clear-faced portrait is preferred.')}</span>
              </span>
            ) : (
              <span className={styles['no-drop-area']}>
                <NoneIcon />
                <span>
                  {t(
                    'Max 6 images support, try delete the current uploads for new ones.'
                  )}
                </span>
              </span>
            )
          }
          maxFileLength={maxFileLength}
          className={styles.uploader}
        />
      </div>
    </div>
  );
}
