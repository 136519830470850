// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {
  $getRoot,
  COMMAND_PRIORITY_CRITICAL,
  EditorState,
  LexicalEditor,
  PASTE_COMMAND,
} from 'lexical';
import {useCallback, useEffect, useRef} from 'react';

import {
  $isLimitedParagraphNode,
  LimitedParagraphNode,
  LimitedTextNode,
} from '../nodes';

function getEditorValueAndParagraphsInfo(editorState: EditorState): {
  value: string;
  paragraphNumber: number;
  paragraphsCharacterNumber: number[];
} {
  let value = '';
  let paragraphNumber = 0,
    paragraphsCharacterNumber: number[] = [];
  editorState.read(() => {
    const root = $getRoot();
    const paragraphs = root.getChildren().filter($isLimitedParagraphNode);
    value = paragraphs.map(p => p.getTextContent()).join('\n');
    const newParagraphs = paragraphs.filter(p => p.getTextContentSize() > 0);
    paragraphNumber = newParagraphs.length;
    paragraphsCharacterNumber = newParagraphs.map(paragraph =>
      paragraph.getTextContentSize()
    );
  });
  return {
    value,
    paragraphNumber,
    paragraphsCharacterNumber,
  };
}

function setEditorValue(
  editor: LexicalEditor,
  value: string,
  isAppend = false
): void {
  editor.update(() => {
    const root = $getRoot();
    !isAppend && root.clear();
    value.split('\n').forEach(text => {
      const p = new LimitedParagraphNode();
      if (text) {
        p.append(new LimitedTextNode(text));
      }
      root.append(p);
    });
    root.selectEnd();
  });
}

export function PromptPlugin({
  value,
  onPromptChange,
}: {
  value: string;
  onPromptChange: (promptInfo: {
    value: string;
    paragraphNumber: number;
    paragraphsCharacterNumber: number[];
  }) => void;
}) {
  const [editor] = useLexicalComposerContext();
  const currentRef = useRef<string>();

  const onChange = useCallback(
    (editorState: EditorState) => {
      const {value, paragraphNumber, paragraphsCharacterNumber} =
        getEditorValueAndParagraphsInfo(editorState);
      currentRef.current = value;
      onPromptChange({
        value,
        paragraphNumber,
        paragraphsCharacterNumber,
      });
    },
    [onPromptChange]
  );

  const reRenderEditor = useCallback(() => {
    const {value} = getEditorValueAndParagraphsInfo(editor.getEditorState());
    setEditorValue(editor, value);
  }, [editor]);

  useEffect(() => {
    if (currentRef.current === undefined || currentRef.current !== value) {
      setEditorValue(editor, value);
    }
  }, [value, editor]);

  useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      () => {
        setTimeout(reRenderEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, reRenderEditor]);

  return (
    <OnChangePlugin
      ignoreHistoryMergeTagChange
      ignoreSelectionChange
      onChange={onChange}
    />
  );
}
