// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  BaseIdeaPromptPolicyJSON,
  BasePromptPolicyJSON,
  BilingualDialogueIdeaPromptPolicyJSON,
  BilingualStoryIdeaPromptPolicyJSON,
  GeneralStoryIdeaPromptPolicyJSON,
  HolidayGreetingIdeaPromptPolicyJSON,
  ProficiencyLevel,
  ProjectType,
  PromptPolicyJSON,
  ScriptPromptPolicyJSON,
  ShortVideoIdeaPromptPolicyJSON,
  Tone,
} from '../types';

export abstract class BasePromptPolicy {
  abstract get keepUserContent(): boolean;

  get isIdeaPrompt(): boolean {
    return !this.keepUserContent;
  }

  get isScriptPrompt(): boolean {
    return this.keepUserContent;
  }

  toJSON(): BasePromptPolicyJSON {
    return {
      keep_user_content: this.keepUserContent,
    };
  }
}

export abstract class BaseIdeaPromptPolicy extends BasePromptPolicy {
  get keepUserContent(): false {
    return false;
  }

  toJSON(): BaseIdeaPromptPolicyJSON {
    return super.toJSON() as BaseIdeaPromptPolicyJSON;
  }
}

export class GeneralStoryIdeaPromptPolicy extends BaseIdeaPromptPolicy {
  static fromJSON({tone, quick_pace}: GeneralStoryIdeaPromptPolicyJSON) {
    return new GeneralStoryIdeaPromptPolicy(tone, quick_pace ?? false);
  }

  constructor(readonly tone: Tone, readonly quickPace: boolean) {
    super();
  }

  patch({
    tone,
    quickPace,
  }: Partial<{[K in 'tone' | 'quickPace']: GeneralStoryIdeaPromptPolicy[K]}>) {
    return new GeneralStoryIdeaPromptPolicy(
      tone ?? this.tone,
      quickPace ?? this.quickPace
    );
  }

  toJSON(): GeneralStoryIdeaPromptPolicyJSON {
    return {
      ...super.toJSON(),
      tone: this.tone,
      quick_pace: this.quickPace,
    };
  }
}

export class ShortVideoIdeaPromptPolicy extends BaseIdeaPromptPolicy {
  static fromJSON({tone}: ShortVideoIdeaPromptPolicyJSON) {
    return new ShortVideoIdeaPromptPolicy(tone);
  }

  constructor(readonly tone: Tone) {
    super();
  }

  patch({tone}: Partial<{[K in 'tone']: ShortVideoIdeaPromptPolicy[K]}>) {
    return new ShortVideoIdeaPromptPolicy(tone ?? this.tone);
  }

  toJSON(): ShortVideoIdeaPromptPolicyJSON {
    return {
      ...super.toJSON(),
      tone: this.tone,
    };
  }
}
export class BilingualStoryIdeaPromptPolicy extends BaseIdeaPromptPolicy {
  static fromJSON({proficiency_level}: BilingualStoryIdeaPromptPolicyJSON) {
    return new BilingualStoryIdeaPromptPolicy(proficiency_level);
  }

  constructor(readonly proficiencyLevel: ProficiencyLevel) {
    super();
  }
  patch({
    proficiencyLevel,
  }: Pick<BilingualStoryIdeaPromptPolicy, 'proficiencyLevel'>) {
    return new BilingualStoryIdeaPromptPolicy(proficiencyLevel);
  }

  toJSON(): BilingualStoryIdeaPromptPolicyJSON {
    return {
      ...super.toJSON(),
      proficiency_level: this.proficiencyLevel,
    };
  }
}

export class BilingualDialogueIdeaPromptPolicy extends BaseIdeaPromptPolicy {
  static fromJSON({proficiency_level}: BilingualDialogueIdeaPromptPolicyJSON) {
    return new BilingualDialogueIdeaPromptPolicy(proficiency_level);
  }
  constructor(readonly proficiencyLevel: ProficiencyLevel) {
    super();
  }

  patch({
    proficiencyLevel,
  }: Partial<{
    [K in 'proficiencyLevel']: BilingualDialogueIdeaPromptPolicy[K];
  }>) {
    return new BilingualDialogueIdeaPromptPolicy(
      proficiencyLevel ?? this.proficiencyLevel
    );
  }

  toJSON(): BilingualDialogueIdeaPromptPolicyJSON {
    return {
      ...super.toJSON(),
      proficiency_level: this.proficiencyLevel,
    };
  }
}

export class HolidayGreetingIdeaPromptPolicy extends BaseIdeaPromptPolicy {
  static fromJSON(_: HolidayGreetingIdeaPromptPolicyJSON) {
    return new HolidayGreetingIdeaPromptPolicy();
  }

  toJSON(): HolidayGreetingIdeaPromptPolicyJSON {
    return super.toJSON();
  }
}

export type IdeaPromptPolicy<T extends ProjectType> = T extends 'general_story'
  ? GeneralStoryIdeaPromptPolicy
  : T extends 'bilingual_story'
  ? BilingualStoryIdeaPromptPolicy
  : T extends 'short_video'
  ? ShortVideoIdeaPromptPolicy
  : T extends 'bilingual_dialogue'
  ? BilingualDialogueIdeaPromptPolicy
  : T extends 'holiday_greeting'
  ? HolidayGreetingIdeaPromptPolicy
  : never;

export class ScriptPromptPolicy extends BasePromptPolicy {
  static fromJSON({paragraph_as_shots}: ScriptPromptPolicyJSON) {
    return new ScriptPromptPolicy(paragraph_as_shots);
  }

  constructor(readonly paragraphAsShots: boolean) {
    super();
  }

  get keepUserContent(): true {
    return true;
  }

  patch({
    paragraphAsShots,
  }: Partial<{[K in 'paragraphAsShots']: ScriptPromptPolicy[K]}>) {
    return new ScriptPromptPolicy(paragraphAsShots ?? this.paragraphAsShots);
  }

  toJSON(): ScriptPromptPolicyJSON {
    return {
      ...super.toJSON(),
      paragraph_as_shots: this.paragraphAsShots,
    } as ScriptPromptPolicyJSON;
  }
}

export type PromptPolicy<T extends ProjectType> =
  | IdeaPromptPolicy<T>
  | ScriptPromptPolicy;

export function fromJSON<T extends ProjectType>(
  type: T,
  json: PromptPolicyJSON<T>
): PromptPolicy<T> {
  if (json.keep_user_content) {
    return ScriptPromptPolicy.fromJSON(json as ScriptPromptPolicyJSON);
  } else {
    switch (type) {
      case 'general_story':
        return GeneralStoryIdeaPromptPolicy.fromJSON(
          json as GeneralStoryIdeaPromptPolicyJSON
        ) as IdeaPromptPolicy<T>;
      case 'short_video':
        return ShortVideoIdeaPromptPolicy.fromJSON(
          json as ShortVideoIdeaPromptPolicyJSON
        ) as IdeaPromptPolicy<T>;
      case 'bilingual_story':
        return BilingualStoryIdeaPromptPolicy.fromJSON(
          json as BilingualStoryIdeaPromptPolicyJSON
        ) as IdeaPromptPolicy<T>;
      case 'bilingual_dialogue':
        return BilingualDialogueIdeaPromptPolicy.fromJSON(
          json as BilingualDialogueIdeaPromptPolicyJSON
        ) as IdeaPromptPolicy<T>;
      case 'holiday_greeting':
        return HolidayGreetingIdeaPromptPolicy.fromJSON(
          json
        ) as IdeaPromptPolicy<T>;
      default:
        throw new Error(`Unknown project type: ${json}`);
    }
  }
}
export function patch<P extends PromptPolicy<ProjectType>>(
  promptPolicy: P,
  data: Partial<P>
): P {
  if (promptPolicy instanceof ScriptPromptPolicy) {
    return promptPolicy.patch(data as Partial<ScriptPromptPolicy>) as P;
  } else {
    if (promptPolicy instanceof GeneralStoryIdeaPromptPolicy) {
      return promptPolicy.patch(
        data as Partial<GeneralStoryIdeaPromptPolicy>
      ) as P;
    } else if (promptPolicy instanceof ShortVideoIdeaPromptPolicy) {
      return promptPolicy.patch(
        data as Partial<ShortVideoIdeaPromptPolicy>
      ) as P;
    } else if (promptPolicy instanceof BilingualDialogueIdeaPromptPolicy) {
      return promptPolicy.patch(
        data as Partial<BilingualDialogueIdeaPromptPolicy>
      ) as P;
    } else if (promptPolicy instanceof BilingualStoryIdeaPromptPolicy) {
      return promptPolicy.patch(
        data as Partial<BilingualDialogueIdeaPromptPolicy> as Pick<
          BilingualStoryIdeaPromptPolicy,
          'proficiencyLevel'
        >
      ) as P;
    } else {
      throw new Error(`Unknown prompt policy: ${promptPolicy}`);
    }
  }
}
