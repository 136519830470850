// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

const CAPTCHA_CONTAINER_ID = 'invisible-captcha-element';

export function requestInvisibleCaptchaToken(): Promise<string> {
  const element = document.createElement('div');
  element.id = CAPTCHA_CONTAINER_ID;
  document.body.appendChild(element);
  return new Promise((resolve, reject) => {
    // 10s 如果没有结果，则自动reject
    const timer = setTimeout(reject, 1000 * 10);
    const widgetId = window.turnstile.render(`#${CAPTCHA_CONTAINER_ID}`, {
      sitekey: process.env
        .REACT_APP_CLOUDFLARE_INVISIBLE_WIDGET_SITE_KEY as string,
      retry: 'never',
      callback: token => {
        clearTimeout(timer);
        window.turnstile.remove(widgetId);
        document.body.removeChild(element);
        resolve(token);
      },
      ['error-callback']: () => {
        clearTimeout(timer);
        window.turnstile.remove(widgetId);
        document.body.removeChild(element);
        reject();
      },
    });
  });
}
