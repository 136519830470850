// Copyright 2022 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import 'i18n';
import './vconsole';

import {AutoSelectLanguage} from 'components/AutoSelectLanguage';
import {VersionManager} from 'components/VersionManager';
import {APIProvider} from 'contexts/APIContext';
import {ErrorProvider} from 'contexts/ErrorContext';
import {MessageContextProvider} from 'contexts/MessageContext';
import {NotificationContextProvider} from 'contexts/NotificationContext';
import {ResourceManagerProvider} from 'contexts/ResourceManager';
import {SizeProvider} from 'contexts/SizeContext';
import {UserContextProvider} from 'contexts/UserContext';
import {MessageBox} from 'pages/components/MessageBox';
import {UnityInstanceManager} from 'pages/WorkspacePage/UnityCanvas/UnityCanvas.instance';
import {useEffect} from 'react';
import {Router} from 'Router';
import {SplashDebug} from 'SplashDebug';

globalThis.splashDebug = new SplashDebug();

export function App() {
  useEffect(() => {
    // 这里做了一个右键菜单的判断，如果点击的是图片，则不显示右键菜单，并对其第二层子元素进行判断
    // 是为了防止有after和before的伪元素遮盖掉点击的图片
    const isImageTag = (dom: HTMLElement) => {
      if (dom.tagName === 'IMG') {
        return true;
      } else if (dom.children.length > 0) {
        for (let i = 0; i < dom.children.length; i++) {
          if ((dom.children[i] as HTMLElement).tagName === 'IMG') {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    };
    const onContentMenu = (e: MouseEvent) => {
      const isImage = isImageTag(e.target as HTMLElement);
      if (isImage) {
        e.preventDefault();
      }
    };
    window.addEventListener('contextmenu', onContentMenu);

    return () => {
      window.removeEventListener('contextmenu', onContentMenu);
    };
  }, []);

  useEffect(() => {
    function setSafeAreaHeight() {
      document.documentElement.style.setProperty(
        '--safeAreaHeight',
        `${window.innerHeight}px`
      );
    }
    setSafeAreaHeight();
    window.addEventListener('resize', setSafeAreaHeight);
    return () => {
      window.removeEventListener('resize', setSafeAreaHeight);
    };
  }, []);

  return (
    <VersionManager>
      <ErrorProvider>
        <APIProvider>
          <ResourceManagerProvider>
            <NotificationContextProvider>
              <MessageContextProvider>
                <MessageBox />
                <UserContextProvider>
                  <UnityInstanceManager canvasId="view3D-canvas">
                    <SizeProvider>
                      <AutoSelectLanguage>
                        <Router />
                      </AutoSelectLanguage>
                    </SizeProvider>
                  </UnityInstanceManager>
                </UserContextProvider>
              </MessageContextProvider>
            </NotificationContextProvider>
          </ResourceManagerProvider>
        </APIProvider>
      </ErrorProvider>
    </VersionManager>
  );
}
