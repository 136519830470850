// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  Character as AFCharacter,
  Scene as AFScene,
  StoryboardTemplate as AFStoryboardTemplate,
  TaskStatus as AFTaskStatus,
  TaskType as AFTaskTypeT,
} from 'modules/ai-frontend/types';
import {CustomizedCharacter} from 'modules/character/models/CustomizedCharacter';
import {ClosedCharacter} from 'modules/character/types';
import {BilingualDialogueComposition} from 'modules/composition/models/BilingualDialogueComposition';
import {BilingualStoryComposition} from 'modules/composition/models/BilingualStoryComposition';
import {Composition} from 'modules/composition/models/Composition';
import {GeneralStoryComposition} from 'modules/composition/models/GeneralStoryComposition';
import {HolidayGreetingComposition} from 'modules/composition/models/HolidayGreetingComposition';
import {ShortVideoComposition} from 'modules/composition/models/ShortVideoComposition';
import {
  GeneralStoryIdeaPromptPolicy,
  ShortVideoIdeaPromptPolicy,
} from 'modules/project/models/PromptPolicy';
import {ProjectType} from 'modules/project/types';
import {BilingualDialogueScene} from 'modules/scene/models/BilingualDialogueScene';
import {BilingualStoryScene} from 'modules/scene/models/BilingualStoryScene';
import {GeneralStoryScene} from 'modules/scene/models/GeneralStoryScene';
import {HolidayGreetingScene} from 'modules/scene/models/HolidayGreetingScene';
import {Scene} from 'modules/scene/models/Scene';
import {ShortVideoScene} from 'modules/scene/models/ShortVideoScene';
import {ClosedScene} from 'modules/scene/types';
import {makeEmptySceneAsset, makeSceneAsset} from 'modules/scene-asset/utils';
import {Task} from 'modules/task/models/Task';
import {TaskStatus} from 'modules/task/types';
import {nanoid} from 'nanoid';
import {getFixedHashtags} from 'utils/hashtag';

import {BilingualDialogueStoryboard} from './models/BilingualDialogueStoryboard';
import {BilingualStoryStoryboard} from './models/BilingualStoryStoryboard';
import {GeneralStoryStoryboard} from './models/GeneralStoryStoryboard';
import {HolidayGreetingStoryboard} from './models/HolidayGreetingStoryboard';
import {ShortVideoStoryboard} from './models/ShortVideoStoryboard';
import {Storyboard} from './models/Storyboard';
import {AFTask, AFTaskType, StoryboardAction, TaskType} from './types';

function expectedTaskType<T extends AFTaskType<TaskType>>(type: T): TaskType[] {
  switch (type) {
    case AFTaskTypeT.Storyboard:
      return [
        'generate_storyboard',
        'split_scene',
        'merge_scenes',
        'regenerate_scene_by_prompt',
      ];
    case AFTaskTypeT.PoseSceneImage:
      return ['regenerate_scene_by_pose_prompt'];
    case AFTaskTypeT.ImageConditioningVideo:
      return ['image_conditioning_video'];
    default:
      throw new Error(`Unsupported task type: ${type}`);
  }
}

export function checkoutTaskType<T extends AFTaskType<TaskType>>(
  type: T,
  task: Task<TaskType>
): TaskType {
  if (!expectedTaskType(type).includes(task.type)) {
    throw new Error(`Invalid task type: ${type} vs ${task.type}`);
  }
  return task.type;
}

export function checkoutAFTaskType<T extends TaskType>(type: T): AFTaskType<T> {
  switch (type) {
    case 'generate_storyboard':
    case 'split_scene':
    case 'merge_scenes':
    case 'regenerate_scene_by_prompt':
      return AFTaskTypeT.Storyboard as AFTaskType<T>;
    case 'regenerate_scene_by_pose_prompt':
      return AFTaskTypeT.PoseSceneImage as AFTaskType<T>;
    case 'image_conditioning_video':
      return AFTaskTypeT.ImageConditioningVideo as AFTaskType<T>;
    default:
      throw new Error(`Unsupported task type: ${type}`);
  }
}

export function checkoutStoryboardTemplate(
  storyboard: Storyboard<ProjectType>
): AFStoryboardTemplate {
  if (storyboard instanceof ShortVideoStoryboard) {
    if (storyboard.promptPolicy instanceof ShortVideoIdeaPromptPolicy) {
      return '/flashcard';
    } else {
      return '/base';
    }
  } else if (storyboard instanceof BilingualStoryStoryboard) {
    return '/bilingual_story';
  } else if (storyboard instanceof GeneralStoryStoryboard) {
    if (
      storyboard.promptPolicy instanceof GeneralStoryIdeaPromptPolicy &&
      storyboard.promptPolicy.quickPace
    ) {
      return '/flashcard';
    } else {
      return '/base';
    }
  } else if (storyboard instanceof BilingualDialogueStoryboard) {
    return '/bilingual_chat';
  } else if (storyboard instanceof HolidayGreetingStoryboard) {
    return '/holiday_greeting';
  } else {
    throw new Error(`Unsupported storyboard type: ${storyboard}`);
  }
}

export function checkoutStoryboardAction<
  T extends Extract<
    TaskType,
    'split_scene' | 'merge_scenes' | 'regenerate_scene_by_prompt'
  >
>(type: T): StoryboardAction<T> {
  switch (type) {
    case 'split_scene':
      return 'split_shot' as StoryboardAction<T>;
    case 'merge_scenes':
      return 'merge_shots' as StoryboardAction<T>;
    case 'regenerate_scene_by_prompt':
      return 'generate_image' as StoryboardAction<T>;
    default:
      throw new Error(`Unsupported task type: ${type}`);
  }
}

function makeCharacter(character: AFCharacter): CustomizedCharacter {
  return new CustomizedCharacter(
    character.id,
    character.name,
    character.description,
    character.character_type ?? 0,
    character.image
  );
}

export function makeScene(
  projectType: ProjectType,
  scene: AFScene
): Scene<ProjectType> {
  const asset = scene.image
    ? makeSceneAsset('image', {
        value: scene.image,
      })
    : undefined;
  if (['general_story', 'short_video'].includes(projectType)) {
    const TargetClass =
      projectType === 'general_story' ? GeneralStoryScene : ShortVideoScene;
    return new TargetClass(
      scene.id,
      scene.type,
      asset,
      asset && [asset],
      undefined,
      scene.prompt,
      scene.subtitle,
      scene.shot_type ?? 0,
      scene.characters
    );
  } else if (projectType === 'holiday_greeting') {
    return new HolidayGreetingScene(
      scene.id,
      scene.type,
      scene.holiday_image,
      asset,
      asset && [asset],
      undefined,
      scene.prompt,
      scene.subtitle,
      scene.shot_type ?? 0,
      scene.characters
    );
  } else if (projectType === 'bilingual_story') {
    return new BilingualStoryScene(
      scene.id,
      scene.type,
      asset,
      asset && [asset],
      undefined,
      scene.prompt,
      scene.subtitle,
      scene.native_subtitle,
      scene.shot_type ?? 0,
      scene.characters
    );
  } else if (projectType === 'bilingual_dialogue') {
    return new BilingualDialogueScene(
      scene.id,
      scene.type,
      asset,
      asset && [asset],
      undefined,
      scene.prompt,
      scene.subtitle,
      scene.native_subtitle,
      scene.shot_type ?? 0,
      scene.characters,
      scene.speaker
    );
  } else {
    throw new Error(`Unsupported project type: ${projectType}`);
  }
}

export function makeEmptyScene<T extends ProjectType>(
  storyboard: Storyboard<T>,
  params?: Partial<Scene<T>>
) {
  const asset = makeEmptySceneAsset();
  if (storyboard instanceof BilingualStoryStoryboard) {
    return new BilingualStoryScene(
      nanoid(),
      'empty_scene',
      asset,
      [asset],
      undefined,
      undefined,
      '',
      '',
      0
    );
  } else if (storyboard instanceof ShortVideoStoryboard) {
    return new ShortVideoScene(
      nanoid(),
      'empty_scene',
      asset,
      [asset],
      undefined,
      undefined,
      '',
      0
    );
  }
  if (storyboard instanceof BilingualDialogueStoryboard) {
    const {speaker} = (params || {}) as Partial<Scene<'bilingual_dialogue'>>;
    return new BilingualDialogueScene(
      nanoid(),
      'empty_scene',
      asset,
      [asset],
      undefined,
      undefined,
      '',
      '',
      0,
      [],
      speaker
    );
  }
  if (storyboard instanceof HolidayGreetingStoryboard) {
    return new HolidayGreetingScene(
      nanoid(),
      'empty_scene',
      undefined,
      asset,
      [asset],
      undefined,
      undefined,
      '',
      0
    );
  }
  return new GeneralStoryScene(
    nanoid(),
    'empty_scene',
    asset,
    [asset],
    undefined,
    undefined,
    '',
    0
  );
}

export function scenesFromAFTask(
  projectType: ProjectType,
  task: AFTask<AFTaskTypeT.Storyboard>
): Scene<ProjectType>[] {
  const scenes: Scene<ProjectType>[] = [];
  for (const scene of task.scenes) {
    if (scene.status === AFTaskStatus.Failure) continue;
    scenes.push(makeScene(projectType, scene));
  }
  return scenes;
}

export function storyboardFromAFTask<T extends Storyboard<ProjectType>>(
  storyboard: T,
  task: AFTask<AFTaskTypeT.Storyboard>,
  status: TaskStatus
): T | null {
  const {
    script,
    title,
    description,
    hashtags,
    characters = [],
    scenes = [],
  } = storyboard;

  const value: Record<string, unknown> = {};
  if (task.script !== script) value.script = task.script;
  if (task.title !== title) value.title = task.title;
  if (
    (storyboard instanceof BilingualStoryStoryboard ||
      storyboard instanceof BilingualDialogueStoryboard) &&
    task.nativeTitle !== storyboard.nativeTitle
  )
    value.nativeTitle = task.nativeTitle;
  if (task.abstract !== description) value.description = task.abstract;
  if (task.hashtags) {
    if (!hashtags || (hashtags.length === 0 && task.hashtags.length !== 0)) {
      //双语故事需要拼接上自己的固定tags
      value.hashtags = appendFixedHashtags(storyboard, task.hashtags ?? []);
    } else {
      for (const tag of hashtags ?? []) {
        if (!task.hashtags?.includes(tag)) {
          //双语故事需要拼接上自己的固定tags
          value.hashtags = appendFixedHashtags(storyboard, task.hashtags ?? []);
          break;
        }
      }
    }
  }

  function mergeCharacters() {
    const value: CustomizedCharacter[] = [];
    const diff: CustomizedCharacter[] = [];
    for (const character of task.characters) {
      if (character.status === AFTaskStatus.Failure) continue;
      const found = characters.find(c => c.id === character.id);
      if (
        !found ||
        found.name !== character.name ||
        found.description !== character.description ||
        found.image !== character.image ||
        found.characterType !== character.character_type
      ) {
        const next = makeCharacter(character);
        value.push(next);
        diff.push(next);
      } else {
        value.push(found);
      }
    }
    return diff.length > 0 || value.length !== characters.length ? value : null;
  }

  function mergeScenes() {
    const value: Scene<ProjectType>[] = [];
    const diff: Scene<ProjectType>[] = [];
    for (const scene of task.scenes) {
      if (scene.status === AFTaskStatus.Failure) continue;
      const found = scenes.find(s => s.id === scene.id);
      if (
        !found ||
        found.type !== scene.type ||
        found.prompt !== scene.prompt ||
        found.subtitle !== scene.subtitle ||
        ((found instanceof BilingualStoryScene ||
          found instanceof BilingualDialogueScene) &&
          found.nativeSubtitle !== scene.native_subtitle) ||
        (scene.image &&
          (!found.currentAsset ||
            found.currentAsset.type !== 'image' ||
            found.currentAsset.value !== scene.image)) ||
        found.shotType !== scene.shot_type ||
        found.characters?.length !== scene.characters?.length ||
        found.characters?.some(
          c =>
            !scene.characters?.find(
              ({name, prompt}) => c.name === name && c.prompt === prompt
            )
        )
      ) {
        const next = makeScene(getProjectTypeByStoryboard(storyboard), scene);
        value.push(next);
        diff.push(next);
      } else {
        value.push(found);
      }
    }
    return diff.length > 0 || value.length !== scenes.length ? value : null;
  }

  const nextCharacters = mergeCharacters();
  if (nextCharacters) value.characters = nextCharacters;

  const nextScenes = mergeScenes();
  if (nextScenes) value.scenes = nextScenes;

  if (!storyboard.task || !storyboard.tasks)
    throw new Error('Storyboard task not found');
  const nextTask =
    !task.closed &&
    storyboard.task.status === status &&
    storyboard.task.progress === task.progress?.ratio &&
    storyboard.task.estimatedRemainingTime === task.estimatedRemainingTime
      ? null
      : storyboard.task.patch({
          status,
          progress: task.progress?.ratio,
          estimatedRemainingTime: task.estimatedRemainingTime,
        });

  if (nextTask) {
    value.tasks = storyboard.tasks.map(t =>
      t.id === nextTask.id ? nextTask : t
    );
    value.task = nextTask;
  }

  if (Object.keys(value).length === 0) return null;
  return storyboard.patch(value) as T;
}

function appendFixedHashtags(
  storyboard: Storyboard<ProjectType>,
  hashtags: string[]
): string[] {
  if (storyboard instanceof BilingualStoryStoryboard) {
    return hashtags.concat(
      getFixedHashtags('bilingual_story', storyboard.nativeLanguage)
    );
  } else if (storyboard instanceof BilingualDialogueStoryboard) {
    return hashtags.concat(
      getFixedHashtags('bilingual_dialogue', storyboard.nativeLanguage)
    );
  } else {
    return hashtags;
  }
}

export function makeComposition<T extends ProjectType>(
  storyboard: Storyboard<T>
): Composition<T> {
  if (storyboard instanceof GeneralStoryStoryboard) {
    return new GeneralStoryComposition(
      nanoid(),
      storyboard.size,
      storyboard.language,
      storyboard.style,
      storyboard.promptPolicy,
      storyboard.prompt!,
      storyboard.title!,
      storyboard.description || '',
      storyboard.hashtags || [],
      (storyboard.characters
        ?.filter(character => character.isValid())
        .map(character => character.toClosed()) ||
        []) as ClosedCharacter<'general_story'>[],
      storyboard
        .scenes!.filter(scene => scene.isValid())
        .map(scene => scene.toClosed()) as ClosedScene<'general_story'>[]
    ) as Composition<T>;
  } else if (storyboard instanceof HolidayGreetingStoryboard) {
    return new HolidayGreetingComposition(
      nanoid(),
      storyboard.size,
      storyboard.language,
      storyboard.promptPolicy,
      storyboard.prompt!,
      storyboard.title!,
      storyboard.description || '',
      storyboard.hashtags || [],
      (storyboard.characters
        ?.filter(character => character.isValid())
        .map(character => character.toClosed()) ||
        []) as ClosedCharacter<'holiday_greeting'>[],
      storyboard
        .scenes!.filter(scene => scene.isValid())
        .map(scene => scene.toClosed()) as ClosedScene<'holiday_greeting'>[],
      storyboard.holiday,
      storyboard.figureStyle
    ) as Composition<T>;
  } else if (storyboard instanceof ShortVideoStoryboard) {
    return new ShortVideoComposition(
      nanoid(),
      storyboard.size,
      storyboard.language,
      storyboard.style,
      storyboard.promptPolicy,
      storyboard.prompt!,
      storyboard.title!,
      storyboard.description || '',
      storyboard.hashtags || [],
      (storyboard.characters
        ?.filter(character => character.isValid())
        .map(character => character.toClosed()) ||
        []) as ClosedCharacter<'short_video'>[],

      storyboard
        .scenes!.filter(scene => scene.isValid())
        .map(scene => scene.toClosed()) as ClosedScene<'short_video'>[]
    ) as Composition<T>;
  } else if (storyboard instanceof BilingualStoryStoryboard) {
    return new BilingualStoryComposition(
      nanoid(),
      storyboard.size,
      storyboard.language,
      storyboard.nativeLanguage,
      storyboard.vocabulary,
      storyboard.style,
      storyboard.promptPolicy,
      storyboard.prompt!,
      storyboard.title!,
      storyboard.nativeTitle!,
      storyboard.description || '',
      storyboard.hashtags || [],
      (storyboard!.characters
        ?.filter(character => character.isValid())
        .map(character => character.toClosed()) ||
        []) as ClosedCharacter<'bilingual_story'>[],

      storyboard!
        .scenes!.filter(scene => scene.isValid())
        .map(scene => scene.toClosed()) as ClosedScene<'bilingual_story'>[]
    ) as Composition<T>;
  } else if (storyboard instanceof BilingualDialogueStoryboard) {
    return new BilingualDialogueComposition(
      nanoid(),
      storyboard.size,
      storyboard.language,
      storyboard.nativeLanguage,
      storyboard.vocabulary,
      storyboard.style,
      storyboard.promptPolicy,
      storyboard.prompt!,
      storyboard.title!,
      storyboard.nativeTitle!,
      storyboard.description || '',
      storyboard.hashtags || [],
      (storyboard!.characters
        ?.filter(character => character.isValid())
        .map(character => character.toClosed()) ||
        []) as ClosedCharacter<'bilingual_dialogue'>[],

      storyboard!
        .scenes!.filter(scene => scene.isValid())
        .map(scene => scene.toClosed()) as ClosedScene<'bilingual_dialogue'>[]
    ) as Composition<T>;
  } else {
    throw new Error(`Unknown storyboard type: ${storyboard}`);
  }
}
export function getProjectTypeByStoryboard(
  storyboard: Storyboard<ProjectType>
): ProjectType {
  if (storyboard instanceof BilingualStoryStoryboard) {
    return 'bilingual_story';
  } else if (storyboard instanceof GeneralStoryStoryboard) {
    return 'general_story';
  } else if (storyboard instanceof HolidayGreetingStoryboard) {
    return 'holiday_greeting';
  } else if (storyboard instanceof ShortVideoStoryboard) {
    return 'short_video';
  } else if (storyboard instanceof BilingualDialogueStoryboard) {
    return 'bilingual_dialogue';
  } else {
    throw new Error(`Unknown storyboard type: ${storyboard}`);
  }
}
//storyboard是GeneralStoryStoryboard、ShortVideoStoryboard、BilingualStoryStoryboard、BilingualDialogueStoryboard的有style
export function storyboardHasStyle(
  storyboard: Storyboard<ProjectType>
): storyboard is
  | GeneralStoryStoryboard
  | ShortVideoStoryboard
  | BilingualStoryStoryboard
  | BilingualDialogueStoryboard {
  return (
    storyboard instanceof GeneralStoryStoryboard ||
    storyboard instanceof ShortVideoStoryboard ||
    storyboard instanceof BilingualStoryStoryboard ||
    storyboard instanceof BilingualDialogueStoryboard
  );
}
