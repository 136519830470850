// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useResourceManager} from 'contexts/ResourceManager';
import {patch as patchPreference} from 'modules/preference/models/Preference';
import {BilingualDialogueProject} from 'modules/project/models/BilingualDialogueProject';
import {BilingualStoryProject} from 'modules/project/models/BilingualStoryProject';
import {GeneralStoryProject} from 'modules/project/models/GeneralStoryProject';
import {HolidayGreetingProject} from 'modules/project/models/HolidayGreetingProject';
import {patch as patchProject} from 'modules/project/models/Project';
import {
  BilingualDialogueIdeaPromptPolicy,
  BilingualStoryIdeaPromptPolicy,
  GeneralStoryIdeaPromptPolicy,
  ScriptPromptPolicy,
  ShortVideoIdeaPromptPolicy,
} from 'modules/project/models/PromptPolicy';
import {ShortVideoProject} from 'modules/project/models/ShortVideoProject';
import {BilingualDialogueProjectConfig} from 'modules/project-config/models/BilingualDialogueProjectConfig';
import {BilingualStoryProjectConfig} from 'modules/project-config/models/BilingualStoryProjectConfig';
import {GeneralStoryProjectConfig} from 'modules/project-config/models/GeneralStoryProjectConfig';
import {HolidayGreetingProjectConfig} from 'modules/project-config/models/HolidayGreetingProjectConfig';
import {patch as patchProjectConfig} from 'modules/project-config/models/ProjectConfig';
import {ShortVideoProjectConfig} from 'modules/project-config/models/ShortVideoProjectConfig';
import {
  isWithSubtitleStyleByProjectConfig,
  isWithTitleStyleByProjectConfig,
} from 'modules/project-config/utils';
import {useCallback} from 'react';

import {PromptPage} from './PromptPage';
import {HookParam, HookReturn} from './PromptPage.types';

function useHook({
  project,
  updateProject,
  pageType,
  updatePreference,
}: HookParam): HookReturn {
  const {
    getDefaultVoiceover,
    getDefaultTitleStyle,
    getDefaultSubtitleStyle,
    getNativeLanguages,
    getTitleBilingualCombinationsByLanguage,
    getSubtitleBilingualCombinationsByLanguage,
    getHolidayCards,
  } = useResourceManager();

  //集合了所有的onChange事件
  const onChange = useCallback(
    (
      key:
        | 'prompt'
        | 'language'
        | 'size'
        | 'style'
        | 'tone'
        | 'proficiencyLevel'
        | 'paragraphAsShots'
        | 'quickPace'
        | 'nativeLanguage'
        | 'vocabulary'
        | 'characters'
        | 'avatar'
        | 'holiday'
        | 'figureStyle'
        | 'images',
      value: any
    ) => {
      if (
        ['prompt'].includes(key) ||
        ('vocabulary' === key &&
          (project instanceof BilingualStoryProject ||
            project instanceof BilingualDialogueProject))
      ) {
        updateProject(patchProject(project, {[key]: value}), true);
      } else if (
        ['size', 'style', 'figureStyle', 'images'].includes(key) ||
        ('nativeLanguage' === key &&
          (project instanceof BilingualStoryProject ||
            project instanceof BilingualDialogueProject))
      ) {
        if (key === 'nativeLanguage') {
          const titleBilingualCombinations =
            getTitleBilingualCombinationsByLanguage(project.language, value);

          const subtitleBilingualCombinations =
            getSubtitleBilingualCombinationsByLanguage(project.language, value);

          const newTitleStyle = titleBilingualCombinations[0].name;
          const newSubtitleStyle = subtitleBilingualCombinations[0].name;
          if (project instanceof BilingualDialogueProject) {
            updateProject(
              patchProject(project, {
                [key]: value,
                config: patchProjectConfig(project.config, {
                  titleStyle: newTitleStyle,
                  subtitleStyle: newSubtitleStyle,
                }) as BilingualDialogueProjectConfig,
              }),
              false
            );
          } else {
            updateProject(
              patchProject(project, {
                [key]: value,
                config: patchProjectConfig(project.config, {
                  titleStyle: newTitleStyle,
                  subtitleStyle: newSubtitleStyle,
                }) as BilingualStoryProjectConfig,
              }),
              false
            );
          }
        } else {
          updateProject(patchProject(project, {[key]: value}), false);
        }
      } else if (key === 'language') {
        const newVoiceover =
          project instanceof GeneralStoryProject ||
          project instanceof HolidayGreetingProject ||
          project instanceof ShortVideoProject
            ? getDefaultVoiceover(value).name
            : project.type === 'bilingual_story'
            ? project.config.voiceover
            : undefined;

        let newNativeLanguage: string | undefined = undefined;
        let newTitleStyle = isWithTitleStyleByProjectConfig(project.config)
          ? project.config.titleStyle
          : undefined;
        let newSubtitleStyle = isWithSubtitleStyleByProjectConfig(
          project.config
        )
          ? project.config.subtitleStyle
          : undefined;

        if (
          project instanceof BilingualStoryProject ||
          project instanceof BilingualDialogueProject
        ) {
          const nativeLanguages = getNativeLanguages(value);
          newNativeLanguage =
            nativeLanguages.find(({code}) => code === project.nativeLanguage)
              ?.code ?? nativeLanguages[0].code;
          const titleBilingualCombinations =
            getTitleBilingualCombinationsByLanguage(value, newNativeLanguage);
          const subtitleBilingualCombinations =
            getSubtitleBilingualCombinationsByLanguage(
              value,
              newNativeLanguage
            );
          newTitleStyle = titleBilingualCombinations[0].name;
          newSubtitleStyle = subtitleBilingualCombinations[0].name;
        } else if (
          project instanceof GeneralStoryProject ||
          project instanceof ShortVideoProject
        ) {
          newTitleStyle = getDefaultTitleStyle(value).name;
          newSubtitleStyle = getDefaultSubtitleStyle(value).name;
        } else if (project instanceof HolidayGreetingProject) {
          const subtitleStyleMap = getHolidayCards().find(
            item => item.name === project.config.cardDesign
          )?.subtitle_styles;
          newSubtitleStyle = subtitleStyleMap && subtitleStyleMap[value];
        }

        if (project instanceof GeneralStoryProject) {
          updateProject(
            patchProject<typeof project>(project, {
              language: value,
              config: patchProjectConfig(project.config, {
                voiceover: newVoiceover,
                titleStyle: newTitleStyle,
                subtitleStyle: newSubtitleStyle,
              }) as GeneralStoryProjectConfig,
            }),
            false
          );
        } else if (project instanceof ShortVideoProject) {
          updateProject(
            patchProject<typeof project>(project, {
              language: value,
              config: patchProjectConfig(project.config, {
                voiceover: newVoiceover,
                titleStyle: newTitleStyle,
                subtitleStyle: newSubtitleStyle,
              }) as ShortVideoProjectConfig,
            }),
            false
          );
        } else if (project instanceof BilingualStoryProject) {
          updateProject(
            patchProject<typeof project>(project, {
              language: value,
              nativeLanguage: newNativeLanguage,
              config: patchProjectConfig(project.config, {
                voiceover: newVoiceover,
                titleStyle: newTitleStyle,
                subtitleStyle: newSubtitleStyle,
              }) as BilingualStoryProjectConfig,
            }),
            false
          );
        } else if (project instanceof BilingualDialogueProject) {
          updateProject(
            patchProject<typeof project>(project, {
              language: value,
              nativeLanguage: newNativeLanguage,
              config: patchProjectConfig(project.config, {
                titleStyle: newTitleStyle,
                subtitleStyle: newSubtitleStyle,
              }) as BilingualDialogueProjectConfig,
            }),
            false
          );
        } else if (project instanceof HolidayGreetingProject) {
          updateProject(
            patchProject<typeof project>(project, {
              language: value,
              config: patchProjectConfig(project.config, {
                voiceover: newVoiceover,
                subtitleStyle: newSubtitleStyle ?? '',
              }) as HolidayGreetingProjectConfig,
            }),
            false
          );
        }
      } else if (
        key === 'proficiencyLevel' &&
        (project.promptPolicy instanceof BilingualStoryIdeaPromptPolicy ||
          project.promptPolicy instanceof BilingualDialogueIdeaPromptPolicy)
      ) {
        updateProject(
          patchProject(project, {
            promptPolicy: project.promptPolicy.patch({
              [key]: value,
            }),
          }),
          false
        );
      } else if (
        key === 'tone' &&
        (project.promptPolicy instanceof GeneralStoryIdeaPromptPolicy ||
          project.promptPolicy instanceof ShortVideoIdeaPromptPolicy)
      ) {
        updateProject(
          patchProject(project, {
            promptPolicy: project.promptPolicy.patch({
              [key]: value,
            }),
          }),
          false
        );
      } else if (
        key === 'holiday' &&
        project instanceof HolidayGreetingProject
      ) {
        updateProject(
          patchProject(project, {
            holiday: value,
          }),
          false
        );
      } else if (
        key === 'paragraphAsShots' &&
        project.promptPolicy instanceof ScriptPromptPolicy
      ) {
        updateProject(
          patchProject(project, {
            promptPolicy: project.promptPolicy.patch({
              [key]: value,
            }),
          }),
          false
        );
      } else if (
        key === 'quickPace' &&
        project.promptPolicy instanceof GeneralStoryIdeaPromptPolicy
      ) {
        updateProject(
          patchProject(project, {
            promptPolicy: project.promptPolicy.patch({
              [key]: value,
            }),
          }),
          false
        );
      } else if (
        key === 'characters' &&
        project instanceof BilingualDialogueProject
      ) {
        updateProject(
          patchProject(project, {
            [key]: value,
          }),
          false
        );
      } else if (
        key === 'avatar' &&
        (project instanceof BilingualStoryProject ||
          project instanceof GeneralStoryProject ||
          project instanceof ShortVideoProject)
      ) {
        if (project instanceof BilingualStoryProject) {
          updateProject(
            patchProject(project, {
              config: patchProjectConfig(project.config, {
                [key]: value as BilingualStoryProjectConfig['avatar'],
              }),
            }),
            false
          );
        } else if (project instanceof GeneralStoryProject) {
          updateProject(
            patchProject(project, {
              config: patchProjectConfig(project.config, {
                [key]: value as GeneralStoryProjectConfig['avatar'],
              }),
            }),
            false
          );
        } else if (project instanceof ShortVideoProject) {
          updateProject(
            patchProject(project, {
              config: patchProjectConfig(project.config, {
                [key]: value as ShortVideoProjectConfig['avatar'],
              }),
            }),
            false
          );
        }
      }
      if (
        [
          'language',
          'nativeLanguage',
          'size',
          'style',
          'tone',
          'proficiencyLevel',
          'paragraphAsShots',
          'quickPace',
          'characters',
          'avatar',
          'holiday',
        ].includes(key) &&
        pageType === 'projectPage'
      ) {
        if (key === 'characters') {
          updatePreference(
            preference =>
              patchPreference(preference, {
                ['characterOption']: value === null ? '1' : '2',
              }),
            true
          );
        } else {
          updatePreference(
            preference => patchPreference(preference, {[key]: value}),
            true
          );
        }
      }
    },
    [
      project,
      pageType,
      updateProject,
      getTitleBilingualCombinationsByLanguage,
      getSubtitleBilingualCombinationsByLanguage,
      getDefaultVoiceover,
      getNativeLanguages,
      getDefaultTitleStyle,
      getDefaultSubtitleStyle,
      getHolidayCards,
      updatePreference,
    ]
  );

  return {
    project,
    updateProject: updateProject,
    onChange,
  };
}

export const PromptPageContainer = combine(useHook, [
  'project',
  'updateProject',
  'pageType',
  'updatePreference',
])(PromptPage);
