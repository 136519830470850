// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useResourceManager} from 'contexts/ResourceManager';

import styles from './LayoutPreview.module.scss';

export function LayoutPreview({
  label,
  value,
  isSelected,
  avatarImage,
  onSelect,
}: {
  label: string;
  value: string | null;
  isSelected: boolean;
  avatarImage: string;
  onSelect: (layout: string | null) => void;
}) {
  const {resUrl} = useResourceManager();
  return (
    <div
      className={`${styles['layout-option']} ${
        isSelected ? styles.selected : ''
      }`}
      onClick={() => onSelect(value)}
    >
      <div className={styles['layout-preview']}>
        {value !== null && (
          <div
            className={classNames(
              styles['layout-preview-image-wrapper'],
              value === 'figure_left' && styles['figure-left'],
              value === 'figure_right' && styles['figure-right'],
              value === 'circle_left' && styles['circle-left'],
              value === 'circle_right' && styles['circle-right']
            )}
          >
            <img
              src={`${resUrl}${avatarImage}`}
              className={classNames(styles['layout-preview-avatar'])}
            />
          </div>
        )}
      </div>
      <span className={styles['layout-label ']}>{label}</span>
    </div>
  );
}
