// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {ProjectType} from 'modules/project/types';
import {Scene} from 'modules/scene/models/Scene';

import {SceneList} from './SceneList';
import {HookParams} from './SceneList.type';

function useHook({scenes, updateScene}: HookParams<ProjectType>) {
  const onUpdateScene = function (
    sceneId: string,
    params: Parameters<Scene<ProjectType>['patch']>[0],
    delay = true
  ) {
    if (!scenes) return;
    updateScene(sceneId, prev => prev.patch(params), delay);
  };

  return {
    scenes,
    onUpdateScene,
  };
}

export const SceneListContainer = combine(useHook, ['scenes', 'updateScene'])(
  SceneList
);
