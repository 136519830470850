// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as WarningIcon} from 'assets/svg/3.0/Warning.svg';
import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getTextareaLines} from 'utils/get-textarea-lines';

import styles from './TextEditor.module.scss';
import {Props} from './TextEditor.types';

export function TextEditor(props: Props) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [lines, setLines] = useState(1);
  const [_isFocused, _setIsFocused] = useState(false);
  const prefixRef = useRef<HTMLSpanElement>(null);
  const {t} = useTranslation('project');

  const {
    label = 'Title',
    isDisabled,
    text,
    onChange,
    layout = 'vertical',
    isOverLength,
    placeholder,
    overLengthTips = t('Try summarize the title, max 100 characters'),
    size = 'small',
    isFocused,
    onFocus,
    // title 部分交互为 100 字符提示 Error ，200 字符不能输入。 Subtitle 部分为 300 字符提示 Error ，无字符长限制
    maxLength = Infinity,
    rtlClassName = 'rtl-element',
    prefix,
  } = props;

  useEffect(() => {
    const prefixDom = prefixRef.current;
    if (prefixDom) {
      const isRtl = getComputedStyle(prefixDom).direction === 'rtl';
      // todo 这部分样式 改成根据外部容器获取对应的数据
      if (isRtl) {
        prefixDom.style.left = 'auto';
        prefixDom.style.right = '10px';
        prefixDom.style.marginRight = '0px';
        prefixDom.style.marginLeft = '4px';
      } else {
        prefixDom.style.left = '10px';
        prefixDom.style.right = 'auto';
        prefixDom.style.marginRight = '4px';
        prefixDom.style.marginLeft = '0px';
      }
    }
  }, [prefix]);

  useEffect(() => {
    if (ref.current) {
      setLines(getTextareaLines(ref.current));
    }
  }, [text]);

  useEffect(() => {
    setTimeout(() => {
      const prefixDom = prefixRef.current;
      const container = ref.current;
      const textIndent = prefixDom?.clientWidth ?? 0;
      if (prefixDom && container) {
        container.style.textIndent = `${textIndent + 4}px`;
      }
    }, 0);
  }, [prefix, rtlClassName]);

  useEffect(() => {
    const container = ref.current;
    const prefixDom = prefixRef.current;
    const top = prefixDom?.offsetTop || 0;
    const onScroll = () => {
      if (container && prefixDom) {
        prefixDom.style.top = `${top - container.scrollTop}px`;
      }
    };
    if (container) {
      container.addEventListener('scroll', onScroll);
    }
    return () => {
      container && container.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={classNames(styles.container)}>
      <div
        className={classNames(styles.box, {
          [styles.row]: layout === 'horizontal',
          [styles.column]: layout === 'vertical',
        })}
      >
        {label !== false && <p className={styles.label}>{label}</p>}
        <div
          className={classNames(styles['input-outer'], {
            [styles.focused]: isFocused ?? _isFocused,
            [styles.medium]: size === 'medium' || lines >= 2,
            [styles.large]: size === 'large',
            [styles.disabled]: isDisabled,
          })}
        >
          <div className={styles.mask}>
            <textarea
              onFocus={e => {
                _setIsFocused(true);
                onFocus?.(e);
              }}
              ref={ref}
              className={classNames(styles.input, rtlClassName)}
              readOnly={isDisabled}
              value={text}
              maxLength={maxLength}
              onChange={e => onChange(e.currentTarget.value)}
              onBlur={() => {
                _setIsFocused(false);
              }}
              placeholder={placeholder}
            />
            {prefix && (
              <span
                ref={prefixRef}
                className={classNames(styles.prefixContainer, rtlClassName)}
              >
                <span className={styles.prefix}>{prefix}</span>
              </span>
            )}
          </div>
        </div>
      </div>
      {isOverLength && (
        <div className={styles.warning}>
          <WarningIcon className={styles['warning-icon']} />
          <p className={styles.text}>{overLengthTips}</p>
        </div>
      )}
    </div>
  );
}
