// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DownArrowIcon} from 'assets/svg//outline/Down.svg';
import classNames from 'classnames';

import styles from './CharacterPreview.module.scss';
import {Props} from './CharacterPreview.types';

export function CharacterPreview({label, disabled, avatarImage}: Props) {
  return (
    <div
      className={classNames(
        styles['previewer-box'],
        disabled && styles['disabled']
      )}
    >
      <span className={styles['previewer-box-label']}>
        {label}&nbsp;-&nbsp;
      </span>
      {!disabled && (
        <span className={styles['previewer-box-avatar']}>
          <img src={avatarImage} draggable={false} />
        </span>
      )}
      <DownArrowIcon className={styles['previewer-box-arrow']} />
    </div>
  );
}
