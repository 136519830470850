// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import 'cropperjs/dist/cropper.css';

import {ReactComponent as CoinIcon} from 'assets/svg/3.0/Coin.svg';
import {ReactComponent as CropIcon} from 'assets/svg/outline/AssetstoolCrop.svg';
import {ReactComponent as RemoveBgIcon} from 'assets/svg/outline/AssetsToolRemoveBg.svg';
import {ReactComponent as StyleTransferIcon} from 'assets/svg/outline/AssetsToolStyleTransfer.svg';
import classNames from 'classnames';
import {Button} from 'components/Button';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useUserContext} from 'contexts/UserContext';
import {handleDownload} from 'lib/image';
import {noop} from 'lodash';
import {getFileCapacityLimitByPlan} from 'modules/user-asset/utils';
import {LoadingPromptDialog} from 'pages/components/LoadingPrompt';
import {Toast} from 'pages/components/Toast';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './AssetEditor.module.scss';
import {AssetActionType, EditAssetProps, ErrorType} from './AssetEditor.types';
import {BackgroundRemoval} from './BackgroundRemoval';
import {Footer} from './Footer';
import {ImageCropper} from './ImageCropper';
import {StyleTransfer} from './StyleTransfer';

const actions = [
  {action: 'crop', icon: <CropIcon />},
  {action: 'rmBg', icon: <RemoveBgIcon />},
  {action: 'styleTransfer', icon: <StyleTransferIcon />},
];
export function AssetEditor({
  type,
  imageInfo,
  filename,
  imageSize,
  errorToastVisible,
  errorToastType,
  hideErrorToast,
  onReset,
  onApply,
  onChangeCurrentImageUrl,
  uploadToAssetLibrary,
  uploadAsset,
  executeTask,
  changeFileName,
}: EditAssetProps) {
  const [actionType, setActionType] = useState<AssetActionType>(type || 'crop');
  const {
    updateCredit,
    userInfo: {plan},
  } = useUserContext();
  const onChangeActionType: EditAssetProps['onChangeActionType'] = e => {
    const actionType = e.currentTarget.getAttribute(
      'data-action'
    ) as AssetActionType;
    setActionType(actionType);
  };
  const {showNotification} = useNotificationContext();
  //下载图片
  const onDownload = () => {
    imageInfo && handleDownload(imageInfo[0], filename);
  };

  const [processingType, setProcessingType] = useState<AssetActionType>();

  const {t} = useTranslation('workspace');

  const onProcessing = useCallback(
    async (status: 'start' | 'success' | 'failed', value?: unknown) => {
      if (status === 'start') return setProcessingType(actionType);
      if (status === 'success') {
        const {currentImage, fileSuffix} = value as {
          currentImage: string;
          fileSuffix: string;
        };
        let newFileName = filename;
        if (fileSuffix) {
          newFileName = `${filename.slice(
            0,
            filename.lastIndexOf('.') === -1
              ? filename.length
              : filename.lastIndexOf('.')
          )}.${fileSuffix}`;
          changeFileName(newFileName);
        }
        onChangeCurrentImageUrl(currentImage, actionType === 'styleTransfer');
        if (actionType === 'styleTransfer') {
          try {
            await uploadToAssetLibrary(currentImage, newFileName);
          } catch {
            // ignore
          }
          //刷新credit
          updateCredit();
        }
      } else if (status === 'failed') {
        if (actionType === 'rmBg') {
          showNotification({type: 'ERROR', message: 'Remove failed'});
        } else if (actionType === 'styleTransfer') {
          showNotification({type: 'ERROR', message: 'Transfer failed'});
        }
      }
      setProcessingType(undefined);
    },
    [
      actionType,
      filename,
      updateCredit,
      changeFileName,
      onChangeCurrentImageUrl,
      uploadToAssetLibrary,
      showNotification,
    ]
  );

  useEffect(() => setProcessingType(undefined), [actionType]);

  const actionRef = useRef<{action: () => void}>(null);

  const tools = (
    <div className={styles['tool-container']}>
      {actions.map(({action, icon}) => (
        <div
          key={action}
          className={classNames(styles['tool-item'], {
            [styles.active]: actionType === action,
          })}
          data-action={action}
          onClick={onChangeActionType}
        >
          {icon}
        </div>
      ))}
    </div>
  );

  let toastInfo = null;
  if (errorToastVisible) {
    switch (errorToastType) {
      case ErrorType.Capacity:
        toastInfo = {
          title: t('Upload failed'),
          content: t('All files storage capacity is limit.', {
            limit: getFileCapacityLimitByPlan(plan),
          }),
          showCancel: false,
          confirmText: t('OK'),
          onOk: hideErrorToast,
        };
        break;
      case ErrorType.UploadFailed:
        toastInfo = {
          title: t('Upload failed'),
          content: t('Upload failed, please try again.'),
          showCancel: false,
          confirmText: t('OK'),
          onOk: hideErrorToast,
        };
        break;
    }
  }

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.isDisabled]: imageInfo === undefined,
        })}
      >
        {actionType === 'crop' && (
          <ImageCropper
            imageInfo={imageInfo || ['', true]}
            onProcessing={onProcessing}
            tools={tools}
            ref={actionRef}
          />
        )}
        {actionType === 'rmBg' && (
          <BackgroundRemoval
            currentImageInfo={imageInfo}
            imageSize={imageSize}
            uploadAsset={uploadAsset}
            executeTask={executeTask}
            onProcessing={onProcessing}
            tools={tools}
            innerRef={actionRef}
          >
            <div className={styles['image-wrap']}>
              <img src={imageInfo && imageInfo[0]} />
            </div>
          </BackgroundRemoval>
        )}
        {actionType === 'styleTransfer' && (
          <StyleTransfer
            currentImageInfo={imageInfo}
            uploadAsset={uploadAsset}
            executeTask={executeTask}
            onProcessing={onProcessing}
            tools={tools}
            innerRef={actionRef}
          >
            <div className={styles['image-wrap']}>
              <img src={imageInfo && imageInfo[0]} />
            </div>
          </StyleTransfer>
        )}
      </div>
      <Footer
        onReset={onReset}
        onApply={onApply}
        onDownload={onDownload}
        showResetAll={!(imageInfo === undefined || (imageInfo && imageInfo[1]))}
      >
        {actionType === 'crop' && (
          <Button
            className={styles.btn}
            type="ghost"
            onClick={() => {
              actionRef.current?.action();
            }}
          >
            {t('Crop')}
          </Button>
        )}
        {actionType === 'rmBg' && (
          <Button
            className={styles.btn}
            type="ghost"
            onClick={() => {
              actionRef.current?.action();
            }}
          >
            {t('Remove')}
          </Button>
        )}
        {actionType === 'styleTransfer' && (
          <Button
            className={styles.btn}
            type="ghost"
            onClick={() => {
              actionRef.current?.action();
            }}
          >
            {t('Transfer')}
            <span className={styles.credits}>
              <CoinIcon className={styles.icon} /> 2
            </span>
          </Button>
        )}
      </Footer>
      <LoadingPromptDialog
        dialogShowing={processingType !== undefined}
        type="processing"
        onCloseDialog={noop}
      />
      {toastInfo && (
        <Toast
          title={toastInfo.title}
          visible={true}
          showCloseBtn={false}
          onOk={toastInfo.onOk}
          showCancel={toastInfo.showCancel}
          confirmText={toastInfo.confirmText}
        >
          {toastInfo.content}
        </Toast>
      )}
    </>
  );
}
