// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow.svg';
import {PreviewBox} from 'components/PreviewBox';
import {HolidayGreetingProjectConfig} from 'modules/project-config/models/HolidayGreetingProjectConfig';
import {
  getProjectTypeByProjectConfig,
  isWithAvatarByProjectConfig,
} from 'modules/project-config/utils';
import {Header} from 'pages/components/Header';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ConfigBox} from './ConfigBox';
import styles from './ConfigPage.module.scss';
import {Props} from './ConfigPage.types';

export function ConfigPage({
  title,
  thumbnail,
  language,
  onBack,
  onNext,
  ...props
}: Props) {
  const [isScrollTop, setIsScrollTop] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const onScroll = () => {
      if (container) {
        setIsScrollTop(container.scrollTop === 0);
      }
    };
    container.addEventListener('scroll', onScroll);
    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, []);

  const {t} = useTranslation('project');
  return (
    <div
      className={styles.container}
      ref={containerRef}
      id="config-box-container"
    >
      <Header
        languageCode={language}
        title={title}
        right={
          <button className={styles.composite} onClick={onNext}>
            {t('Composite')}
            <RightArrowIcon className={styles.icon} />
          </button>
        }
        isScrollTop={isScrollTop}
        labelType={getProjectTypeByProjectConfig(props.config)}
        onBack={onBack}
        background="#F7F7F7"
      />

      <div className={styles.content}>
        <div className={styles['preview-box']}>
          <PreviewBox
            preview={thumbnail}
            ratio={props.ratio}
            cardDesign={
              props.config instanceof HolidayGreetingProjectConfig
                ? props.config.cardDesign
                : undefined
            }
            loading={false}
            avatar={
              isWithAvatarByProjectConfig(props.config) && props.config.avatar
                ? props.config.avatar
                : undefined
            }
          />
        </div>
        <div className={styles['configs']}>
          <ConfigBox {...props} scrollContainer={containerRef} />
        </div>
      </div>
    </div>
  );
}
