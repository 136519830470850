// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {SceneAssetJSON, SceneAssetType} from '../types';
import {ColorSceneAsset} from './ColorSceneAsset';
import {ImageSceneAsset} from './ImageSceneAsset';
import {VideoSceneAsset} from './VideoSceneAsset';

export type SceneAsset<T extends SceneAssetType> = T extends 'image'
  ? ImageSceneAsset
  : T extends 'video'
  ? VideoSceneAsset
  : T extends 'color'
  ? ColorSceneAsset
  : never;

export function fromJSON<T extends SceneAssetType>(
  json: SceneAssetJSON
): SceneAsset<T> {
  switch (json.type) {
    case 'image':
      return ImageSceneAsset.fromJSON(json) as SceneAsset<T>;
    case 'video':
      return VideoSceneAsset.fromJSON(json) as SceneAsset<T>;
    case 'color':
      return ColorSceneAsset.fromJSON(json) as SceneAsset<T>;
    default:
      throw new Error(`Unknown scene history type: ${json}`);
  }
}
