// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Draft} from 'modules/draft/models/Draft';
import {SceneAsset} from 'modules/scene-asset/models/SceneAsset';
import {SceneAssetType} from 'modules/scene-asset/types';
import {Task} from 'modules/task/models/Task';

import {BaseClosedScene, BaseSceneJSON, TaskType} from '../types';

export abstract class BaseScene {
  constructor(
    readonly id: string,
    readonly type: string,
    readonly currentAsset?: SceneAsset<SceneAssetType>,
    readonly assets?: SceneAsset<SceneAssetType>[],
    readonly prompt?: string,
    readonly lastPrompt?: string,
    readonly subtitle?: string,
    readonly shotType?: number,
    readonly characters?: BaseSceneJSON['characters'],
    readonly draft?: Draft,
    readonly task?: Task<TaskType>,
    readonly animatePrompt?: string
  ) {}

  isValid() {
    if (!this.currentAsset) return false;
    return !(
      !this.subtitle &&
      this.currentAsset.type === 'color' &&
      !this.draft?.isValid()
    );
  }

  abstract patch(data: Partial<BaseScene>): BaseScene;

  toClosed(): BaseClosedScene {
    return {
      type: this.type,
      prompt: this.currentPrompt,
      subtitle: this.subtitle,
      shot_type: this.shotType,
      characters: this.characters,
      image: this.currentImage,
      video:
        this.currentAsset && this.currentAsset.type === 'video'
          ? this.currentAsset.value
          : undefined,
      background_color:
        this.currentAsset &&
        this.currentAsset.type === 'color' &&
        !this.draft?.isValid()
          ? this.currentAsset.value
          : undefined,
    };
  }

  toJSON(): BaseSceneJSON {
    return {
      id: this.id,
      type: this.type,
      prompt: this.prompt,
      last_prompt: this.lastPrompt,
      subtitle: this.subtitle,
      shot_type: this.shotType,
      characters: this.characters,
      draft: this.draft?.isValid() ? this.draft.toJSON() : undefined,
      task: this.task?.id,
      animate_prompt: this.animatePrompt,
      current_asset: this.currentAsset && this.currentAsset.id,
      assets: this.assets && this.assets.map(a => a.toJSON()),
    };
  }
  get currentPrompt() {
    return this.prompt ?? this.lastPrompt;
  }

  get currentImage() {
    if (this.currentAsset) {
      const type = this.currentAsset.type;
      if (type === 'video') return this.currentAsset.refImage;
      if (this.draft?.image) return this.draft.image;
      if (type === 'image') return this.currentAsset.value;
    }
    return undefined;
  }

  get canAnimate() {
    return !!(this.currentAsset && this.currentAsset.type === 'image');
  }
}
