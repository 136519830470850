// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LockedIcon} from 'assets/svg/outline/Locked.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useUserContext} from 'contexts/UserContext';

import styles from './StylesBox.module.scss';
import {Props} from './StylesBox.types';

export function StylesBox({
  value,
  resUrl,
  styleList,
  onChange,
  showUpgradeUnlockStyleBoxDialog,
}: Props) {
  const {
    userInfo: {plan},
  } = useUserContext();

  return (
    <ul className={classNames(styles['style-list'], 'style-list')}>
      {styleList.map(({id, image, display_name, name, supported_plans}) => {
        const canUse = supported_plans.includes(plan);
        const isSelected = value === name;

        return (
          <li
            className={classNames(
              styles.style,
              isSelected && styles.active,
              !canUse && styles.disabled
            )}
            key={id}
            onClick={() => {
              if (!isSelected && canUse) {
                onChange(name);
              } else if (!canUse) {
                showUpgradeUnlockStyleBoxDialog &&
                  showUpgradeUnlockStyleBoxDialog();
              }
            }}
          >
            <div className={styles['preview-box']}>
              <img src={resUrl + image} className={styles.preview} />
              <div className={styles.box}>
                <LockedIcon className={styles.locked} />
              </div>
            </div>

            <Popover
              triggerElement={
                <p className={classNames(styles['style-name'], 'style-name')}>
                  {display_name}
                </p>
              }
              trigger="hover"
              content={
                <p className={classNames(styles['style-name-popover'])}>
                  {display_name}
                </p>
              }
              direction="right"
              isControlByOverflow={true}
            />
          </li>
        );
      })}
    </ul>
  );
}
