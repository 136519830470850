// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './Vocabulary.module.scss';
type Props = {
  vocabulary: (string | null)[];
  onChange: (value: (string | null)[]) => void;
  maxLength: number;
  maxCount: number;
};
export function Vocabulary({vocabulary, onChange, maxLength, maxCount}: Props) {
  const isComposingRef = useRef(false);

  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd: React.CompositionEventHandler<
    HTMLInputElement
  > = event => {
    isComposingRef.current = false;
    const {value, dataset} = event.target as HTMLInputElement;
    validateInput(dataset.idx, value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value, dataset} = event.target;
    const {idx} = dataset;
    if (!isComposingRef.current) {
      validateInput(idx, value);
    } else {
      onChangeVocabulary(idx, value); // 保持未确定字符的显示
    }
  };
  const validateInput = (idx: string | undefined, value: string) => {
    const englishOnly = value.replace(/[^a-zA-Z-]/g, ''); // 只保留英文字符
    onChangeVocabulary(idx, englishOnly);
  };
  const onChangeVocabulary = (idx: string | undefined, value: string) => {
    if (idx) {
      //只能输入英文、中横线
      const newValue = value.slice(0, maxLength);
      const newVocabulary = [...vocabulary];
      newVocabulary[+idx] = newValue;
      onChange(newVocabulary);
    }
  };
  const {t} = useTranslation('project');
  return (
    <div className={styles['flex-container']}>
      {new Array(maxCount).fill(undefined).map((_, idx) => {
        return (
          <Popover
            key={idx}
            triggerElement={
              <input
                type="text"
                className={classNames(
                  styles['vocabulary-input'],
                  vocabulary[idx] && styles['with-value']
                )}
                placeholder={t('Word')}
                value={vocabulary[idx] ?? undefined}
                data-idx={idx}
                onChange={handleChange}
                maxLength={maxLength}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
              />
            }
            direction="bottom"
            trigger="hover"
            distance={9}
            content={
              <span className={styles['tip-container']}>{vocabulary[idx]}</span>
            }
            isControlByOverflow={true}
          />
        );
      })}
    </div>
  );
}
