// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useVisible} from 'lib/hooks';
import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Uploader} from './Uploader';
import {
  ErrorInfo,
  ErrorType,
  FileToUpload,
  HookParams,
  HookReturn,
} from './Uploader.type';
import EXIF from 'exif-js';

const MAX_FILE_SIZE = 25 * 1024 * 1024;

export function useHook({
  disabled,
  uploadFileAndEditAsset,
  accept,
  maxFileLength = 10,
}: HookParams): HookReturn {
  const [errorToastVisible, hideErrorToast, showErrorToast, errorType] =
    useVisible<ErrorType>();
  const {t} = useTranslation('common');
  const {t: workspaceT} = useTranslation('workspace');
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>({otherError: []});
  const filesToUploadRef = useRef<FileToUpload[]>([]);
  const filesUploadingRef = useRef<FileToUpload[]>([]);

  // 验证文件,拿到合规、符合一次上传限制的文件、所有的错误信息
  const verifyFiles = async (
    uploadedFiles: FileList | null
  ): Promise<{
    errorInfo: ErrorInfo;
    validFiles: FileToUpload[];
  }> => {
    if (!uploadedFiles) return {errorInfo: {otherError: []}, validFiles: []};
    let filesToUpload = Array.from(uploadedFiles);
    const errorInfo: ErrorInfo = {otherError: []};
    const allFilesSize = filesToUpload.reduce(
      (total, file) => total + file.size,
      0
    );
    if (
      (maxFileLength && filesToUpload.length > maxFileLength) ||
      allFilesSize > 100 * 1024 * 1024
    ) {
      errorInfo.maximumError = {
        message: workspaceT(
          'Max 10 files with 100MB upload at once, extra files will be discarded.',
          {
            maxLength: maxFileLength,
          }
        ),
      };
    }
    if (
      accept.length &&
      filesToUpload.some(file => !accept.includes(file.type))
    ) {
      errorInfo.otherError.push({
        message: workspaceT('Assets support files format .jpg, .png, .webp.'),
        details: filesToUpload
          .filter(file => !accept.includes(file.type))
          .map(file => file.name)
          .map(name => getFileNameAndExtension(name)),
      });
      filesToUpload = filesToUpload.filter(file => accept.includes(file.type));
    }

    //如果文件大小超过25MB，提示错误
    if (filesToUpload.filter(file => file.size > MAX_FILE_SIZE).length > 0) {
      errorInfo.otherError.push({
        message: t('File maximum size is 25MB.'),
        details: filesToUpload
          .filter(file => file.size > MAX_FILE_SIZE)
          .map(file => file.name)
          .map(name => getFileNameAndExtension(name)),
      });
      filesToUpload = filesToUpload.filter(file => file.size <= MAX_FILE_SIZE);
    }
    const pixelErrors: string[] = [];

    let validFiles: FileToUpload[] = [];
    const step = 3;
    //每次校验step个文件
    do {
      const filesToCheck = filesToUpload.splice(0, step);
      if (filesToCheck.length === 0 || validFiles.length >= maxFileLength) {
        break;
      }
      const {validFiles: files, pixelErrors: errors} =
        await checkPixelInfoOfFiles(filesToCheck);
      if (errors.length > 0) {
        pixelErrors.push(...errors);
      }
      if (files.length > 0 && validFiles.length >= maxFileLength) {
        // 如果已经达到最大文件数量，释放多余的blobImageUrl
        files.forEach(fileInfo => {
          URL.revokeObjectURL(fileInfo.blobImageUrl);
        });
      }
      if (files.length > 0 && validFiles.length <= maxFileLength) {
        const currentValidFilesLength = validFiles.length;
        validFiles.push(
          ...files.slice(0, maxFileLength - currentValidFilesLength)
        );
        // 释放多余的blobImageUrl
        files
          .slice(maxFileLength - currentValidFilesLength)
          .forEach(fileInfo => {
            URL.revokeObjectURL(fileInfo.blobImageUrl);
          });
      }
    } while (filesToUpload.length > 0);

    if (pixelErrors.length > 0) {
      errorInfo.otherError.push({
        message: workspaceT(
          'The max resolution supported is 8000 pixels for both length and width.'
        ),
        details: pixelErrors.map(name => getFileNameAndExtension(name)),
      });
    }
    //validFiles只保留100M以内的文件
    const validFilesIndex = getFilesLengthToUpload(validFiles);
    if (validFilesIndex < validFiles.length) {
      validFiles
        .slice(validFilesIndex)
        .forEach(fileInfo => URL.revokeObjectURL(fileInfo.blobImageUrl));
      validFiles = validFiles.slice(0, validFilesIndex);
    }
    return {errorInfo, validFiles};
  };
  const checkFilesAndUpload = async (uploadedFiles: FileList | null) => {
    verifyFiles(uploadedFiles).then(({validFiles, errorInfo}) => {
      if (errorInfo?.maximumError || errorInfo?.otherError.length > 0) {
        setErrorInfo(errorInfo);
        if (validFiles.length > 0) {
          filesToUploadRef.current = validFiles;
          showErrorToast('uploadFiles');
        } else {
          showErrorToast('uploadFailed');
        }
      } else {
        try {
          uploadFileAndEditAsset(validFiles);
        } catch (e) {
          console.error(e);
        }
      }
    });
  };
  const handleFileChange = async function (
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.preventDefault();
    const uploadedFiles = event.target.files;

    if (disabled || !uploadedFiles) return;
    checkFilesAndUpload(uploadedFiles).finally(() => {
      setTimeout(() => {
        event.target.value = '';
      }, 1000);
    });
  };

  const handleDrop = async function (event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (disabled) return;
    checkFilesAndUpload(event.dataTransfer.files);
  };
  // toast点击确认上传
  const handleUpload = function () {
    if (errorToastVisible) hideErrorToast();
    const isUploading = filesUploadingRef.current.length > 0;
    // 追加到正在上传的队列
    filesUploadingRef.current = [
      ...filesUploadingRef.current,
      ...filesToUploadRef.current,
    ];
    // 清空待上传队列
    filesToUploadRef.current = [];
    // 如果没有正在上传，触发循环上传
    if (!isUploading) {
      uploadFilesPoll();
    }
  };
  const uploadFilesPoll = async () => {
    // 一次最多上传10个文件
    const filesLengthToUpload = getFilesLengthToUpload(
      filesUploadingRef.current
    );
    const currentSpicesFileToUpload = filesUploadingRef.current.splice(
      0,
      filesLengthToUpload
    );
    if (currentSpicesFileToUpload.length === 0) return;
    await uploadFileAndEditAsset(currentSpicesFileToUpload);
    await uploadFilesPoll();
  };
  return {
    accept,
    disabled,
    errorToastVisible,
    errorInfo,
    errorType,
    handleFileChange,
    handleDrop,
    hideErrorToast,
    handleUpload,
  };
}
export const UploaderContainer = combine(useHook, [
  'disabled',
  'uploadFileAndEditAsset',
  'accept',
  'maxFileLength',
])(Uploader);

const validateImagesPixels = (files: FileList | File[]) => {
  const maxWidth = 8000;
  const maxHeight = 8000;

  // 校验单个图片的宽高
  const checkImageDimensions = (file: File): Promise<FileToUpload> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      const blobImageUrl = URL.createObjectURL(file);

      img.onload = () => {
        // 检查尺寸
        if (img.naturalWidth > maxWidth || img.naturalHeight > maxHeight) {
          reject(file.name);
          URL.revokeObjectURL(blobImageUrl);
          return;
        }

        // 读取 EXIF 信息
        EXIF.getData(file as any, function (this: any) {
          const orientation = EXIF.getTag(this, 'Orientation') || 1;

          // 如果不需要旋转，直接返回原始文件
          if (orientation === 1) {
            resolve({file, blobImageUrl});
            return;
          }

          // 创建 canvas 进行旋转
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            reject('Canvas context not supported');
            return;
          }

          // 根据方向设置画布尺寸和变换
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          switch (orientation) {
            case 6: // 顺时针旋转 90 度
              canvas.width = height;
              canvas.height = width;
              ctx.translate(canvas.width / 2, canvas.height / 2);
              ctx.drawImage(img, -width / 2, -height / 2, width, height);
              break;
            case 3: // 旋转 180 度
              canvas.width = width;
              canvas.height = height;
              ctx.translate(canvas.width / 2, canvas.height / 2);
              ctx.drawImage(img, -width / 2, -height / 2, width, height);
              break;
            case 8: // 逆时针旋转 90 度
              canvas.width = height;
              canvas.height = width;
              ctx.translate(canvas.width / 2, canvas.height / 2);
              ctx.drawImage(img, -width / 2, -height / 2, width, height);
              break;
            default:
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);
          }

          // 将 canvas 转换为 Blob
          canvas.toBlob(blob => {
            if (!blob) {
              reject('Failed to convert canvas to blob');
              return;
            }

            // 创建新的 File 对象
            const rotatedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            });

            // 为旋转后的图片创建新的 URL
            const rotatedBlobUrl = URL.createObjectURL(rotatedFile);

            // 释放原始的 URL
            URL.revokeObjectURL(blobImageUrl);

            resolve({
              file: rotatedFile,
              blobImageUrl: rotatedBlobUrl,
            });
          }, file.type);
        });
      };

      img.onerror = () => {
        reject(`${file.name} 加载失败`);
        URL.revokeObjectURL(blobImageUrl);
      };

      img.src = blobImageUrl;
    });
  // 批量处理所有文件
  return Array.from(files).map(checkImageDimensions);
};

function getFileNameAndExtension(filename: string) {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    // 没有后缀或是隐藏文件没有扩展名
    return {name: filename, extension: ''};
  }

  return {
    name: filename.slice(0, lastDotIndex), // 文件名部分
    extension: filename.slice(lastDotIndex), // 后缀名部分
  };
}

function getFilesLengthToUpload(files: FileToUpload[]) {
  let count = 0,
    size = 0;
  do {
    if (files[count]) {
      size += files[count].file.size;
      count++;
    } else {
      break;
    }
  } while (size < 100 * 1024 * 1024 && count < 10);

  return count;
}

async function checkPixelInfoOfFiles(filesToUpload: FileList | File[]) {
  let pixelErrors: string[] = [];

  let validFiles: FileToUpload[] = [];
  //验证图片的宽高
  await Promise.allSettled(validateImagesPixels(filesToUpload)).then(
    results => {
      validFiles = results
        .filter(result => result.status === 'fulfilled')
        .map(
          result =>
            (
              result as PromiseFulfilledResult<{
                file: File;
                blobImageUrl: string;
              }>
            ).value
        );
      pixelErrors = results
        .filter(result => result.status === 'rejected')
        .map(result => (result as PromiseRejectedResult).reason);
    }
  );
  return {validFiles, pixelErrors};
}
