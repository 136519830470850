// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import {AxiosResponse} from 'axios';
import {combine} from 'components/Combine';
import {useMessageContext} from 'contexts/MessageContext';
import {useUserContext} from 'contexts/UserContext';
import {useNavigate} from 'react-router-dom';
import {CONFIRM_EMAIL_PATH, ROOT_PATH} from 'utils/path';

import {usePasswordConfirmPassword, useQueryString} from '../modules';
import {DoResetPasswordPropsHookReturn} from './AuthForm.types';
import {AuthFormDoResetPassword} from './AuthFormDoResetPassword';

export function useHooks(): DoResetPasswordPropsHookReturn {
  const {
    password,
    confirmPassword,
    passwordErrorType,
    validPassword,
    validConfirmPassword,
    changePassword,
    changeConfirmPassword,
  } = usePasswordConfirmPassword();
  const getQueryParam = useQueryString();
  const {showMessage} = useMessageContext();
  const {updateUserLogin} = useUserContext();
  const navigate = useNavigate();

  const onConfirm = () => {
    const email = getQueryParam('email');
    const token = getQueryParam('token');
    if (!email || !token) {
      showMessage({type: 'ERROR', content: 'Invalid URL'});
      return;
    }
    if (validPassword() && validConfirmPassword() && email && token) {
      apiServer
        .doResetPasswordByEmail({email, token, password, confirmPassword})
        .then(_ => {
          apiServer
            .login({
              email,
              password,
            })
            .then(res => {
              updateUserLogin(
                email,
                res.data.userName,
                res.data.userId,
                res.data.portrait,
                res.data.token,
                res.data.emailConfirmStatus
              );
              navigate(ROOT_PATH);
              return true;
            })
            .catch((err: AxiosResponse<apiServer.Response>) => {
              const {error} = err.data || {};
              switch (error) {
                case apiServer.ResponseCode.USER_EMAIL_NOT_CONFIRM_YET:
                  navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
                  break;
              }
            });
        })
        .catch((err: AxiosResponse<apiServer.Response>) => {
          const {error} = err.data;
          switch (error) {
            case apiServer.ResponseCode.TOKEN_INVALID:
              showMessage({type: 'ERROR', content: 'Token invalid'});
              break;
          }
          return err;
        });
    }
  };
  return {
    password,
    setPassword: changePassword,
    confirmPassword,
    setConfirmPassword: changeConfirmPassword,
    passwordErrorType,
    validPassword,
    validConfirmPassword,
    onConfirm,
  };
}

export const AuthFormDoResetPasswordContainer = combine(useHooks)(
  AuthFormDoResetPassword
);
