import {useAwsFileUpload} from 'pages/WorkspacePage/modules';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {ProjectImage} from '../ImageUploader.types';
import styles from './Uploading.module.scss';

interface Props {
  data: ProjectImage;
  handleOk: (objectKey: string) => void;
}
export function Uploading({data, handleOk}: Props) {
  const initRef = useRef(false);
  const {uploadFile, uploadProgress} = useAwsFileUpload(objectKey => {
    handleOk(typeof objectKey === 'string' ? objectKey : objectKey[0]);
  });
  const {t} = useTranslation('workspace');
  useEffect(() => {
    if (data.file && !initRef.current) {
      initRef.current = true;
      uploadFile({file: data.file}, 'db');
    }
  }, [data.file, uploadFile]);

  useEffect(() => {
    return () => {
      if (data.blobUrl && uploadProgress === 100) {
        setTimeout(() => {
          URL.revokeObjectURL(data.blobUrl!);
        }, 6000);
      }
    };
  }, [data.blobUrl, uploadProgress]);

  return (
    <div className={styles.image_wrap_outer}>
      <div className={styles.image_wrap}>
        <img
          src={data.blobUrl}
          className={styles.image + ' ' + styles.image_uploading}
        />
        <div className={styles.image_wrap_mask_content}>
          <span>{t('uploading')}</span>
          <span>{uploadProgress}%</span>
        </div>
      </div>
    </div>
  );
}
