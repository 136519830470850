// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import removeBg from 'assets/images/assetToolRmbg.jpg';
import assetToolStyleTransfer from 'assets/images/assetToolStyleTransfer.jpg';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow3.svg';
import {ReactComponent as AssetsToolRemoveBgIcon} from 'assets/svg/outline/AssetsToolRemoveBg.svg';
import {ReactComponent as AssetsToolStyleTransferIcon} from 'assets/svg/outline/AssetsToolStyleTransfer.svg';
import {
  AssetToolAction,
  AssetToolProps,
} from 'components/AssetEditor/AssetEditor.types';
import {Button} from 'pages/components/Button';
import {useTranslation} from 'react-i18next';

import styles from './AssetTool.module.scss';
type ToolInfoMapType = {
  [key in AssetToolAction]: {
    icon: JSX.Element;
    title: string;
    subTitle: string;
    imageUrl: string;
  };
};

export function AssetTool({type, handleClick}: AssetToolProps) {
  const {t} = useTranslation('workspace');
  const toolInfoMap: ToolInfoMapType = {
    rmBg: {
      icon: <AssetsToolRemoveBgIcon />,
      title: t('Remove BG'),
      subTitle: t('Remove background with one click'),
      imageUrl: 'url(' + removeBg + ')',
    },
    styleTransfer: {
      icon: <AssetsToolStyleTransferIcon />,
      title: t('Style transfer'),
      subTitle: t('Transfer your photo into amazing styles'),
      imageUrl: 'url(' + assetToolStyleTransfer + ')',
    },
  };

  const imageStyle = {
    '--image-url': toolInfoMap[type].imageUrl,
  } as React.CSSProperties;

  const _handleClick = () => {
    handleClick(type);
  };

  return (
    <div className={styles.container} onClick={_handleClick}>
      <div className={styles['image-wrap']} style={imageStyle}>
        <div className={styles.icon}>{toolInfoMap[type].icon}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.info}>
          <span className={styles.title}>{toolInfoMap[type].title}</span>
          <span className={styles['sub-title']}>
            {toolInfoMap[type].subTitle}
          </span>
        </div>
        <div className={styles.footer}>
          <Button type="link">
            {t('Try now')} <RightArrowIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}
