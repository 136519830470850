// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {BilingualDialogueComposition} from 'modules/composition/models/BilingualDialogueComposition';
import {BilingualStoryComposition} from 'modules/composition/models/BilingualStoryComposition';
import {Composition} from 'modules/composition/models/Composition';
import {GeneralStoryComposition} from 'modules/composition/models/GeneralStoryComposition';
import {HolidayGreetingComposition} from 'modules/composition/models/HolidayGreetingComposition';
import {ShortVideoComposition} from 'modules/composition/models/ShortVideoComposition';
import {ProjectType} from 'modules/project/types';
import {Task} from 'modules/task/models/Task';
import {TaskType} from 'modules/task/types';

import {HistoryJSON} from '../types';
import {BilingualDialogueHistory} from './BilingualDialogueHistory';
import {BilingualStoryHistory} from './BilingualStoryHistory';
import {GeneralStoryHistory} from './GeneralStoryHistory';
import {HolidayGreetingHistory} from './HolidayGreetingHistory';
import {ShortVideoHistory} from './ShortVideoHistory';

export type History<T extends ProjectType> = T extends 'general_story'
  ? GeneralStoryHistory
  : T extends 'holiday_greeting'
  ? HolidayGreetingHistory
  : T extends 'short_video'
  ? ShortVideoHistory
  : T extends 'bilingual_story'
  ? BilingualStoryHistory
  : T extends 'bilingual_dialogue'
  ? BilingualDialogueHistory
  : never;

export function fromComposition<T extends ProjectType>(
  composition: Composition<T>,
  storyboardTasks: Task<TaskType>[],
  id?: string
): History<T> {
  if (composition instanceof GeneralStoryComposition) {
    return GeneralStoryHistory.fromComposition(
      composition,
      storyboardTasks,
      id
    ) as History<T>;
  } else if (composition instanceof HolidayGreetingComposition) {
    return HolidayGreetingHistory.fromComposition(
      composition,
      storyboardTasks,
      id
    ) as History<T>;
  } else if (composition instanceof ShortVideoComposition) {
    return ShortVideoHistory.fromComposition(
      composition,
      storyboardTasks,
      id
    ) as History<T>;
  } else if (composition instanceof BilingualStoryComposition) {
    return BilingualStoryHistory.fromComposition(
      composition,
      storyboardTasks,
      id
    ) as History<T>;
  } else if (composition instanceof BilingualDialogueComposition) {
    return BilingualDialogueHistory.fromComposition(
      composition,
      storyboardTasks,
      id
    ) as History<T>;
  } else {
    throw new Error(`Unknown composition type: ${composition}`);
  }
}

export function fromJSON<T extends ProjectType>(
  type: T,
  json: HistoryJSON<T>
): History<T> {
  if (type === 'bilingual_story') {
    return BilingualStoryHistory.fromJSON(
      json as HistoryJSON<'bilingual_story'>
    ) as History<T>;
  } else if (type === 'short_video') {
    return ShortVideoHistory.fromJSON(
      json as HistoryJSON<'short_video'>
    ) as History<T>;
  } else if (type === 'bilingual_dialogue') {
    return BilingualDialogueHistory.fromJSON(
      json as HistoryJSON<'bilingual_dialogue'>
    ) as History<T>;
  } else if (type === 'general_story') {
    return GeneralStoryHistory.fromJSON(
      json as HistoryJSON<'general_story'>
    ) as History<T>;
  } else if (type === 'holiday_greeting') {
    return HolidayGreetingHistory.fromJSON(
      json as HistoryJSON<'holiday_greeting'>
    ) as History<T>;
  } else {
    throw new Error(`Unknown project type: ${type}`);
  }
}
