// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as AnimateIcon} from 'assets/svg/3.0/Animate.svg';
import {ReactComponent as CoinIcon} from 'assets/svg/3.0/Coin.svg';
import {ReactComponent as LockIcon} from 'assets/svg/3.0/Lock.svg';
import {ReactComponent as LoadingSvg} from 'assets/svg/outline/LoadingLine.svg';
import classNames from 'classnames';
import {AnimateInfo, AnimateSelect} from 'components/AnimateSelect';
import {ErrorType} from 'components/SceneEditor/SceneEditor.types';
import {useUserContext} from 'contexts/UserContext';
import {TFunction} from 'i18next';
import {useVisible} from 'lib/hooks';
import {ProjectType} from 'modules/project/types';
import {BilingualDialogueScene} from 'modules/scene/models/BilingualDialogueScene';
import {BilingualStoryScene} from 'modules/scene/models/BilingualStoryScene';
import {GeneralStoryScene} from 'modules/scene/models/GeneralStoryScene';
import {AnimateOptionTypeEnums} from 'modules/scene/utils';
import {ANIMATE_PRICE} from 'modules/task/utils';
import {LoadingPrompt} from 'pages/components/LoadingPrompt';
import {Toast} from 'pages/components/Toast';
import {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'utils/path';

import {BilingualDialogueSceneInsertActionTipsDialog} from './BilingualDialogueSceneInsertActionTips';
import {ActionType, InsertType} from './SceneActionMenu';
import {SceneActionTipsDialog} from './SceneActionTips';
import {SceneItem} from './SceneItem';
import styles from './SceneList.module.scss';
import {SceneListPlaceholder} from './SceneList.placeholder';
import {AnimationType, Props, ToastProps} from './SceneList.type';

export function SceneList(props: Props<ProjectType>) {
  const {
    scenes,
    sceneSubtitleMaxLength,
    maxParagraphsNumber,
    ratio,
    isProcessing,
    selectedSceneIndex,
    newSceneIds,
    animatingInfo,
    disabledAnimateAll,
    onAction,
    changeSelectedSceneIndex,
    getImageWithObjectKey,
    refreshImage,
    onGenerateAnimationAll,
    onUpdateScene,
    characterNames,
  } = props;
  const listRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [
    animateAllToastVisible,
    hideAnimateAllToast,
    showAnimateAllToast,
    animateAllToastType,
  ] = useVisible<ErrorType>();

  const [actionInfo, setActionInfo] = useState<{
    type: Exclude<ActionType | InsertType, 'regenerate_scene_by_prompt'>;
    sceneId: string;
  } | null>(null);
  const validScenesNumber = useMemo(
    () =>
      (scenes &&
        scenes.filter(scene => !scene.task || scene.task.isEndedStatus)
          .length) ||
      0,
    [scenes]
  );

  const sceneAnimateStatusInfo = useMemo(() => {
    const sceneAnimateInfoMap = new Map<string, number>();
    scenes &&
      scenes.forEach((scene, idx) => {
        const task =
          scene.task &&
          scene.task.type === 'image_conditioning_video' &&
          !scene.task.isEndedStatus;
        const getAnimateInfo = (sceneId: string) =>
          sceneAnimateInfoMap.get(sceneId) || 0;
        if (task) {
          sceneAnimateInfoMap.set(
            scene.id,
            getAnimateInfo(scene.id) | AnimationType.isAnimating
          );
          if (scenes[idx - 1]) {
            sceneAnimateInfoMap.set(
              scenes[idx - 1].id,
              getAnimateInfo(scenes[idx - 1].id) | AnimationType.nextIsAnimating
            );
          }
          if (scenes[idx + 1]) {
            sceneAnimateInfoMap.set(
              scenes[idx + 1].id,
              getAnimateInfo(scenes[idx + 1].id) | AnimationType.prevIsAnimating
            );
          }
        }
      });
    return sceneAnimateInfoMap;
  }, [scenes]);

  const canAnimateSceneCount = useMemo(
    () => scenes?.filter(scene => scene.canAnimate).length || 0,
    [scenes]
  );

  const {
    userInfo: {plan},
  } = useUserContext();

  const _onAction = useCallback(
    (
      type: Exclude<ActionType, 'regenerate_scene_by_prompt'> | InsertType,
      sceneId: string
    ) => {
      if (!scenes) return;
      const scene = scenes.find(scene => scene.id === sceneId);
      if (!scene) return;
      if (['insert_after', 'insert_before'].includes(type)) {
        if (scene instanceof BilingualDialogueScene) {
          setActionInfo({
            type: type as Exclude<ActionType, 'regenerate_scene_by_prompt'>,
            sceneId,
          });
          return;
        }
        onAction(type, sceneId);
        return;
      } else if (
        type === 'delete_scene' &&
        scene.subtitle === '' &&
        scene.currentAsset!.type === 'color' &&
        scene.assets!.length === 1
      ) {
        onAction('delete_scene', sceneId);
        return;
      } else {
        setActionInfo({
          type: type as Exclude<ActionType, 'regenerate_scene_by_prompt'>,
          sceneId,
        });
      }
    },
    [onAction, scenes]
  );

  const onAnimateAll = useCallback(() => {
    if (plan === 'FREE') return showAnimateAllToast('Upgrade');
    showAnimateAllToast('AnimateAll');
  }, [plan, showAnimateAllToast]);

  const {t} = useTranslation('project');

  if (!scenes || scenes.length === 0) {
    return <SceneListPlaceholder />;
  }
  const toastProps = getToastProps({
    toastType: animateAllToastType,
    toastVisible: animateAllToastVisible,
    hideToast: hideAnimateAllToast,
    onAnimateAll: onGenerateAnimationAll,
    showToast: showAnimateAllToast,
    navigate,
    animateAllCost: canAnimateSceneCount * ANIMATE_PRICE,
    t,
  });

  return (
    <div
      className={classNames(
        styles.container,
        scenes.length === 0 && styles.empty
      )}
    >
      <div
        className={classNames(
          styles['list-box'],
          scenes.length === 0 && isProcessing && styles.center
        )}
        ref={listRef}
      >
        {scenes.length === 0 && isProcessing && (
          <LoadingPrompt type="generating" />
        )}

        <div className={styles['animate-all']}>
          <div>{t('n scenes', {n: scenes.length})}</div>
          {scenes.length > 0 &&
          (scenes[0] instanceof BilingualDialogueScene ||
            scenes[0] instanceof GeneralStoryScene ||
            scenes[0] instanceof BilingualStoryScene ||
            scenes[0] instanceof BilingualDialogueScene) ? (
            <button
              className={classNames(styles.animate, {
                [styles.gray]:
                  disabledAnimateAll ||
                  animatingInfo[1] < animatingInfo[0] ||
                  plan === 'FREE' ||
                  !canAnimateSceneCount,
              })}
              disabled={
                disabledAnimateAll ||
                animatingInfo[1] < animatingInfo[0] ||
                !canAnimateSceneCount
              }
              onClick={onAnimateAll}
            >
              {animatingInfo[1] < animatingInfo[0] ? (
                <>
                  <LoadingSvg className={styles.rotateLoading} />
                  {t('Animating...', {
                    progress: `${animatingInfo[1]}/${animatingInfo[0]}`,
                  })}
                </>
              ) : (
                <>
                  {plan === 'FREE' && <LockIcon className={styles.icon} />}
                  {plan !== 'FREE' && <AnimateIcon className={styles.icon} />}
                  {t('Animate all')}
                  <span className="new-tag new-tag-ml4">{t('New')}</span>
                </>
              )}
            </button>
          ) : null}
        </div>

        <ul className={styles.list}>
          {scenes.map((scene, index) => {
            return (
              <SceneItem
                title={t('Scene n', {n: index + 1})}
                key={scene.id}
                scene={scene}
                sceneSubtitleMaxLength={sceneSubtitleMaxLength}
                maxParagraphsNumber={maxParagraphsNumber}
                nextScene={scenes[index + 1]}
                prevScene={scenes[index - 1]}
                //
                scenesNumber={scenes.length}
                ratio={ratio}
                isNew={newSceneIds.includes(scene.id)}
                isSelected={index === selectedSceneIndex}
                isProcessing={isProcessing}
                animateStatus={
                  sceneAnimateStatusInfo.get(scene.id) || AnimationType.none
                }
                validScenesNumber={validScenesNumber}
                onAction={_onAction}
                onSelected={() => changeSelectedSceneIndex(index)}
                getImageWithObjectKey={getImageWithObjectKey}
                refreshImage={refreshImage}
                onUpdateScene={onUpdateScene}
              />
            );
          })}
        </ul>
      </div>
      {actionInfo &&
        !['insert_after', 'insert_before'].includes(actionInfo.type) && (
          <SceneActionTipsDialog
            onCloseDialog={() => setActionInfo(null)}
            onCancel={() => setActionInfo(null)}
            dialogShowing={actionInfo !== null}
            type={
              actionInfo.type as Exclude<
                ActionType,
                'regenerate_scene_by_prompt'
              >
            }
            sceneId={actionInfo.sceneId}
            onSceneAction={onAction}
          />
        )}
      {actionInfo &&
        ['insert_after', 'insert_before'].includes(actionInfo.type) && (
          <BilingualDialogueSceneInsertActionTipsDialog
            onCloseDialog={() => setActionInfo(null)}
            onCancel={() => setActionInfo(null)}
            dialogShowing={actionInfo !== null}
            characterNames={characterNames || []}
            onSelect={speaker =>
              onAction(actionInfo.type, actionInfo.sceneId, {speaker})
            }
          />
        )}
      {toastProps && (
        <Toast
          title={toastProps.title}
          visible={toastProps.visible}
          confirmText={toastProps.confirmText}
          onOk={toastProps.onOk}
          onCancel={toastProps.onCancel}
          maskClosable={false}
          showCancel={toastProps.showCancel}
          cancelText={toastProps.cancelText}
        >
          {toastProps.content}
        </Toast>
      )}
    </div>
  );
}
function getToastProps({
  toastType,
  toastVisible,
  hideToast,
  onAnimateAll,
  showToast,
  navigate,
  animateAllCost,
  t,
}: {
  toastType: ErrorType | undefined;
  toastVisible: boolean;
  hideToast: () => void;
  onAnimateAll?: (
    shouldCheckWaitingTime: boolean,
    animateInfo: AnimateInfo
  ) => Promise<void>;
  showToast: (type: ErrorType) => void;
  navigate: NavigateFunction;
  animateAllCost: number;
  t: TFunction;
}) {
  if (!toastType) return undefined;
  let toastProps: ToastProps;

  switch (toastType) {
    case 'AnimateAll':
      toastProps = {
        title: (
          <>
            <AnimateIcon
              className={styles.icon}
              style={{fill: '#7D7D7D', marginRight: '10px'}}
            />
            {t('Animate all')}
            <span className="new-tag new-tag-ml4">{t('New')}</span>
          </>
        ),
        visible: toastVisible,
        confirmText: (
          <>
            {t('OK')}
            <span className={styles.credits}>
              <span className={styles.abandon}>
                <CoinIcon className={styles.icon} />
              </span>
              <span className={styles.cost}>{animateAllCost}</span>
            </span>
          </>
        ),
        onOk: async () => {
          hideToast();
          try {
            onAnimateAll &&
              (await onAnimateAll(true, {
                model: AnimateOptionTypeEnums.Base,
              }));
          } catch (err) {
            if (err instanceof Error && err.message === 'Overloaded') {
              setTimeout(() => {
                showToast('Overloaded');
              }, 0);
            }
          }
        },
        onCancel: hideToast,
        content: <AnimateSelect type="animateAll" />,
        cancelText: t('Cancel'),
      };
      break;
    case 'Upgrade':
      toastProps = {
        visible: toastVisible,
        title: t('Upgrade to unlock'),
        confirmText: t('Upgrade'),
        cancelText: t('I’m OK'),
        onOk: () => {
          navigate(PLAN_PAGE_PATH);
        },
        onCancel: hideToast,
        content: t('Upgrade your plan to enjoy premium features.'),
      };
      break;
    case 'Overloaded':
      toastProps = {
        title: (
          <>
            <AnimateIcon
              className={styles.icon}
              style={{fill: '#7D7D7D', marginRight: '10px'}}
            />
            {t("We're overloaded")}
          </>
        ),
        visible: toastVisible,
        confirmText: t('Submit later'),
        cancelText: t('Submit now'),
        onOk: () => {
          hideToast();
        },
        onCancel: () => {
          hideToast();
          onAnimateAll &&
            onAnimateAll(false, {model: AnimateOptionTypeEnums.Base});
        },
        content: t(
          'New request takes more than an hour to generate due to high demand, are you sure to submit now?'
        ),
      };
      break;
    default:
      toastProps = {
        visible: false,
      };
  }
  return toastProps;
}
