// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Effect} from 'api/server';
import {ProficiencyLevel, Size} from 'modules/project/types';
import {Avatar, ThumbnailType} from 'modules/project-config/types';

import {BasePreference} from './BasePreference';

export class BilingualStoryPreference extends BasePreference {
  static fromJSON(json: Record<string, any>): BilingualStoryPreference {
    return new BilingualStoryPreference(
      json.size,
      json.language,
      json.nativeLanguage,
      json.proficiencyLevel,
      json.style,
      json.paragraphAsShots,
      json.titleStyle,
      json.subtitleStyle,
      json.effect,
      json.transition,
      json.thumbnailType,
      json.thumbnailIncludeVideo,
      json.bgm,
      json.voiceover,
      json.voiceoverOrder,
      json.avatar
    );
  }
  constructor(
    size?: Size,
    language?: string,
    readonly nativeLanguage?: string,
    readonly proficiencyLevel?: ProficiencyLevel,
    readonly style?: string,
    readonly paragraphAsShots?: boolean,
    readonly titleStyle?: string,
    readonly subtitleStyle?: string,
    readonly effect?: Effect,
    readonly transition?: string,
    readonly thumbnailType?: ThumbnailType,
    readonly thumbnailIncludeVideo?: boolean,
    readonly bgm?: string,
    readonly voiceover?: string,
    readonly voiceoverOrder?: string,
    readonly avatar?: Avatar
  ) {
    super(size, language);
  }
  patch(data: Partial<BilingualStoryPreference>): BilingualStoryPreference {
    const {
      size,
      language,
      style,
      paragraphAsShots,
      titleStyle,
      subtitleStyle,
      voiceover,
      effect,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      nativeLanguage,
      proficiencyLevel,
      voiceoverOrder,
      avatar,
    } = Object.assign({}, this, data);
    return new BilingualStoryPreference(
      size,
      language,
      nativeLanguage,
      proficiencyLevel,
      style,
      paragraphAsShots,
      titleStyle,
      subtitleStyle,
      effect,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceover,
      voiceoverOrder,
      avatar
    );
  }
}
