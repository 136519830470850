// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as VideoIcon} from 'assets/svg/3.0/Video.svg';
import classNames from 'classnames';
import {HistoryPreviewerDialog} from 'components/HistoryPreviewer';
import {LoadingDialog} from 'components/Loading';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import {download} from 'lib/download';
import {noop} from 'lodash';
import {BilingualDialogueComposition} from 'modules/composition/models/BilingualDialogueComposition';
import {BilingualStoryComposition} from 'modules/composition/models/BilingualStoryComposition';
import {getProjectTypeByComposition} from 'modules/composition/utils';
import {useRawVideoPayment} from 'modules/payment/services/raw-video';
import {fromComposition} from 'modules/project-history/models/History';
import {Header} from 'pages/components/Header';
import {TaskPage} from 'pages/TaskPage';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {
  HISTORY_ID_QUERY_KEY,
  OUT_TRADE_NO_QUERY_KEY,
  PAYMENT_STATUS_QUERY_KEY,
  PaymentCallbackEnum,
  PaymentStatusEnum,
} from 'utils/stripe';

import styles from './CompositionPage.module.scss';
import {Props} from './CompositionPage.types';
import {ResultCard} from './ResultCard';

export function CompositionPage({
  composition,
  assetUrl,
  storyboardTasks,
  ratio,
  histories,
  projectId,
  refreshAssetUrl,
  onBack,
  onBackToInitPage,
  onHashTagsChange,
  onSummaryChange,
  updateHistories,
}: Props) {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {checkPaymentStatus} = useRawVideoPayment();
  const {showNotification} = useNotificationContext();
  const {recordEvent} = useAnalysis();

  const [isPaymentChecking, setIsPaymentChecking] = useState(false);
  const [downloadToastVisible, setDownloadToastVisible] = useState(false);

  const [historyDialogShowing, setHistoryDialogShowing] = useState(
    params.get(HISTORY_ID_QUERY_KEY) !== null
  );

  const isProcessing =
    (composition &&
      composition.task?.status &&
      !composition.task.isEndedStatus) ??
    true;

  const progressTask =
    composition && composition.task && !composition.task.isEndedStatus
      ? composition.task
      : undefined;

  const outTradeNo = params.get(OUT_TRADE_NO_QUERY_KEY) ?? undefined;
  const defaultSelectedHistoryId =
    params.get(HISTORY_ID_QUERY_KEY) ?? undefined;
  const paymentStatus = params.get(PAYMENT_STATUS_QUERY_KEY) ?? undefined;

  const downloadInfo = useMemo(
    () => ({
      title: composition.title,
      description: composition.description,
      hashtags: composition.hashtags,
      scenes: composition.scenes,
      promptPolicy: composition.promptPolicy,
      prompt: composition.prompt,
    }),
    [composition]
  );
  const loadedHistories = useMemo(() => {
    if (!composition.isValid()) return;
    const history = fromComposition(composition, storyboardTasks);
    return [history];
  }, [composition, storyboardTasks]);

  const onClearOutTradeNo = useCallback(() => {
    navigate(location.pathname, {replace: true});
  }, [location.pathname, navigate]);

  const onCloseDownloadToast = useCallback(() => {
    setDownloadToastVisible(false);
    onClearOutTradeNo();
  }, [onClearOutTradeNo]);

  const {t} = useTranslation('project');

  const onVideoPaymentCallback = useCallback(async () => {
    if (outTradeNo && !defaultSelectedHistoryId) {
      setIsPaymentChecking(true);
      try {
        if (paymentStatus === PaymentStatusEnum.Success) {
          await checkPaymentStatus(outTradeNo);
          showNotification({message: 'Pay success', type: 'SUCCESS'});
          await refreshAssetUrl();
        } else {
          throw Error('Pay failed');
        }
      } catch {
        showNotification({message: 'Pay failed', type: 'ERROR'});
        setDownloadToastVisible(true);
      }
      setIsPaymentChecking(false);
    }
  }, [
    outTradeNo,
    defaultSelectedHistoryId,
    paymentStatus,
    checkPaymentStatus,
    showNotification,
    refreshAssetUrl,
  ]);

  useEffect(() => {
    if (composition.assetProduct) {
      onVideoPaymentCallback();
    }
  }, [composition.assetProduct, onVideoPaymentCallback]);

  useEffect(() => {
    if (
      typeof assetUrl === 'string' &&
      outTradeNo &&
      !defaultSelectedHistoryId
    ) {
      download(assetUrl, composition.title);
      recordEvent('Download-Video');
      onClearOutTradeNo();
    }
  }, [
    assetUrl,
    composition.title,
    defaultSelectedHistoryId,
    onClearOutTradeNo,
    outTradeNo,
    recordEvent,
  ]);

  const [isScrollTop, setIsScrollTop] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const onScroll = () => {
      if (container) {
        setIsScrollTop(container.scrollTop === 0);
      }
    };
    container.addEventListener('scroll', onScroll);
    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <TaskPage
      isProcessing={isProcessing}
      current={
        (composition?.task?.progress &&
          Math.floor(composition.task.progress * 100)) ||
        0
      }
      title={t('Compositing the video...')}
      task={progressTask}
      remainingTime={progressTask?.estimatedRemainingTime ?? null}
      isScrollTop={isScrollTop}
    >
      <LoadingDialog
        dialogShowing={isPaymentChecking}
        onCloseDialog={noop}
        hasText={false}
        size={32}
      />
      <div
        className={styles.container}
        ref={containerRef}
        style={{background: isProcessing ? 'transparent' : '#f7f7f7'}}
      >
        {!isProcessing && (
          <Header
            languageCode={composition.language}
            title={composition.title}
            onBack={onBack}
            isScrollTop={isScrollTop}
            right={
              <div className={styles.right}>
                {histories.length > 0 && (
                  <button
                    className={classNames(styles.button, styles.history)}
                    onClick={() => setHistoryDialogShowing(true)}
                  >
                    <VideoIcon className={styles.icon} />
                    <span>{t('History')}</span>
                  </button>
                )}
                <button
                  className={classNames(styles.button, styles.generate)}
                  onClick={() => {
                    onBackToInitPage();
                  }}
                >
                  {t('Generate new')}
                </button>
              </div>
            }
            labelType={getProjectTypeByComposition(composition)}
          />
        )}
        <div className={styles['content-wrapper']}>
          <div className={styles.content}>
            <ResultCard
              projectId={projectId}
              outTradeNo={outTradeNo}
              languageCode={composition.language}
              nativeLanguageCode={
                composition instanceof BilingualStoryComposition ||
                composition instanceof BilingualDialogueComposition
                  ? composition.nativeLanguage
                  : undefined
              }
              projectType={getProjectTypeByComposition(composition)}
              title={composition.title}
              isDisabled={assetUrl === undefined || isProcessing}
              historyDialogShowing={historyDialogShowing}
              thumbnail={composition.thumbnail || ''}
              ratio={ratio}
              scenesNumber={composition.scenes.length || 0}
              size={composition.size}
              hashtags={composition.hashtags}
              summary={composition.description}
              video={assetUrl ?? composition.asset}
              showDownloadToast={
                !!(composition.assetProduct && assetUrl === null)
              }
              downloadToastVisible={downloadToastVisible}
              assetProduct={composition.assetProduct}
              refreshAssetUrl={refreshAssetUrl}
              onCloseDownloadToast={onCloseDownloadToast}
              onHashTagsChange={onHashTagsChange}
              onSummaryChange={onSummaryChange}
              showDownloadDialog={() => setDownloadToastVisible(true)}
              storyboard={downloadInfo}
            />
          </div>
        </div>
      </div>
      {loadedHistories && (
        <HistoryPreviewerDialog
          dialogShowing={historyDialogShowing}
          type={PaymentCallbackEnum.CompositePageHistory}
          histories={histories}
          projectId={projectId}
          projectType={getProjectTypeByComposition(composition)}
          onCloseDialog={() => setHistoryDialogShowing(false)}
          loadedHistories={loadedHistories}
          paymentStatus={paymentStatus as PaymentStatusEnum | undefined}
          outTradeNo={outTradeNo}
          onClearOutTradeNo={onClearOutTradeNo}
          defaultSelectedHistoryId={defaultSelectedHistoryId}
          onCurrentVideoPaymentSuccess={refreshAssetUrl}
          updateHistories={updateHistories}
        />
      )}
    </TaskPage>
  );
}
