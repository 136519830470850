// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useEffect, useMemo} from 'react';

import {SizePluginProps} from './types';

export function SizePlugin({setEditorSize}: SizePluginProps) {
  const [editor] = useLexicalComposerContext();

  const removeUpdateListener = useMemo(() => {
    const removeUpdateListener = editor.registerUpdateListener(() => {
      const rootEle = editor.getRootElement();
      if (!rootEle) return;
      const paragraphCount = calcDivLines(rootEle);
      setEditorSize(prevSize => {
        return paragraphCount > 3 ? 'large' : 'small';
      });
    });
    return removeUpdateListener;
  }, [editor, setEditorSize]);

  useEffect(() => removeUpdateListener, [removeUpdateListener]);

  return null;
}

function calcDivLines(ele: HTMLElement) {
  const div = document.createElement('div');
  div.id = ele.id;
  div.className = ele.className;
  div.innerHTML = ele.innerHTML;
  div.style.width = ele.offsetWidth + 'px';
  div.style.position = 'fixed';
  div.style.zIndex = '-1';
  div.style.visibility = 'hidden';
  div.style.height = 'auto';
  div.style.userSelect = 'text';
  div.style.whiteSpace = 'pre-wrap';
  div.style.wordBreak = 'break-word';
  document.body.appendChild(div);
  const lines = Math.round(div.offsetHeight / 24);
  document.body.removeChild(div);
  return lines;
}
