// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LoadingIcon} from 'assets/svg/outline/LoadingLine.svg';

import styles from './ImageLoading.module.scss';
import {Props} from './ImageLoading.types';

export function ImageLoading({size = 24}: Props) {
  return (
    <div className={styles.container}>
      <LoadingIcon
        className={styles.icon}
        style={{width: size, height: size}}
      />
    </div>
  );
}
