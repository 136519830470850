// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {getLanguage} from 'contexts/localStorage';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import ICU from 'i18next-icu';
import {initReactI18next} from 'react-i18next';

const SUPPORTED_LANGUAGES = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'zh-CN',
    name: '中文（简体）',
  },
  {
    code: 'ja',
    name: '日本語',
  },
];

i18n
  .use(ICU)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: SUPPORTED_LANGUAGES.map(({code}) => code),
    lng: getLanguage() ?? undefined,
    fallbackLng: 'en',
    debug: process.env.REACT_APP_ENV !== 'dev',
    ns: ['common'],
    defaultNS: 'common',
    load: 'currentOnly',
    backend: {
      loadPath: `/locales/${process.env.REACT_APP_VERSION}/{{lng}}/{{ns}}.json`,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export {i18n, SUPPORTED_LANGUAGES};
