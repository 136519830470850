// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {combine} from 'components/Combine';
import {useAPI} from 'contexts/APIContext';
import {formatAspectRatio} from 'lib/ratio';
import {retryIgnoreError} from 'lib/retry';
import {
  fromJSON as projectFromJSON,
  Project,
} from 'modules/project/models/Project';
import {ProjectJSON, ProjectType} from 'modules/project/types';
import {
  convertProjectVersion,
  MAX_HISTORY_QUANTITY,
  thumbnailCombiner,
} from 'modules/project/utils';
import {
  fromComposition,
  fromJSON as historyFromJSON,
  History,
} from 'modules/project-history/models/History';
import {HistoryJSON} from 'modules/project-history/types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {HistoryPreviewer} from './HistoryPreviewer';
import {HookParam, HookReturn} from './HistoryPreviewer.types';

function useHook({
  histories,
  projectId,
  projectType,
  loadedHistories,
  defaultSelectedHistoryId,
  updateHistories,
}: HookParam): HookReturn {
  const [loading, setLoading] = useState(false);
  const projectTypeRef = useRef<ProjectType | null>(null);
  const hasInitRef = useRef(false);
  const {aiFrontendClient, backendClient} = useAPI();
  const [error, setError] = useState<Error | null>(null);
  const [assetUrl, setAssetUrl] = useState<string | null>();
  //根据histories获取对应的History
  const [resultHistories, setResultHistories] = useState<
    History<ProjectType>[]
  >([]);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(0);
  const projectRef = useRef<Project<ProjectType>>();
  const [canDeleteHistory, setCanDeleteHistory] = useState(true);

  const currentHistory = useMemo(
    () => resultHistories[selectedHistoryIndex],
    [selectedHistoryIndex, resultHistories]
  );

  const getDefaultSelectedHistoryIndex = useCallback(
    (histories: History<ProjectType>[]) => {
      const index = histories.findIndex(
        history => history.id === defaultSelectedHistoryId
      );
      return index === -1 ? 0 : index;
    },
    [defaultSelectedHistoryId]
  );

  //已经翻转
  const fetchHistories = useCallback(
    async (projectId: string, histories: string[]) => {
      setLoading(true);
      setError(null);
      if (projectTypeRef.current === null) {
        return [];
      }
      try {
        return await backendClient.getHistoriesContent(
          projectId,
          histories,
          projectTypeRef.current
        );
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
      return [];
    },
    [backendClient]
  );
  const getHistoriesContentAndSet = useCallback(
    async (
      projectId: string,
      histories: string[],
      loadedHistories?: History<ProjectType>[]
    ) => {
      const diff = Math.max(
        histories.length +
          (loadedHistories?.length ?? 0) -
          MAX_HISTORY_QUANTITY,
        0
      );
      const newHistories = [
        ...(histories.length > diff
          ? await fetchHistories(projectId, histories.slice(diff))
          : []),
        ...(loadedHistories ?? []),
      ].reverse();
      setResultHistories(newHistories);
      setSelectedHistoryIndex(getDefaultSelectedHistoryIndex(newHistories));
    },
    [fetchHistories, getDefaultSelectedHistoryIndex]
  );
  const initProject = useCallback(
    async (projectId: string) => {
      setLoading(true);
      const data: ProjectJSON<ProjectType> = await backendClient.getProject(
        projectId,
        true
      );
      if (!data) {
        setLoading(false);
        return;
      }
      const project = convertProjectVersion(data) as unknown as Record<
        string,
        unknown
      >;
      if (
        !project.composition &&
        (!project.histories || (project.histories as unknown[]).length === 0)
      ) {
        setLoading(false);
        return;
      }
      if (
        project.histories &&
        (project.histories as unknown[]).some(
          (h: unknown) => typeof h !== 'string'
        )
      ) {
        const p = projectFromJSON({
          ...project,
          histories: [],
        } as unknown as ProjectJSON<ProjectType>);
        projectTypeRef.current = p.type;
        const histories = (project.histories as HistoryJSON<ProjectType>[]).map(
          json => historyFromJSON(p.type, json)
        );
        const newHistories = [
          ...histories,
          ...(p.composition && p.composition.isValid()
            ? [fromComposition(p.composition, p.storyboard?.tasks || [])]
            : []),
        ].reverse();
        setSelectedHistoryIndex(getDefaultSelectedHistoryIndex(newHistories));
        setResultHistories(newHistories);
        setLoading(false);
        setCanDeleteHistory(false);
      } else {
        const p = projectFromJSON(
          project as unknown as ProjectJSON<ProjectType>
        );
        projectRef.current = p;
        projectTypeRef.current = p.type;
        getHistoriesContentAndSet(
          p.id,
          p.histories ?? [],
          p.composition && p.composition.isValid()
            ? [fromComposition(p.composition, p.storyboard?.tasks || [])]
            : []
        ).finally(() => setLoading(false));
      }
    },
    [backendClient, getDefaultSelectedHistoryIndex, getHistoriesContentAndSet]
  );

  const getAssetUrl = useCallback(
    async (assetProduct: string) => {
      return await aiFrontendClient.getAssetUrl(assetProduct);
    },
    [aiFrontendClient]
  );

  const syncProject = useCallback(
    async (newHistories: string[]) => {
      if (!projectRef.current) return;
      const project = projectRef.current.patch({histories: newHistories});
      projectRef.current = project;
      await backendClient.updateProject({
        projectId: project.id,
        thumbnailUrl: project.thumbnail
          ? thumbnailCombiner(project.thumbnail)
          : undefined,
        frameRatio: formatAspectRatio(project.size),
        projectJsonContent: JSON.stringify(project),
        projectName: project.storyboard?.title,
        history: project.historyQuantity,
        lang: project.language,
      });
    },
    [backendClient]
  );

  const deleteHistoryById = useCallback(
    async (historyId: string) => {
      if (resultHistories.length <= 1) return;
      const newResultHistories = resultHistories.filter(
        h => h.id !== historyId
      );
      setResultHistories(newResultHistories);
      if (!newResultHistories[selectedHistoryIndex]) {
        setSelectedHistoryIndex(selectedHistoryIndex - 1);
      }
      try {
        if (updateHistories) {
          const loadedHistoryIds = loadedHistories?.map(h => h.id) || [];
          updateHistories(
            resultHistories
              .map(h => h.id)
              .filter(h => h !== historyId && !loadedHistoryIds.includes(h))
              .reverse()
          );
          return;
        }
        if (!projectRef.current) return;
        const newHistories = projectRef.current.histories?.filter(
          item => item !== historyId
        );
        await syncProject(newHistories ?? []);
      } catch {
        // delete failed
      }
    },
    [
      resultHistories,
      updateHistories,
      loadedHistories,
      selectedHistoryIndex,
      syncProject,
    ]
  );

  useEffect(() => {
    if (hasInitRef.current) return;
    hasInitRef.current = true;
    if (!histories && !loadedHistories) {
      initProject(projectId);
    } else if (histories) {
      setLoading(true);
      projectTypeRef.current = projectType!;
      getHistoriesContentAndSet(projectId, histories, loadedHistories).finally(
        () => {
          setLoading(false);
        }
      );
    }
  }, [
    getHistoriesContentAndSet,
    histories,
    initProject,
    loadedHistories,
    projectId,
    projectType,
  ]);

  useEffect(() => {
    if (!currentHistory) return;
    if (!currentHistory.assetProduct) {
      setAssetUrl(null);
      return;
    }
    let ignore = false;
    getAssetUrl(currentHistory.assetProduct)
      .then(url => {
        !ignore && setAssetUrl(url);
      })
      .catch(() => {
        // 无权限
        !ignore && setAssetUrl(null);
      });
    return () => {
      ignore = true;
      setAssetUrl(undefined);
    };
  }, [currentHistory, getAssetUrl]);

  const refreshAssetUrl = useCallback(async () => {
    if (!currentHistory?.assetProduct) return;
    try {
      const url = await retryIgnoreError(
        {times: 5, interval: 1000},
        getAssetUrl,
        currentHistory.assetProduct
      );
      setAssetUrl(url);
    } catch {
      setAssetUrl(null);
    }
  }, [currentHistory?.assetProduct, getAssetUrl]);

  return {
    resultHistories,
    loading,
    error,
    projectId,
    selectedHistoryIndex,
    currentHistory,
    setSelectedHistoryIndex,
    assetUrl,
    refreshAssetUrl,
    deleteHistoryById,
    canDeleteHistory,
  };
}

export const HistoryPreviewerContainer = combine(useHook, [
  'histories',
  'projectId',
  'projectType',
  'loadedHistories',
  'defaultSelectedHistoryId',
  'updateHistories',
])(HistoryPreviewer);
