// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useResourceManager} from 'contexts/ResourceManager';
import {useSize} from 'contexts/SizeContext';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {AssetLoader} from '../AssetLoader';
import {ImageLoading} from '../ImageLoading';
import {ObjectsEditor} from '../ObjectsEditor';
import {ImageWithCardDesign} from './ImageWithCardDesign';
import styles from './PreviewBox.module.scss';
import {Props} from './PreviewBox.types';

const MAX_HEIGHT = {
  xs: 280,
  sm: 360,
  md: 576,
  lg: 576,
};

export function PreviewBox({
  preview,
  ratio,
  loading,
  type = 'Image',
  onLoad,
  onObjectsEditorAction,
  objectsEditorDisabled,
  draft,
  updateObject,
  getImageWithObjectKey,
  refreshImage,
  forceLoading,
  outerEl,
  sceneId,
  avatar,
  cardDesign,
}: Props) {
  const {size} = useSize();
  const [width, height]: [number, number] = useMemo(
    () => ratio.split(':').map(Number) as [number, number],
    [ratio]
  );
  const [[previewWidth, previewHeight], setPreviewSize] = useState([0, 0]);
  const [isLoaded, setIsLoaded] = useState(false);
  const prevPreviewRef = useRef(preview);

  const root = useRef<HTMLDivElement>(null);
  const {getAvatarCharacters, resUrl, getHolidayCards} = useResourceManager();

  const resizePreview = useCallback(() => {
    const parent = root.current?.parentElement;
    if (!parent) return;
    const maxWidth = parent.clientWidth - 48;
    const minHeight = 160;
    const maxHeight = Math.max(
      Math.min(window.innerHeight - 320, MAX_HEIGHT[size]),
      minHeight
    );
    let _width = maxWidth;
    let _height = maxWidth / (width / height);
    if (_height < minHeight) {
      _height = minHeight;
      _width = _height * (width / height);
    } else if (_height > maxHeight) {
      _height = maxHeight;
      _width = _height * (width / height);
    }
    setPreviewSize([_width, _height]);
  }, [height, size, width]);

  useEffect(() => {
    resizePreview();
    window.addEventListener('resize', resizePreview);
    return () => {
      window.removeEventListener('resize', resizePreview);
    };
  }, [resizePreview]);

  useEffect(() => {
    // 防止useEffect的第二次执行会在onLoad事件之后执行，从而导致loaded始终为false
    if (prevPreviewRef.current === preview) return;

    if (!preview || (type === 'Image' && draft)) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
    prevPreviewRef.current = preview;
  }, [draft, preview, type]);

  const isEmpty = !preview;
  const avatarCharacter =
    avatar &&
    getAvatarCharacters().find(
      character => character.name === avatar?.character
    );
  return (
    <div
      className={styles['preview-box']}
      style={{
        width: previewWidth,
        height: previewHeight,
        background: isEmpty ? '#1F1F1F' : undefined,
      }}
      ref={root}
    >
      {avatar && avatar.layout && avatarCharacter && (
        <div
          className={classNames(
            styles['layout-preview-avatar'],
            avatar.layout === 'figure_left' && styles['figure-left'],
            avatar.layout === 'figure_right' && styles['figure-right'],
            avatar.layout === 'circle_left' && styles['circle-left'],
            avatar.layout === 'circle_right' && styles['circle-right']
          )}
          style={
            {
              '--imgBoxWidth':
                (getImgBoxWidthByLayout(avatar.layout, true, ratio) /
                  getHorizontalPixelByRatio(ratio)) *
                  100 +
                '%',
              '--imgWidth':
                (getImgBoxWidthByLayout(avatar.layout, false, ratio) /
                  getHorizontalPixelByRatio(ratio)) *
                  100 +
                '%',
              '--paddingWidth':
                (60 / getHorizontalPixelByRatio(ratio)) * 100 + '%',
            } as React.CSSProperties
          }
        >
          <img src={`${resUrl}${avatarCharacter.image}`} draggable={false} />
        </div>
      )}
      {((!isLoaded && !isEmpty) || (loading && isEmpty)) && (
        <ImageLoading size={30} />
      )}
      {type === 'Image' &&
        (draft && getImageWithObjectKey && refreshImage ? (
          <ObjectsEditor
            key={sceneId}
            className={styles.preview}
            onLoad={() => {
              setIsLoaded(true);
            }}
            onAction={onObjectsEditorAction}
            draft={draft}
            disabled={objectsEditorDisabled}
            outerHeight={previewHeight}
            outerWidth={previewWidth}
            updateObject={updateObject}
            getImageWithObjectKey={getImageWithObjectKey}
            refreshImage={refreshImage}
            forceLoading={forceLoading}
            outerEl={outerEl}
            background={preview}
          />
        ) : preview ? (
          <ImageWithCardDesign
            cardDesign={cardDesign}
            holidayCards={getHolidayCards()}
          >
            <AssetLoader
              type="image"
              className={styles.preview}
              src={preview}
              onLoadedMetadata={onLoad}
              onLoad={() => {
                setIsLoaded(true);
              }}
            />
          </ImageWithCardDesign>
        ) : null)}
      {type === 'Video' && preview && (
        <AssetLoader
          type="video"
          preload="auto"
          className={styles.preview}
          src={preview}
          controls={isLoaded}
          onLoadedMetadata={e => {
            onLoad && onLoad(e);
            setIsLoaded(true);
          }}
        />
      )}
    </div>
  );
}
function getImgBoxWidthByLayout(
  layout: string,
  isBox: boolean,
  ratio: '16:9' | '1:1' | '9:16'
) {
  const circleWidth = 480,
    circleLargeWidth = 600,
    squareWidth = 800,
    margin = 60;
  if (layout === 'figure_left' || layout === 'figure_right') {
    return squareWidth;
  }

  return (
    (ratio === '9:16' ? circleLargeWidth : circleWidth) +
    (isBox ? margin * 2 : 0)
  );
}
function getHorizontalPixelByRatio(ratio: '16:9' | '1:1' | '9:16') {
  if (ratio === '16:9') {
    return 1920;
  } else if (ratio === '9:16' || ratio === '1:1') {
    return 1080;
  }
  throw new Error('Invalid ratio');
}
