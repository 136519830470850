// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as AnimateIcon} from 'assets/svg/3.0/Animate.svg';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow.svg';
import classNames from 'classnames';
import {AnimateInfo} from 'components/AnimateSelect';
import {BackToTop} from 'components/BackToTop';
import {SceneEditor} from 'components/SceneEditor';
import {TextEditor} from 'components/TextEditor';
import {getNotShowDialogAgain} from 'contexts/localStorage';
import {noop} from 'lodash';
import {ProjectType} from 'modules/project/types';
import {BilingualDialogueScene} from 'modules/scene/models/BilingualDialogueScene';
import {BilingualStoryScene} from 'modules/scene/models/BilingualStoryScene';
import {BilingualDialogueStoryboard} from 'modules/storyboard/models/BilingualDialogueStoryboard';
import {BilingualStoryStoryboard} from 'modules/storyboard/models/BilingualStoryStoryboard';
import {getProjectTypeByStoryboard} from 'modules/storyboard/utils';
import {Task} from 'modules/task/models/Task';
import {Header} from 'pages/components/Header';
import {LoadingPromptDialog} from 'pages/components/LoadingPrompt';
import {Toast} from 'pages/components/Toast';
import {TaskPage} from 'pages/TaskPage';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {SceneList} from '../SceneList';
import {BackDialog} from './BackBox';
import styles from './StoryboardPage.module.scss';
import {Props} from './StoryboardPage.type';

const BACK_TO_TOP_HEIGHT = 266;

export function StoryboardPage({
  storyboard,
  constraint,
  selectedSceneIndex,
  loading,
  errorToastInfo,
  newSceneIds,
  projectId,
  ratio,
  languageCode,
  changeLoading,
  saveScene,
  generateStoryboardTask,
  hideToast,
  setSelectedSceneIndex,
  onBack,
  onNext,
  updateStoryboard,
  onAction,
  onGenerateAnimationOfCurrentScene,
  getImageWithObjectKey,
  refreshImage,
  updateScene,
  onGenerateAnimationAll,
  executeTaskInEditor,
}: Props<ProjectType>) {
  const [canScroll, setCanScroll] = useState(false);
  const [isTaskGenerating, setIsTaskGenerating] = useState(false);
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [isBackDialogOpen, setIsBackDialogOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation('project');
  const animateInfoRef = useRef<AnimateInfo | null>(null);

  const onResize = useCallback(() => {
    const container = containerRef.current;
    const box = boxRef.current;
    if (!container || !box) return;

    setCanScroll(prev => {
      if (prev) {
        return container.clientHeight - BACK_TO_TOP_HEIGHT > box.clientHeight;
      } else {
        return container.clientHeight > box.clientHeight;
      }
    });
  }, []);

  const onAnimateAll = useCallback(
    async (shouldCheckWaitingTime: boolean, animateInfo: AnimateInfo) => {
      setIsTaskGenerating(true);
      animateInfoRef.current = animateInfo;
      await onGenerateAnimationAll(shouldCheckWaitingTime, animateInfo);
      setIsTaskGenerating(false);
    },
    [onGenerateAnimationAll]
  );

  const onBackToTop = useCallback(() => {
    const box = boxRef.current;
    if (!box) return;
    box.scrollTop = 0;
  }, []);

  const onHeaderBack = useCallback(() => {
    const notShowDialogAgain = getNotShowDialogAgain();
    try {
      if (notShowDialogAgain && JSON.parse(notShowDialogAgain)) {
        onBack();
      } else {
        setIsBackDialogOpen(true);
      }
    } catch {
      setIsBackDialogOpen(true);
    }
  }, [onBack]);

  const isTitleOverLength = useMemo(
    () => storyboard?.title && storyboard?.title?.length > 100,
    [storyboard?.title]
  );

  const hasSceneOverLength = useMemo(() => {
    if (!storyboard) return false;
    if (!storyboard.scenes) return false;
    return (
      storyboard.scenes.filter(scene => {
        if (
          scene instanceof BilingualStoryScene ||
          scene instanceof BilingualDialogueScene
        ) {
          return (
            (scene.subtitle &&
              scene.subtitle.length > constraint.sceneSubtitleLength.max) ||
            (scene.nativeSubtitle &&
              scene.nativeSubtitle.length > constraint.sceneSubtitleLength.max)
          );
        }
        return (
          scene.subtitle &&
          scene.subtitle.length > constraint.sceneSubtitleLength.max
        );
      }).length > 0
    );
  }, [constraint.sceneSubtitleLength.max, storyboard]);
  useEffect(() => {
    const container = containerRef.current;
    const box = boxRef.current;
    if (!container || !box) return;
    const onScroll = () => {
      if (box) {
        setIsScrollTop(box.scrollTop === 0);
      }
    };

    const onResize = () => {
      const container = containerRef.current;
      const box = boxRef.current;
      if (!container || !box) return;
      setCanScroll(container.clientHeight > box.clientHeight);
    };
    onResize();

    box.addEventListener('scroll', onScroll);
    container.addEventListener('resize', onResize);
    return () => {
      container.removeEventListener('resize', onResize);
      box.removeEventListener('scroll', onScroll);
    };
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [onResize, storyboard.scenes?.length]);

  const isProcessing =
    (storyboard && storyboard.task?.status && !storyboard.task.isEndedStatus) ??
    true;
  //storyboard.tasks最后一个task的type
  const lastTask =
    (storyboard && storyboard.tasks?.[storyboard.tasks.length - 1]) ?? null;
  const progressTask =
    storyboard && storyboard.task && !storyboard.task.isEndedStatus
      ? storyboard.task
      : undefined;
  const animating =
    storyboard &&
    storyboard.tasks?.some(
      task => task.type === 'image_conditioning_video' && !task.isEndedStatus
    );
  const [animateIsDisabled, setAnimateIsDisabled] = useState(false);

  const disabledAnimateAll = useMemo(() => {
    return isProcessing || animating || animateIsDisabled;
  }, [animateIsDisabled, animating, isProcessing]);

  const [latestAnimatingTasks, finishedAnimateTasks] = useMemo(() => {
    const latestAnimatingTasks =
      storyboard?.tasks?.filter(
        task =>
          task.type === 'image_conditioning_video' &&
          storyboard.taskSession &&
          storyboard.taskSession === task.session
      ) || [];
    const finishedAnimateTasks =
      latestAnimatingTasks?.filter((task: Task) => task.isEndedStatus) || [];
    return [latestAnimatingTasks.length, finishedAnimateTasks.length];
  }, [storyboard?.taskSession, storyboard?.tasks]);

  function onTitleChange(title: string) {
    //延迟更新
    updateStoryboard(prev => {
      return prev.patch({title});
    }, true);
  }

  function onNativeTitleChange(nativeTitle: string) {
    updateStoryboard(prev => {
      if (
        prev instanceof BilingualStoryStoryboard ||
        prev instanceof BilingualDialogueStoryboard
      ) {
        return prev.patch({nativeTitle});
      }
      return prev;
    }, true);
  }

  function renderTitle() {
    return (
      <div className={styles.titleBox}>
        {storyboard &&
          ((storyboard.title && storyboard.title.length > 0 && isProcessing) ||
            !isProcessing) && (
            <TextEditor
              label={t('Title')}
              text={storyboard.title || ''}
              onChange={onTitleChange}
              isDisabled={isProcessing}
              maxLength={200}
              isOverLength={(storyboard.title || '').length > 100}
            />
          )}
        {(storyboard instanceof BilingualStoryStoryboard ||
          storyboard instanceof BilingualDialogueStoryboard) &&
          storyboard &&
          ((storyboard.nativeTitle &&
            storyboard.nativeTitle.length > 0 &&
            isProcessing) ||
            !isProcessing) && (
            <TextEditor
              text={storyboard.nativeTitle || ''}
              onChange={onNativeTitleChange}
              isDisabled={isProcessing}
              label={false}
              maxLength={200}
              isOverLength={(storyboard.nativeTitle || '').length > 100}
              rtlClassName="rtl-bilingual-element"
            />
          )}
      </div>
    );
  }

  const dialogShowing =
    (Boolean(loading) ||
      storyboard?.tasks?.some(
        task =>
          !task.isEndedStatus &&
          task.type !== 'generate_storyboard' &&
          task.type !== 'image_conditioning_video'
      )) ??
    false;

  return (
    <TaskPage
      isProcessing={isProcessing}
      current={
        (storyboard?.task?.progress &&
          Math.floor(storyboard.task.progress * 100)) ||
        0
      }
      task={progressTask}
      remainingTime={progressTask?.estimatedRemainingTime ?? null}
      title={t('Generating storyboard...')}
      isScrollTop={isScrollTop}
    >
      <LoadingPromptDialog
        dialogShowing={dialogShowing || isTaskGenerating}
        type={
          (lastTask &&
            (lastTask.type === 'regenerate_scene_by_prompt' ||
              lastTask.type === 'regenerate_scene_by_pose_prompt') &&
            !lastTask.isEndedStatus) ||
          loading === 'regenerate_scene_by_pose_prompt'
            ? 'generating'
            : 'processing'
        }
        onCloseDialog={noop}
      />
      <div className={styles.box} ref={boxRef}>
        {!isProcessing && (
          <div className={styles.header}>
            <Header
              languageCode={languageCode}
              title={(storyboard && storyboard.title) ?? null}
              right={
                <button
                  className={styles.next}
                  onClick={onNext}
                  disabled={
                    isTitleOverLength ||
                    hasSceneOverLength ||
                    animating ||
                    !storyboard?.scenes?.some(scene => scene.isValid())
                  }
                >
                  {t('Next')} <RightArrowIcon className={styles.icon} />
                </button>
              }
              background="rgba(248, 248, 248, 0.80)"
              isScrollTop={isScrollTop}
              onBack={onHeaderBack}
              labelType={getProjectTypeByStoryboard(storyboard)}
            />
          </div>
        )}
        <div className={styles.container} ref={containerRef}>
          <SceneEditor
            projectId={projectId}
            title={t('Scene n', {n: selectedSceneIndex + 1})}
            selectedSceneIndex={selectedSceneIndex}
            scene={storyboard!.scenes && storyboard!.scenes[selectedSceneIndex]}
            updateScene={updateScene}
            onAction={onAction}
            isProcessing={isProcessing}
            size={storyboard!.size}
            executeTaskInEditor={executeTaskInEditor}
            onGenerateAnimationOfCurrentScene={(
              shouldCheckWaitingTime: boolean,
              animateInfo: AnimateInfo
            ) => {
              animateInfoRef.current = animateInfo;
              onGenerateAnimationOfCurrentScene(
                shouldCheckWaitingTime,
                animateInfo
              );
            }}
            animating={animating ?? false}
            saveScene={saveScene}
            setAnimateIsDisabled={setAnimateIsDisabled}
            changeLoading={changeLoading}
          />
          <div
            className={classNames(styles['container-left'], {
              [styles.processing]: isProcessing,
              [styles['processing-no-scenes']]:
                isProcessing &&
                (!storyboard?.scenes || storyboard?.scenes.length === 0),
            })}
          >
            <div
              className={classNames(styles['container-left-box'], {
                [styles.processing]: isProcessing,
                [styles.scroll]: canScroll,
                [styles['processing-no-scenes']]:
                  isProcessing &&
                  (!storyboard?.scenes || storyboard?.scenes.length === 0),
              })}
            >
              {renderTitle()}
              {storyboard && (
                <SceneList
                  characterNames={storyboard.characters?.map(item => item.name)}
                  updateScene={updateScene}
                  scenes={storyboard.scenes || []}
                  sceneSubtitleMaxLength={constraint.sceneSubtitleLength.max}
                  maxParagraphsNumber={constraint.sceneQuantity.max}
                  isProcessing={isProcessing}
                  ratio={ratio}
                  animatingInfo={[latestAnimatingTasks, finishedAnimateTasks]}
                  selectedSceneIndex={selectedSceneIndex}
                  changeSelectedSceneIndex={setSelectedSceneIndex}
                  newSceneIds={newSceneIds}
                  onAction={onAction}
                  refreshImage={refreshImage}
                  disabledAnimateAll={disabledAnimateAll}
                  getImageWithObjectKey={getImageWithObjectKey}
                  onGenerateAnimationAll={onAnimateAll}
                />
              )}
              {canScroll && !isProcessing && (
                <div className={styles['back-to-top']}>
                  <BackToTop onBackToTop={onBackToTop} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <BackDialog
        dialogShowing={isBackDialogOpen}
        onCloseDialog={() => setIsBackDialogOpen(false)}
        onClose={() => setIsBackDialogOpen(false)}
        onBack={onBack}
      />
      <Toast
        title={t('Animate generation failed')}
        visible={errorToastInfo[0] && errorToastInfo[1][0] === 'sceneError'}
        onOk={hideToast}
        onCancel={hideToast}
        showCloseBtn={false}
        confirmText={t('OK')}
        cancelText={t('Cancel')}
      >
        {t('Scene n video generation failed, please try again.', {
          n: errorToastInfo[1][1] + '',
        })}
      </Toast>
      <Toast
        title={
          <>
            <AnimateIcon style={{fill: '#7D7D7D', marginRight: '10px'}} />
            {t("We're overloaded")}
          </>
        }
        visible={
          !!(
            errorToastInfo[0] &&
            errorToastInfo[1][0] &&
            [
              'overloaded',
              'animationAllOverloaded',
              'animationOverloaded',
            ].includes(errorToastInfo[1][0])
          )
        }
        confirmText={t('Submit later')}
        onOk={() => {
          if (errorToastInfo[1][0] === 'overloaded') {
            onBack();
          } else if (
            ['animationAllOverloaded', 'animationOverloaded'].includes(
              errorToastInfo[1][0] || ''
            )
          ) {
            hideToast();
          }
        }}
        cancelText={t('Submit now')}
        onCancel={() => {
          if (errorToastInfo[1][0] === 'overloaded') {
            generateStoryboardTask(storyboard, false);
          } else if (errorToastInfo[1][0] === 'animationAllOverloaded') {
            onAnimateAll(false, animateInfoRef.current!);
          } else if (errorToastInfo[1][0] === 'animationOverloaded') {
            onGenerateAnimationOfCurrentScene(false, animateInfoRef.current!);
          }
          hideToast();
        }}
        maskClosable={false}
      >
        {t(
          'New request takes more than an hour to generate due to high demand, are you sure to submit now?'
        )}
      </Toast>
      <Toast
        title={
          <>
            <AnimateIcon style={{fill: '#7D7D7D', marginRight: '10px'}} />
            {t('Have a rest')}
          </>
        }
        visible={
          !!(
            errorToastInfo[0] &&
            errorToastInfo[1][0] &&
            errorToastInfo[1][0] === 'pendingTaskLimit'
          )
        }
        confirmText={t('OK')}
        showCancel={false}
        onOk={() => hideToast()}
      >
        {t('You’ve submitted too many tasks shortly, please try again later.')}
      </Toast>
    </TaskPage>
  );
}
