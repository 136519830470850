// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {throttle} from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import {Props} from './Slider.types';
import {SliderGrid} from './SliderGrid';

export const Slider: React.FC<Props> = ({
  list,
  className,
  sliderGap,
  sliderItemWidth,
  scrollToIdx,
}) => {
  const initialRender = useRef(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [, setState] = useState({});
  const moveDistance = sliderItemWidth + sliderGap;

  const handleScroll = useCallback(
    (direction: 'left' | 'right' | number) => {
      if (scrollContainerRef.current) {
        const distance =
          typeof direction === 'number'
            ? direction
            : direction === 'left'
            ? moveDistance
            : 0 - moveDistance;
        const currentLeft = parseInt(
          scrollContainerRef.current.style.left || '0'
        );
        //计算最大左边距:元素总宽度-可视区域宽度
        const maxLeft = Math.max(
          scrollContainerRef.current.getBoundingClientRect().width -
            (moveDistance * list.length - sliderGap),
          currentLeft + distance
        );

        scrollContainerRef.current.style.left = `${Math.min(maxLeft, 0)}px`;
        setState({});
      }
    },
    [list.length, moveDistance, sliderGap]
  );

  useEffect(() => {
    if (initialRender.current && scrollToIdx !== undefined) {
      //如果value不为null，scroll到value的位置
      if (scrollToIdx < list.length && scrollToIdx >= 0) {
        handleScroll(-scrollToIdx * moveDistance);
      }
      initialRender.current = false;
    }
  }, [handleScroll, list.length, moveDistance, scrollToIdx]);

  //useEffect增加window resize监听,防抖
  useEffect(() => {
    const handleResize = throttle(() => {
      setState({});
    }, 200);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <SliderGrid
      list={list}
      scrollContainerRef={scrollContainerRef}
      onScroll={handleScroll}
      className={className}
      sliderGap={sliderGap}
      sliderItemWidth={sliderItemWidth}
    />
  );
};
