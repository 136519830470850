// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import * as apiServerAi from 'api/server-ai';
import {BodyShape, GenerateResponseParam, ShotAngle} from 'api/unity';
import axios from 'axios';
import {combine} from 'components/Combine';
import {ErrorType, useError} from 'contexts/ErrorContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {PosesData} from 'contexts/ResourceManager.type';
import {isTouchDevice} from 'lib/deviceInfo';
import {useVisible} from 'lib/hooks';
import {base64ToFile} from 'lib/image';
import {usePoll} from 'lib/poll';
import {useUnityService} from 'modules/unity/services';
import {SearchInputHandle} from 'pages/components/SearchInput/SearchInput.types';
import {useAwsFileUpload} from 'pages/WorkspacePage/modules';
import {useCallback, useEffect, useRef, useState} from 'react';

import {poseImageKeyMap} from './const';
import {Generator} from './Generator';
import {
  ErrorToastType,
  HookParam,
  HookReturn,
  PoseImageType,
  SceneSettingKeys,
  SettingInfoType,
  UsePoseSendRequestParam,
  UsePoseSendReturn,
} from './Generator.types';

function useHook({
  size,
  projectId,
  showLoading,
  hideLoading,
  onRegenerate,
  selectedSceneIndex,
  onRegenerateWithPosePrompt,
  onCloseDialog,
  onClose,
  changeLoading,
}: HookParam): HookReturn {
  const [posePrompt, setPosePrompt] = useState<string>('');
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [poseList, setPoseList] = useState<PosesData[]>([]);
  const [activePose, setActivePose] = useState<string | null>(null);
  const hasGeneratedPose = useRef<string | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);
  const isKeyDownrRef = useRef<boolean>(false);
  const [settingInfo, setSettingInfo] = useState<SettingInfoType>({
    angle: 'front' as ShotAngle,
    bodyShape: 'male' as BodyShape,
  });
  const [popoverVisibleMap, setPopoverVisibleMap] = useState({
    angle: false,
    bodyShape: false,
  });
  const posePromptRef = useRef<SearchInputHandle>(null);
  const isReadyRef = useRef<boolean>(false);

  const [errorDialogVisible, hideErrorDialog, showErrorDialog, errorToastType] =
    useVisible<ErrorToastType | undefined>(false);
  const {isResLoaded, getPosses, resUrl} = useResourceManager();

  const {
    callback: unityCallback,
    controller,
    isReady,
    cameraMovedCount,
  } = useUnityService();
  useEffect(() => {
    if (isReady) {
      isReadyRef.current = true;
      setTimeout(() => {
        controller.sendRequest('SetOutput', {
          width: size[0],
          height: size[1],
        });
        const isMobile = isTouchDevice();
        if (!isMobile) {
          controller.sendRequest('SetTips', {
            tips: 'Desktop',
          });
        }
      });
    }
  }, [isReady, controller, size]);
  useEffect(() => {
    if (cameraMovedCount > 0) {
      setSettingInfo(prev => {
        return {
          ...prev,
          angle: undefined,
        };
      });
    }
  }, [cameraMovedCount]);

  const {
    generatePoseImage,
    sendRequestByNameAndAssetPath,
    generatePoseJson,
    changeBodyShape,
    changeShotAngle,
  } = usePoseSendRequest({
    controller,
    projectId,
    showErrorDialog,
    isReady,
  });
  const {start, stop} = usePoll(2000);
  const {uploadFile} = useAwsFileUpload(
    async objectKeys => {
      //图片顺序： ['mask.png', 'depth.jpg', 'open_pose.jpg']
      //走通用添加addTask逻辑
      onClose();
      const targetPose = poseList.find(p => p.id === activePose);
      const pose_description =
        activePose && targetPose
          ? targetPose.description
          : hasGeneratedPose.current || '';
      onRegenerateWithPosePrompt(objectKeys, pose_description);
    },
    () => {
      stop();
      showErrorDialog('systemError');
      onCloseDialog && onCloseDialog();
    }
  );

  useEffect(() => {
    if (isResLoaded) {
      setPoseList(getPosses());
    }
  }, [isResLoaded, getPosses]);

  useEffect(() => {
    //绑定键盘事件，按下空格键发出SetInputKey事件
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.code === 'Space') {
        if (
          !(
            document.activeElement &&
            ['INPUT', 'TEXTAREA'].includes(document.activeElement.nodeName)
          )
        ) {
          //阻止默认事件，避免按空格滚动页面
          e.preventDefault();
        }
        if (
          isKeyDownrRef.current === true ||
          !isReady ||
          (document.activeElement &&
            ['INPUT', 'TEXTAREA'].includes(document.activeElement.nodeName))
        )
          return;
        isKeyDownrRef.current = true;
        controller.sendRequest('SetInputKey', {key: 'Space', isActive: true});
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.code === 'Space') {
        if (
          isKeyDownrRef.current === false ||
          !isReady ||
          (document.activeElement &&
            ['INPUT', 'TEXTAREA'].includes(document.activeElement.nodeName))
        )
          return;
        isKeyDownrRef.current = false;
        controller.sendRequest('SetInputKey', {key: 'Space', isActive: false});
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [controller, isReady]);
  const onChangeSwitch: HookReturn['onChangeSwitch'] = value => {
    setSwitchValue(value);
  };
  const onChangePosePrompt: HookReturn['onChangePosePrompt'] = e => {
    setPosePrompt(e.target.value);
  };

  const changeActivePose: HookReturn['changeActivePose'] = async e => {
    const dom = e.target as HTMLDivElement;
    const poseId = dom.dataset.id;
    if (!poseId) {
      return;
    }
    setActivePose(poseId);
    const pose = poseList.find(p => p.id === poseId);
    if (pose) {
      await sendRequestByNameAndAssetPath('SetPose', `${resUrl}${pose.asset}`);
      //清空已生成pose标记
      hasGeneratedPose.current = undefined;
    }
  };

  const onChangeSceneSetting: HookReturn['onChangeSceneSetting'] = (
    type,
    id
  ) => {
    setSettingInfo(prev => ({
      ...prev,
      [type]: id,
    }));
    updatePopoverVisibleMap(type, false);
    if (type === 'angle') {
      changeShotAngle((id.charAt(0).toUpperCase() + id.slice(1)) as ShotAngle);
    } else if (type === 'bodyShape') {
      changeBodyShape((id.charAt(0).toUpperCase() + id.slice(1)) as BodyShape);
    }
  };
  const onGeneratePoseJson = useCallback(async () => {
    if (!posePrompt) {
      return;
    }
    //页面锁定，等待生成结果
    showLoading && showLoading();
    //生成pose json
    await generatePoseJson(posePrompt)
      .then(() => {
        //成功生成PoseJson后,清空activePose
        setActivePose(null);
        //标记已生成pose
        hasGeneratedPose.current = posePrompt;
      })
      .finally(() => {
        //页面解锁
        hideLoading && hideLoading();
      });
  }, [generatePoseJson, hideLoading, posePrompt, showLoading]);
  const doRegenerate = useCallback(async () => {
    //根据pose生成image.页面锁定，等待生成结果
    changeLoading && changeLoading('regenerate_scene_by_pose_prompt'); //使用storyboard的loading
    //样式上关闭弹框
    const parentElement = containerRef.current?.parentElement;
    if (parentElement) {
      parentElement.style.opacity = '0';
      //mask也设置透明度为0
      const maskdom = parentElement.parentElement
        ?.previousElementSibling as HTMLDivElement;
      if (maskdom && maskdom.dataset.tag === 'popover-mask') {
        maskdom.style.opacity = '0';
      }
    }
    //等待unity准备好
    start(waitToBeReadyAndDoGenerate);
    async function waitToBeReadyAndDoGenerate() {
      if (isReadyRef.current) {
        stop();
      } else {
        return;
      }
      if (!controller.instance) {
        return;
      }
      //生成pose图片
      const imageInfoFromUnity = (await generatePoseImage()) as Record<
        keyof GenerateResponseParam,
        string
      >;
      if (imageInfoFromUnity) {
        const keys = Object.keys(imageInfoFromUnity) as Array<
          keyof GenerateResponseParam
        >;
        const contentType: Record<string, PoseImageType> = {};
        const res = keys.reduce((left: Record<string, File>, current) => {
          const base64Str = imageInfoFromUnity[current];
          const currentKey = poseImageKeyMap[current];
          if (currentKey === 'mask') {
            left[currentKey] = base64ToFile(
              base64Str,
              `${currentKey}.png`,
              'image/png'
            );
            contentType[currentKey] = 'image/png';
          } else {
            left[currentKey] = base64ToFile(
              base64Str,
              `${currentKey}.jpg`,
              'image/jpeg'
            );
            contentType[currentKey] = 'image/jpeg';
          }
          return left;
        }, {});
        uploadFile(res, 'cdn-st', 'pose_scene_ref', contentType);
      }
    }
  }, [
    changeLoading,
    start,
    controller.instance,
    generatePoseImage,
    stop,
    uploadFile,
  ]);
  //点击弹框确定按钮
  const handleRegenerate = useCallback(async () => {
    if (!switchValue) {
      //未开启pose control,走之前的逻辑
      onRegenerate && onRegenerate();
      return;
    }
    //若开启了pose control，未生成pose且未选择pose，则弹框提示
    if (switchValue && !activePose && hasGeneratedPose.current === undefined) {
      showErrorDialog('noPose');
      return;
    }
    doRegenerate();
  }, [activePose, doRegenerate, onRegenerate, showErrorDialog, switchValue]);
  const updatePopoverVisibleMap = useCallback(
    (type: SceneSettingKeys, visible: boolean) => {
      setPopoverVisibleMap(prev => ({
        ...prev,
        [type]: visible,
      }));
    },
    []
  );

  return {
    posePrompt,
    switchValue,
    onChangeSwitch,
    onChangePosePrompt,
    poseList,
    activePose,
    changeActivePose,
    resUrl,
    unityCallback,
    settingInfo,
    onChangeSceneSetting,
    onGeneratePoseJson,
    errorDialogVisible,
    hideErrorDialog,
    posePromptRef,
    handleRegenerate,
    selectedSceneIndex,
    errorToastType,
    updatePopoverVisibleMap,
    popoverVisibleMap,
    onRegenerate,
    containerRef,
    onClose,
  };
}
export const GeneratorContainer = combine(useHook, [
  'size',
  'projectId',
  'changeLoading',
  'showLoading',
  'hideLoading',
  'onRegenerate',
  'selectedSceneIndex',
  'onCloseDialog',
  'onRegenerateWithPosePrompt',
  'onClose',
])(Generator);

type ActionRefType = {
  changeShotAngle: Parameters<UsePoseSendReturn['changeShotAngle']> | null;
  changeBodyShape: Parameters<UsePoseSendReturn['changeBodyShape']> | null;
  sendRequestByNameAndAssetPath: Parameters<
    UsePoseSendReturn['sendRequestByNameAndAssetPath']
  > | null;
};
function usePoseSendRequest({
  controller,
  projectId,
  showErrorDialog,
  isReady,
}: UsePoseSendRequestParam): UsePoseSendReturn {
  const [poseInfoByPromt, setPoseInfoByPrompt] =
    useState<GenerateResponseParam | null>(null);
  const {start, stop} = usePoll(1500);
  const {report} = useError();
  const actionRef = useRef<ActionRefType>({
    changeShotAngle: null,
    changeBodyShape: null,
    sendRequestByNameAndAssetPath: null,
  });

  const sendRequestByNameAndAssetPath: UsePoseSendReturn['sendRequestByNameAndAssetPath'] =
    useCallback(
      (...rest) => {
        if (!controller.instance) {
          actionRef.current &&
            (actionRef.current['sendRequestByNameAndAssetPath'] = rest);
          return;
        }
        const [name, url] = rest;
        return axios.get(url).then(res => {
          setPoseInfoByPrompt(res.data);
          return controller.sendRequest(name, {
            pose: res.data,
          });
        });
      },
      [controller]
    );

  //生成pose图片
  const generatePoseImage = useCallback(async () => {
    const res = await controller.sendRequest('Generate', {});
    return res;
  }, [controller]);

  const generatePoseJson = useCallback(
    async (posePrompt: string) => {
      const taskId = await apiServerAi
        .commitPoseTask(posePrompt, projectId)
        .catch(err => {
          if (err.status === 402) {
            // showErrorDialog('no_credit');
            report(ErrorType.NoCredit, err);
          }
          return '';
        });
      if (!taskId) {
        return;
      }
      const startTime = new Date().getTime();
      const maxAttemptTimes = 1000 * 60 * 5; // 5分钟
      const showError = (reject: (reason?: Error) => void) => {
        stop();
        showErrorDialog('systemError');
        reject(new Error('Task failed'));
      };
      return new Promise<void>((resolve, reject) => {
        const attempt = async () => {
          if (new Date().getTime() - startTime > maxAttemptTimes) {
            showError(reject);
          }

          try {
            const data: apiServerAi.getPoseJsonByTaskIdResponse =
              await apiServerAi.getPoseJsonByTaskId(taskId);
            if (data.status === 'success' && data.asset) {
              stop();
              await sendRequestByNameAndAssetPath(
                'SetPose',
                staticCombiner(data.asset)
              );
              resolve();
            } else if (
              data.status === 'failure' ||
              (data.status === 'success' && !data.asset)
            ) {
              showError(reject);
            }
          } catch (error) {
            showError(reject);
          }
        };
        start(attempt);
      });
    },
    [
      projectId,
      sendRequestByNameAndAssetPath,
      showErrorDialog,
      start,
      stop,
      report,
    ]
  );

  const changeShotAngle: UsePoseSendReturn['changeShotAngle'] = useCallback(
    (...rest) => {
      if (!controller.instance) {
        actionRef.current && (actionRef.current['changeShotAngle'] = rest);
        return;
      }
      const [shotAngle] = rest;
      controller.sendRequest('SetShotAngle', {
        shotAngle,
      });
    },
    [controller]
  );
  const changeBodyShape: UsePoseSendReturn['changeBodyShape'] = useCallback(
    (...rest) => {
      if (!controller.instance) {
        actionRef.current && (actionRef.current['changeBodyShape'] = rest);
        return;
      }
      const [bodyShape] = rest;
      controller.sendRequest('SetBodyShape', {
        bodyShape,
      });
    },
    [controller]
  );
  const clearPoseInfo = useCallback(() => {
    setPoseInfoByPrompt(null);
  }, []);
  //当controller初始化后，执行之前未执行的操作
  useEffect(() => {
    if (controller.instance && isReady) {
      if (actionRef.current['sendRequestByNameAndAssetPath']) {
        try {
          sendRequestByNameAndAssetPath(
            ...(actionRef.current[
              'sendRequestByNameAndAssetPath'
            ] as Parameters<UsePoseSendReturn['sendRequestByNameAndAssetPath']>)
          );
        } finally {
          actionRef.current['sendRequestByNameAndAssetPath'] = null;
        }
      }
      if (actionRef.current['changeShotAngle']) {
        try {
          changeShotAngle(...actionRef.current['changeShotAngle']);
        } finally {
          actionRef.current['changeShotAngle'] = null;
        }
      }
      if (actionRef.current['changeBodyShape']) {
        try {
          changeBodyShape(...actionRef.current['changeBodyShape']);
        } finally {
          actionRef.current['changeBodyShape'] = null;
        }
      }
    }
  }, [
    changeBodyShape,
    changeShotAngle,
    controller.instance,
    controller.isReady,
    isReady,
    sendRequestByNameAndAssetPath,
  ]);
  return {
    sendRequestByNameAndAssetPath,
    generatePoseImage,
    generatePoseJson,
    changeShotAngle,
    changeBodyShape,
    poseInfoByPromt,
    clearPoseInfo,
  };
}
