// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Option} from 'components/Option';
import {Select} from 'pages/components/Select';
import {SelectCard} from 'pages/components/SelectCard';
import {useMemo, useState} from 'react';

import styles from './BGMSizer.module.scss';
import {Props} from './BGMSizer.types';

export function BGMSizer({
  selectedTag,
  defaultLabel,
  tags,
  onChange,
  iconFromTag,
  displayNameFromTag,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const SelectedTagIcon = useMemo(() => {
    return selectedTag && iconFromTag && iconFromTag(selectedTag);
  }, [iconFromTag, selectedTag]);

  return (
    <div className={styles.container}>
      <Select
        label=""
        value={
          <div className={styles['selected-container']}>
            {SelectedTagIcon && <SelectedTagIcon className={styles.icon} />}
            <span>
              {selectedTag === null
                ? defaultLabel
                : displayNameFromTag(selectedTag)}
            </span>
          </div>
        }
        isOpen={isOpen}
        onVisibleChange={setIsOpen}
        popoverClassName={styles['popover-container']}
      >
        <SelectCard label="">
          <ul className={styles.list}>
            <li className={styles.item}>
              <Option
                label={defaultLabel}
                isDisabled={false}
                isChecked={selectedTag === null}
                onCheck={() => {
                  onChange(null);
                  setIsOpen(false);
                }}
              />
            </li>
            {tags.map(tag => {
              const Icon = iconFromTag && iconFromTag(tag);

              return (
                <li key={tag} className={styles.item}>
                  <Option
                    label={
                      <>
                        {Icon && <Icon className={styles.icon} />}
                        <span className={styles.label}>
                          {displayNameFromTag(tag)}
                        </span>
                      </>
                    }
                    isDisabled={false}
                    isChecked={tag === selectedTag}
                    onCheck={() => {
                      onChange(tag);
                      setIsOpen(false);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </SelectCard>
      </Select>
    </div>
  );
}
