// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as BackgroundIcon} from 'assets/svg/3.0/Background.svg';
import {ReactComponent as BGMIcon} from 'assets/svg/3.0/bgm.svg';
import {ReactComponent as CardDesignIcon} from 'assets/svg/3.0/CardDesign.svg';
import {ReactComponent as SoundIcon} from 'assets/svg/3.0/Sound.svg';
import {ReactComponent as StarIcon} from 'assets/svg/3.0/Star.svg';
import {ReactComponent as SubtitleIcon} from 'assets/svg/3.0/Subtitle.svg';
import {ReactComponent as TextIcon} from 'assets/svg/3.0/Text.svg';
import {ReactComponent as TransitionIcon} from 'assets/svg/3.0/Transition.svg';
import {ReactComponent as AvatarLayoutMenuIcon} from 'assets/svg/outline/AvatarLayoutMenu.svg';
import classNames from 'classnames';
import {BackToTop} from 'components/BackToTop';
import {ScrollSpy} from 'components/ScrollSpy';
import {useResourceManager} from 'contexts/ResourceManager';
import {useSize} from 'contexts/SizeContext';
import {BilingualDialogueProjectConfig} from 'modules/project-config/models/BilingualDialogueProjectConfig';
import {BilingualStoryProjectConfig} from 'modules/project-config/models/BilingualStoryProjectConfig';
import {GeneralStoryProjectConfig} from 'modules/project-config/models/GeneralStoryProjectConfig';
import {HolidayGreetingProjectConfig} from 'modules/project-config/models/HolidayGreetingProjectConfig';
import {ShortVideoProjectConfig} from 'modules/project-config/models/ShortVideoProjectConfig';
import {
  isWithAvatarByProjectConfig,
  isWithEffectByProjectConfig,
  isWithOverlayEffectByProjectConfig,
  isWithSubtitleStyleByProjectConfig,
  isWithThumbnailByProjectConfig,
  isWithTitleStyleByProjectConfig,
  isWithTransitionByProjectConfig,
} from 'modules/project-config/utils';
import {useCallback, useEffect, useRef, useState} from 'react';

import {AvatarLayout} from '../AvatarLayout';
import {BGMList} from '../BGMList';
import {CardDesign} from '../CardDesign';
import {SceneEffectList} from '../SceneEffectList';
import {SceneOverlayEffectList} from '../SceneOverlayEffectList';
import {SceneTransitionList} from '../SceneTransitionList';
import {TextStyleGroupList} from '../TextStyleGroupList';
import {TextStyleList} from '../TextStyleList';
import {ThumbnailList} from '../ThumbnailList';
import {VoiceoverCollapse} from '../VoiceoverCollapse';
import styles from './ConfigBox.module.scss';
import {Props} from './ConfigBox.types';

export function ConfigBox({
  avatarData,
  config,
  bgmList,
  characters,
  titleStyleList,
  subtitleStyleList,
  voiceoverList,
  thumbnailTypes,
  transitions,
  overlayEffects,
  scrollContainer,
  titleStyleCombination,
  subtitleStyleCombination,
  updateConfigAndPreference,
  ratio,
}: Props) {
  const {resUrl} = useResourceManager();
  const {size} = useSize();
  const isMobile = size === 'xs' || size === 'sm';

  const onPress = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.dataset['toScrollspyId'] as string
    );

    if (target) {
      //触发点击事件
      const collapseHeader: HTMLDivElement | null =
        target.querySelector('.click-area');
      //判断collapseHeader是否有“open”这个class
      if (collapseHeader && !collapseHeader.classList.contains('open')) {
        collapseHeader.click();
      }
      const headerOffset = isMobile ? 140 : 104;
      const elementPosition = target.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;

      scrollContainer?.current?.scrollBy({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const onScrollToTop = () => {
    scrollContainer?.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const lastSpy = useRef<HTMLDivElement>(null);
  const [lastSpyHeight, setLastSpyHeight] = useState(0);
  const [bottomOffset, setBottomOffset] = useState(0);

  const timer = useRef<number | null>(null);

  const baseVoiceoverProps = {
    voiceovers: voiceoverList,
    voiceover:
      config instanceof GeneralStoryProjectConfig ||
      config instanceof BilingualStoryProjectConfig ||
      config instanceof ShortVideoProjectConfig ||
      config instanceof HolidayGreetingProjectConfig
        ? config.voiceover
        : null,
    onChange: (value: string | null) => {
      if (
        config instanceof GeneralStoryProjectConfig ||
        config instanceof BilingualStoryProjectConfig ||
        config instanceof ShortVideoProjectConfig ||
        config instanceof HolidayGreetingProjectConfig
      ) {
        updateConfigAndPreference<
          | GeneralStoryProjectConfig
          | BilingualStoryProjectConfig
          | ShortVideoProjectConfig
          | HolidayGreetingProjectConfig,
          'voiceover'
        >('voiceover', value);
      }
    },
  };

  const delaySetLastSpyHeight = useCallback((height: number) => {
    if (timer.current !== null) cancelAnimationFrame(timer.current);
    timer.current = requestAnimationFrame(() => {
      timer.current = null;
      setLastSpyHeight(height);
    });
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        delaySetLastSpyHeight(entry.target.clientHeight);
      });
    });
    if (!lastSpy.current) return;
    setLastSpyHeight(lastSpy.current.scrollHeight);
    observer.observe(lastSpy.current);
    return () => {
      observer.disconnect();
    };
  }, [delaySetLastSpyHeight]);

  useEffect(() => {
    const resizeHandler = () =>
      setBottomOffset(Math.max(window.innerHeight - lastSpyHeight - 225, 0));

    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [lastSpyHeight, size]);

  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (!scrollContainer?.current) return;
    const scroll = scrollContainer?.current;
    const scrollHandler = () => {
      if (!stickyRef.current) return;
      if (stickyRef.current.getBoundingClientRect().top <= 80) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    scroll.addEventListener('scroll', scrollHandler);
    window.addEventListener('resize', scrollHandler);
    return () => {
      scroll.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', scrollHandler);
    };
  }, [scrollContainer]);

  const spyList = [
    ...(config instanceof HolidayGreetingProjectConfig
      ? [{icon: CardDesignIcon, id: 'CardDesignList'}]
      : []),
    ...(isWithTitleStyleByProjectConfig(config)
      ? [{icon: TextIcon, id: 'TitleStyleList'}]
      : []),
    ...(isWithSubtitleStyleByProjectConfig(config) &&
    !(config instanceof HolidayGreetingProjectConfig)
      ? [{icon: SubtitleIcon, id: 'SubtitleStyleList'}]
      : []),
    ...(isWithThumbnailByProjectConfig(config)
      ? [{icon: BackgroundIcon, id: 'ThumbnailList'}]
      : []),
    ...(isWithEffectByProjectConfig(config) ||
    isWithOverlayEffectByProjectConfig(config)
      ? [{icon: StarIcon, id: 'SceneEffectList'}]
      : []),
    ...(isWithTransitionByProjectConfig(config)
      ? [{icon: TransitionIcon, id: 'SceneTransitionList'}]
      : []),
    {icon: SoundIcon, id: 'VoiceoverList'},
    ...(isWithAvatarByProjectConfig(config)
      ? [{icon: AvatarLayoutMenuIcon, id: 'AvatarLayout'}]
      : []),
    {icon: BGMIcon, id: 'BGMList'},
  ];

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.scrollSpyController, styles.fixedSpy, {
          [styles.show]: isSticky,
        })}
      >
        {spyList.map(({icon: Icon, id}) => (
          <div
            key={id}
            className="icon-container"
            data-to-scrollspy-id={id}
            onClick={onPress}
          >
            <Icon />
          </div>
        ))}
      </div>
      <div className={styles.scrollSpyController} ref={stickyRef}>
        {spyList.map(({icon: Icon, id}) => (
          <div
            key={id}
            className="icon-container"
            data-to-scrollspy-id={id}
            onClick={onPress}
          >
            <Icon />
          </div>
        ))}
      </div>
      <div className={styles.scroll}>
        <ScrollSpy
          parentScrollContainerRef={scrollContainer}
          offsetTop={isMobile ? 144 : 104}
        >
          {config instanceof HolidayGreetingProjectConfig && (
            <div id="CardDesignList" className={styles['card']}>
              <CardDesign
                ratio={ratio}
                cardDesign={config.cardDesign}
                onChange={value =>
                  updateConfigAndPreference<
                    HolidayGreetingProjectConfig,
                    'cardDesign'
                  >('cardDesign', value)
                }
              />
            </div>
          )}
          {(config instanceof GeneralStoryProjectConfig ||
            config instanceof ShortVideoProjectConfig) && (
            <>
              <div id="TitleStyleList" className={styles['card']}>
                <TextStyleList
                  type="title"
                  data={titleStyleList}
                  selectedStyleName={config.titleStyle}
                  onSelected={name =>
                    updateConfigAndPreference<
                      GeneralStoryProjectConfig | ShortVideoProjectConfig,
                      'titleStyle'
                    >('titleStyle', name)
                  }
                />
              </div>
              <div id="SubtitleStyleList" className={styles['card']}>
                <TextStyleList
                  type="subtitle"
                  data={subtitleStyleList}
                  selectedStyleName={config.subtitleStyle}
                  onSelected={name =>
                    updateConfigAndPreference<
                      GeneralStoryProjectConfig | ShortVideoProjectConfig,
                      'subtitleStyle'
                    >('subtitleStyle', name)
                  }
                />
              </div>
            </>
          )}

          {(config instanceof BilingualStoryProjectConfig ||
            config instanceof BilingualDialogueProjectConfig) && (
            <>
              <div id="TitleStyleList" className={styles['card']}>
                <TextStyleGroupList
                  type="title"
                  selectedTextStyle={config.titleStyle}
                  textStyleCombination={titleStyleCombination}
                  onChange={name =>
                    updateConfigAndPreference<
                      | BilingualStoryProjectConfig
                      | BilingualDialogueProjectConfig,
                      'titleStyle'
                    >('titleStyle', name)
                  }
                />
              </div>
              <div id="SubtitleStyleList" className={styles['card']}>
                <TextStyleGroupList
                  type="subtitle"
                  selectedTextStyle={config.subtitleStyle}
                  textStyleCombination={subtitleStyleCombination}
                  onChange={name =>
                    updateConfigAndPreference<
                      | BilingualStoryProjectConfig
                      | BilingualDialogueProjectConfig,
                      'subtitleStyle'
                    >('subtitleStyle', name)
                  }
                />
              </div>
            </>
          )}
          {isWithThumbnailByProjectConfig(config) && (
            <div id="ThumbnailList" className={styles['card']}>
              <ThumbnailList
                thumbnailTypes={thumbnailTypes}
                thumbnailType={config.thumbnailType}
                thumbnailIncludeVideo={config.thumbnailIncludeVideo}
                onThumbnailIncludeVideoChange={value => {
                  updateConfigAndPreference<
                    | GeneralStoryProjectConfig
                    | BilingualStoryProjectConfig
                    | BilingualDialogueProjectConfig,
                    'thumbnailIncludeVideo'
                  >('thumbnailIncludeVideo', value);
                }}
                onChange={value =>
                  updateConfigAndPreference<
                    | GeneralStoryProjectConfig
                    | BilingualStoryProjectConfig
                    | BilingualDialogueProjectConfig,
                    'thumbnailType'
                  >('thumbnailType', value)
                }
              />
            </div>
          )}
          {isWithEffectByProjectConfig(config) && (
            <div id="SceneEffectList" className={styles['card']}>
              <SceneEffectList
                effect={config.effect}
                onChange={value =>
                  updateConfigAndPreference<
                    | BilingualStoryProjectConfig
                    | BilingualDialogueProjectConfig
                    | GeneralStoryProjectConfig
                    | ShortVideoProjectConfig,
                    'effect'
                  >('effect', value)
                }
              />
            </div>
          )}

          {isWithOverlayEffectByProjectConfig(config) && (
            <div id="SceneEffectList" className={styles['card']}>
              <SceneOverlayEffectList
                effect={config.overlayEffect}
                overlayEffects={overlayEffects}
                onChange={value =>
                  updateConfigAndPreference<
                    HolidayGreetingProjectConfig,
                    'overlayEffect'
                  >('overlayEffect', value)
                }
              />
            </div>
          )}

          {isWithTransitionByProjectConfig(config) && (
            <div id="SceneTransitionList" className={styles['card']}>
              <SceneTransitionList
                resUrl={resUrl}
                transition={config.transition}
                transitions={transitions}
                onChange={value =>
                  updateConfigAndPreference<
                    | BilingualStoryProjectConfig
                    | BilingualDialogueProjectConfig
                    | GeneralStoryProjectConfig
                    | ShortVideoProjectConfig,
                    'transition'
                  >('transition', value)
                }
              />
            </div>
          )}

          <div ref={lastSpy} id="VoiceoverList" className={styles['card']}>
            {(config instanceof GeneralStoryProjectConfig ||
              config instanceof ShortVideoProjectConfig ||
              config instanceof HolidayGreetingProjectConfig) && (
              <VoiceoverCollapse
                {...baseVoiceoverProps}
                voiceoverSpeed={config.voiceoverSpeed === 'fast'}
                onSpeedChange={value =>
                  updateConfigAndPreference<
                    | GeneralStoryProjectConfig
                    | ShortVideoProjectConfig
                    | HolidayGreetingProjectConfig,
                    'voiceoverSpeed'
                  >('voiceoverSpeed', value ? 'fast' : 'normal')
                }
              />
            )}
            {config instanceof BilingualStoryProjectConfig && (
              <VoiceoverCollapse
                {...baseVoiceoverProps}
                voiceoverOrder={config.voiceoverOrder}
                onOrderChange={value =>
                  updateConfigAndPreference<
                    BilingualStoryProjectConfig,
                    'voiceoverOrder'
                  >('voiceoverOrder', value)
                }
              />
            )}
            {config instanceof BilingualDialogueProjectConfig && (
              <VoiceoverCollapse
                voiceovers={voiceoverList}
                voiceoverOrder={config.voiceoverOrder}
                onOrderChange={value =>
                  updateConfigAndPreference<
                    BilingualDialogueProjectConfig,
                    'voiceoverOrder'
                  >('voiceoverOrder', value)
                }
                characters={characters || []}
                characterVoiceover={config.voiceovers}
                onCharacterVoiceoverChange={value =>
                  updateConfigAndPreference<
                    BilingualDialogueProjectConfig,
                    'voiceovers'
                  >('voiceovers', value)
                }
              />
            )}
          </div>
          {isWithAvatarByProjectConfig(config) && (
            <div ref={lastSpy} id="AvatarLayout" className={styles['card']}>
              <AvatarLayout
                avatar={config.avatar}
                onChange={value =>
                  updateConfigAndPreference<
                    | BilingualStoryProjectConfig
                    | ShortVideoProjectConfig
                    | GeneralStoryProjectConfig,
                    'avatar'
                  >('avatar', value)
                }
                avatarData={avatarData}
              />
            </div>
          )}
          <div ref={lastSpy} id="BGMList" className={styles['card']}>
            <BGMList
              bgms={bgmList}
              bgm={config.bgm}
              onChange={value => updateConfigAndPreference('bgm', value)}
            />
          </div>
        </ScrollSpy>
        <BackToTop onBackToTop={onScrollToTop} />
        <div style={{height: bottomOffset}}></div>
      </div>
    </div>
  );
}
