// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import sanitize from 'sanitize-filename';

const DEFAULT_FILENAME = 'mootion';
const MAX_FILENAME_LENGTH = 255;

export function cleanFilename(fileName: string): string {
  const cleanedFilename = sanitize(fileName) || DEFAULT_FILENAME;
  return cleanedFilename.slice(0, MAX_FILENAME_LENGTH);
}

export async function download(assetUrl: string, fileName: string) {
  // 处理非法字符，如果为空或替换完为空，则替换为默认文件名
  let suffix = assetUrl.split('.')[1];
  const url = `${process.env.REACT_APP_DOWNLOAD_URL}/${assetUrl}`;
  if (!suffix) {
    const {headers} = await axios.head(url);
    const contentType = headers['content-type'];
    if (!contentType) {
      throw new Error('No content type in response header');
    }
    suffix = contentType.split('/')[1];
  }
  downloadFile(url, fileName, suffix);
}

export function downloadFile(url: string, fileName: string, suffix: string) {
  const cleanedFilename = cleanFilename(fileName);
  const link = document.createElement('a');
  link.target = '_blank';
  // 做encodeURIComponent处理，防止# / \ ?等字符导致query被截断
  link.href = `${url}?file-name=${encodeURIComponent(
    cleanedFilename
  )}.${suffix}`;

  document.body.appendChild(link);
  // 使用异步的方法打开新窗口下载文件，避免被Safari等浏览器拦截
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  });
}
