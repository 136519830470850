// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Holiday} from 'modules/project/types';
import {HOLIDAY_OPTIONS} from 'modules/project/utils';
import {useTranslation} from 'react-i18next';

import {CommonSelectWithoutLabel} from './CommonSelectWithoutLabel/CommonSelectWithoutLabel';

export interface Props {
  value: Holiday;
  onChange: (value: Holiday) => void;
}
export function HolidaySelect({value, onChange}: Props) {
  const {t} = useTranslation('project');
  const options = HOLIDAY_OPTIONS.map(option => ({
    ...option,
    label: t(option.label),
  }));
  return (
    <CommonSelectWithoutLabel
      options={options}
      value={value}
      onChange={value => onChange(value as Holiday)}
      customizeValue="Other"
      placeholderOfOtherInput={t('Type in the holiday here')}
    />
  );
}
