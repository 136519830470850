// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CloseIcon} from 'assets/svg/3.0/Close.svg';
import classNames from 'classnames';
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import styles from './SearchInput.module.scss';
import {SearchInputHandle, SearchInputProps} from './SearchInput.types';

export const SearchInput = forwardRef(
  (props: SearchInputProps, ref: Ref<SearchInputHandle>) => {
    const {
      value,
      onChange,
      prefix,
      suffix,
      allowClear,
      status,
      placeholder,
      className,
      maxLength,
      onOK,
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    //监听props.value变化，改变innerValue
    useEffect(() => {
      if (inputRef.current && inputRef.current.value !== value) {
        inputRef.current.value = value ?? '';
      }
    }, [value]);
    const handleClear = () => {
      onChange &&
        onChange({
          target: {value: ''},
        } as React.ChangeEvent<HTMLInputElement>);
      inputRef.current?.focus();
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onOK && onOK();
      }
    };

    useImperativeHandle(ref, () => ({
      focus: ({cursor}) => {
        if (inputRef.current) {
          inputRef.current.focus();
          if (cursor === 'end') {
            const length = inputRef.current.value.length;
            inputRef.current.setSelectionRange(length, length);
          } else {
            inputRef.current.setSelectionRange(0, 0);
          }
        }
      },
    }));

    return (
      <div
        className={classNames(
          styles.searchInputWrapper,
          {
            [styles.error]: status === 'error',
            [styles.warning]: status === 'warning',
          },
          className
        )}
      >
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        <input
          ref={inputRef}
          type="text"
          onChange={onChange}
          className={classNames(styles.input)}
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
        />
        {allowClear && value && (
          <span className={styles.clearIcon} onClick={handleClear}>
            <CloseIcon />
          </span>
        )}
        {suffix && <span className={styles.suffix}>{suffix}</span>}
      </div>
    );
  }
);
SearchInput.displayName = 'SearchInput';
