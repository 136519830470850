// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  BilingualDialogueProjectConfigJSON,
  Effect,
  ThumbnailType,
} from '../types';
import {BaseProjectConfig} from './BaseProjectConfig';

export class BilingualDialogueProjectConfig extends BaseProjectConfig {
  static fromJSON({
    effect,
    title_style,
    subtitle_style,
    transition,
    thumbnail_type,
    thumbnail_include_video,
    bgm,
    voiceover_order,
    voiceovers,
  }: BilingualDialogueProjectConfigJSON) {
    return new BilingualDialogueProjectConfig(
      effect,
      title_style,
      subtitle_style,
      transition,
      thumbnail_type,
      thumbnail_include_video,
      bgm,
      voiceover_order,
      voiceovers
    );
  }

  constructor(
    readonly effect: Effect,
    readonly titleStyle: string | null,
    readonly subtitleStyle: string | null,
    readonly transition: string | null,
    readonly thumbnailType: ThumbnailType,
    readonly thumbnailIncludeVideo: boolean,
    bgm: string | null,
    readonly voiceoverOrder: number,
    readonly voiceovers: [string, string, string]
  ) {
    super(bgm);
  }

  patch(data: Partial<BilingualDialogueProjectConfigJSON>) {
    const {
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceoverOrder,
      voiceovers,
    } = Object.assign({}, this, data);
    return new BilingualDialogueProjectConfig(
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceoverOrder,
      voiceovers
    );
  }

  toJSON(): BilingualDialogueProjectConfigJSON {
    return {
      ...super.toJSON(),
      effect: this.effect,
      title_style: this.titleStyle,
      subtitle_style: this.subtitleStyle,
      transition: this.transition,
      thumbnail_type: this.thumbnailType,
      thumbnail_include_video: this.thumbnailIncludeVideo,
      voiceover_order: this.voiceoverOrder,
      voiceovers: this.voiceovers,
    };
  }
}
