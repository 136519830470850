// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';

import {BilingualDialoguePreference} from './BilingualDialoguePreference';
import {BilingualStoryPreference} from './BilingualStoryPreference';
import {GeneralStoryPreference} from './GeneralStoryPreference';
import {HolidayGreetingPreference} from './HolidayGreetingPreference';
import {ShortVideoPreference} from './ShortVideoPreference';

export type Preference<T extends ProjectType> = T extends 'general_story'
  ? GeneralStoryPreference
  : T extends 'holiday_greeting'
  ? HolidayGreetingPreference
  : T extends 'bilingual_story'
  ? BilingualStoryPreference
  : T extends 'short_video'
  ? ShortVideoPreference
  : T extends 'bilingual_dialogue'
  ? BilingualDialoguePreference
  : never;

export function fromJSON(
  json: Record<string, unknown>
): Partial<{[P in ProjectType]: Preference<P>}> {
  if (!json) return {};
  return {
    general_story: GeneralStoryPreference.fromJSON(
      !json['general_story'] ? json : json['general_story']
    ),
    ...(json['bilingual_story']
      ? {
          bilingual_story: BilingualStoryPreference.fromJSON(
            json['bilingual_story']
          ),
        }
      : {}),
    ...(json['bilingual_dialogue']
      ? {
          bilingual_dialogue: BilingualDialoguePreference.fromJSON(
            json['bilingual_dialogue']
          ),
        }
      : {}),
    ...(json['short_video']
      ? {
          short_video: ShortVideoPreference.fromJSON(json['short_video']),
        }
      : {}),
    ...(json['holiday_greeting']
      ? {
          holiday_greeting: HolidayGreetingPreference.fromJSON(
            json['holiday_greeting']
          ),
        }
      : {}),
  };
}

export function create<T extends ProjectType>(type: T): Preference<T> {
  if (type === 'general_story') {
    return new GeneralStoryPreference() as Preference<T>;
  } else if (type === 'holiday_greeting') {
    return new HolidayGreetingPreference() as Preference<T>;
  } else if (type === 'bilingual_story') {
    return new BilingualStoryPreference() as Preference<T>;
  } else if (type === 'short_video') {
    return new ShortVideoPreference() as Preference<T>;
  } else if (type === 'bilingual_dialogue') {
    return new BilingualDialoguePreference() as Preference<T>;
  }
  throw new Error(`Unsupported project type: ${type}`);
}

export function patch<P extends Preference<ProjectType>>(
  preference: P,
  data: Partial<P>
): P {
  if (preference instanceof GeneralStoryPreference) {
    return preference.patch(data as Partial<GeneralStoryPreference>) as P;
  } else if (preference instanceof HolidayGreetingPreference) {
    return preference.patch(data as Partial<HolidayGreetingPreference>) as P;
  } else if (preference instanceof BilingualStoryPreference) {
    return preference.patch(data as Partial<BilingualStoryPreference>) as P;
  } else if (preference instanceof ShortVideoPreference) {
    return preference.patch(data as Partial<ShortVideoPreference>) as P;
  } else if (preference instanceof BilingualDialoguePreference) {
    return preference.patch(data as Partial<BilingualDialoguePreference>) as P;
  } else {
    throw new Error(`Unknown preference type: ${preference}`);
  }
}
