// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as Delete3Icon} from 'assets/svg/3.0/Delete3.svg';
import {ReactComponent as DownloadIcon} from 'assets/svg/3.0/Download.svg';
import {ReactComponent as LoadingSvg} from 'assets/svg/3.0/loading-rotate.svg';
import {ReactComponent as MoreIcon} from 'assets/svg/3.0/More.svg';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow3.svg';
import {ReactComponent as AssetstoolCropIcon} from 'assets/svg/outline/AssetstoolCrop.svg';
import {ReactComponent as AssetsToolRemoveBgIcon} from 'assets/svg/outline/AssetsToolRemoveBg.svg';
import {ReactComponent as AssetsToolStyleTransferIcon} from 'assets/svg/outline/AssetsToolStyleTransfer.svg';
import {ReactComponent as CheckIcon} from 'assets/svg/outline/Check.svg';
import {ReactComponent as SelectBoxIcon} from 'assets/svg/outline/SelectBox.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {dateToUpdateTime} from 'pages/common/date';
import {BlankData} from 'pages/components/BlankData';
import {Button} from 'pages/components/Button';
import {List} from 'pages/components/List';
import {Toast} from 'pages/components/Toast';
import {Upload} from 'pages/components/Upload';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './ImageGrid.module.scss';
import {ImageGridProps, TriggerElementParamsType} from './ImageGrid.type';

export function ImageGrid({
  tempList,
  assetItemList,
  deleteConfirmVisible,
  isAssetsFetchLoading,
  loadMoreRef,
  hasMore,
  isInModal = false,
  handleImageError,
  deleteAssetById,
  onConfirmDelete,
  hideDeleteConfirmModal,
  handleClickAssets,
  uploadFileAndEditAsset,
  doActionToImage,
  onChangeSelectedAssetIds,
  isMultiple,
  maxQuantity,
}: ImageGridProps) {
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [menuVisible, setMenuVisible] = useState<Record<string, boolean>>({});
  const hasData =
    (tempList && tempList.length > 0) ||
    (assetItemList && assetItemList.length > 0);
  const scrollElement = useMemo(() => {
    return (
      (document.getElementById('mootion-aside')
        ?.nextElementSibling as HTMLElement) || undefined
    );
  }, []);
  const onClickAction: TriggerElementParamsType['onClickAction'] = (
    assetId,
    actionType
  ) => {
    const assetInfo = assetItemList?.find(item => item.assetId === assetId);
    setMenuVisible(prev => ({...prev, [assetId]: false}));
    switch (actionType) {
      case 'delete':
        deleteAssetById(assetId);
        break;
      case 'crop':
      case 'rmBg':
      case 'styleTransfer':
      case 'download':
        if (!assetInfo) {
          throw new Error('assetInfo not found');
        }
        doActionToImage &&
          doActionToImage(actionType, assetInfo.assetUrl, assetInfo.assetName);
        break;
    }
  };
  const onVisibleChange = (assetId: string) => {
    return (visible: boolean) => {
      setMenuVisible(prev => ({
        ...prev,
        [assetId]: visible,
      }));
    };
  };
  const _handleClickAssets = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isMultiple) {
        return handleClickAssets && handleClickAssets(e);
      } else {
        const target = e.currentTarget;
        const assetUrl = target.getAttribute('data-asset-url');
        if (assetUrl) {
          setSelectedAssetIds(prev => {
            const index = prev.indexOf(assetUrl);
            if (index > -1) {
              return prev.filter(id => id !== assetUrl);
            } else {
              if (
                (maxQuantity && selectedAssetIds.length < maxQuantity) ||
                maxQuantity === undefined
              ) {
                return [...prev, assetUrl];
              } else {
                return prev;
              }
            }
          });
        }
      }
    },
    [handleClickAssets, isMultiple, maxQuantity, selectedAssetIds.length]
  );

  useEffect(() => {
    if (isMultiple) {
      onChangeSelectedAssetIds && onChangeSelectedAssetIds(selectedAssetIds);
    }
  }, [isMultiple, onChangeSelectedAssetIds, selectedAssetIds]);

  const {t} = useTranslation('workspace');
  return (
    <>
      {hasData ? (
        <div
          className={classNames(styles.image_grid + ' ' + 'image_grid', {
            [styles['simple']]: isInModal,
          })}
        >
          <div className={styles.image_container}>
            <div className={styles.image_wrap_outer}>
              <div className={styles.image_wrap + ' ' + styles.uploader}>
                <Upload
                  accept={['image/jpeg', 'image/png', 'image/webp']}
                  uploadFileAndEditAsset={uploadFileAndEditAsset}
                />
              </div>
            </div>
            {!isInModal && (
              <div className={styles.footer_upload}>{t('Upload')}</div>
            )}
          </div>
          {tempList &&
            tempList.map(tempItem => (
              <div className={styles.image_container} key={tempItem.imgUrl}>
                <div className={styles.image_wrap_outer}>
                  <div className={styles.image_wrap}>
                    <img
                      className={styles.image + ' ' + styles.image_uploading}
                      src={tempItem.imgUrl}
                    />
                    <div className={styles.image_wrap_mask_content}>
                      <span>{t('uploading')}</span>
                      <span>{tempItem.uploadProgress}%</span>
                    </div>
                  </div>
                </div>
                {!isInModal && (
                  <div className={styles.footer}>{t('uploading')}</div>
                )}
              </div>
            ))}
          {assetItemList &&
            assetItemList.map(assetItem => (
              <div
                className={styles.image_container + ' ' + 'image_container'}
                key={assetItem.assetId}
                data-asset-url={assetItem.assetUrl}
                data-asset-name={assetItem.assetName}
                onClick={_handleClickAssets}
              >
                <div className={styles.image_wrap_outer}>
                  <div className={styles.image_wrap}>
                    <img
                      className={styles.image}
                      src={assetItem.assetThumbUrl}
                      alt={assetItem.assetName}
                      data-asset-id={assetItem.assetId}
                      onError={handleImageError}
                    />
                    {isMultiple ? (
                      selectedAssetIds.includes(assetItem.assetUrl) ? (
                        <CheckIcon className={styles['checkbox']} />
                      ) : (
                        <SelectBoxIcon className={styles['checkbox']} />
                      )
                    ) : null}
                  </div>
                </div>
                {isInModal ? null : (
                  <div className={styles.footer}>
                    <div className={styles.updateTime}>
                      {dateToUpdateTime(new Date(assetItem.updateTime))}
                    </div>
                    <Popover
                      key={assetItem.assetId}
                      className={styles.more_popover}
                      scrollElement={scrollElement}
                      visible={menuVisible[assetItem.assetId]}
                      onVisibleChange={onVisibleChange(assetItem.assetId)}
                      content={
                        <PopoverContent
                          assetId={assetItem.assetId}
                          onClickAction={onClickAction}
                        />
                      }
                      triggerElement={
                        <Button type="icon" onClick={e => e.stopPropagation()}>
                          <MoreIcon className={styles.more} />
                        </Button>
                      }
                    />
                  </div>
                )}
              </div>
            ))}
          {hasMore && !isAssetsFetchLoading && (
            <LoadingSvg
              ref={loadMoreRef}
              className={styles.rotateLoading + ' loadMore'}
            />
          )}
        </div>
      ) : null}
      {assetItemList &&
      assetItemList.length === 0 &&
      (!tempList || tempList?.length === 0) ? (
        <BlankData
          quotation={t('CREATIVITY IS INTELLIGENCE HAVING FUN.')}
          quotationAuthor={t('Albert Einstein')}
          footerMsg={t('You have no assets yet')}
          footerAction={
            <Button type="link" className={styles.blanckDataButton}>
              {t('Upload')}
              <RightArrowIcon />
              <Upload
                accept={['image/jpeg', 'image/png', 'image/webp']}
                className={styles.uploadBtnInBlanckData}
                uploadFileAndEditAsset={uploadFileAndEditAsset}
              />
            </Button>
          }
          showQuota={!isInModal}
          className={classNames(styles.blankDataSection, {
            [styles['isInModal']]: isInModal,
          })}
        />
      ) : null}
      <Toast
        title={t('Delete')}
        visible={deleteConfirmVisible}
        onOk={onConfirmDelete}
        onCancel={hideDeleteConfirmModal}
        showCloseBtn={false}
        btnTheme="danger"
        cancelText={t('Cancel')}
        confirmText={t('Delete')}
      >
        {t('Confirm to delete?')}
      </Toast>
    </>
  );
}

const PopoverContent = function (props: TriggerElementParamsType) {
  const {assetId, onClickAction} = props;
  const {t} = useTranslation('workspace');
  return (
    <List size="small" onClick={e => e.stopPropagation()}>
      <li onClick={() => onClickAction(assetId, 'crop')}>
        <AssetstoolCropIcon /> {t('Crop')}
      </li>
      <li onClick={() => onClickAction(assetId, 'rmBg')}>
        <AssetsToolRemoveBgIcon /> {t('Remove BG')}
      </li>
      <li onClick={() => onClickAction(assetId, 'styleTransfer')}>
        <AssetsToolStyleTransferIcon /> {t('Style transfer')}
      </li>
      <li onClick={() => onClickAction(assetId, 'download')}>
        <DownloadIcon /> {t('Download')}
      </li>
      <li onClick={() => onClickAction(assetId, 'delete')}>
        <Delete3Icon /> {t('Delete')}
      </li>
    </List>
  );
};
