// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Popover} from 'components/PopoverNew';
import {useResourceManager} from 'contexts/ResourceManager';
import {AvatarCharacter} from 'contexts/ResourceManager.type';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {CharacterPreview} from './CharacterPreview';
import {Props} from './CharacterSelect.types';
import {CharacterSelectContent} from './CharacterSelectContent';

export function CharacterSelect({
  value,
  characters,
  onChange,
  disabled = false,
}: Props) {
  const {resUrl} = useResourceManager();
  const [visible, setVisible] = useState(false);
  const {t} = useTranslation('project');
  const selectedCharacter = characters.find(
    avatar => avatar.name === value
  ) as AvatarCharacter;

  const scrollElement = document.getElementById('config-box-container');
  return (
    <Popover
      triggerElement={
        <CharacterPreview
          label={t('AI avatar')}
          disabled={disabled}
          avatarImage={`${resUrl}${selectedCharacter.image}`}
        />
      }
      trigger="click"
      content={
        <CharacterSelectContent
          characters={characters}
          value={selectedCharacter.name}
          setVisible={setVisible}
          onChange={onChange}
          resUrl={resUrl}
          title={t('AI avatar')}
        />
      }
      direction="bottom"
      distance={10}
      scrollElement={scrollElement ?? undefined}
      minHeight={200}
      disabled={disabled}
      visible={visible}
      onVisibleChange={setVisible}
    />
  );
}
