// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Draft} from 'modules/draft/models/Draft';
import {SceneAsset} from 'modules/scene-asset/models/SceneAsset';
import {SceneAssetType} from 'modules/scene-asset/types';
import {TaskType as StoryboardTaskType} from 'modules/storyboard/types';
import {Task} from 'modules/task/models/Task';

import {
  HolidayGreetingClosedScene,
  HolidayGreetingSceneJSON,
  TaskType,
} from '../types';
import {isSceneTask, processSceneAssets} from '../utils';
import {BaseScene} from './BaseScene';

export class HolidayGreetingScene extends BaseScene {
  static fromJSON(
    {
      id,
      type,
      holiday_image,
      prompt,
      last_prompt,
      subtitle,
      shot_type,
      characters,
      image,
      video,
      draft,
      task,
      animate_prompt,
      current_asset,
      assets,
    }: HolidayGreetingSceneJSON,
    tasks?: Task<StoryboardTaskType>[]
  ) {
    const _task = task && tasks ? tasks.find(t => t.id === task) : undefined;
    if (task && !_task) {
      throw new Error(`Task with id ${task} not found`);
    }
    if (_task && !isSceneTask(_task)) {
      throw new Error(`Invalid task type: ${_task.type}`);
    }
    const {currentAsset, assetsList} = processSceneAssets({
      image,
      video,
      current_asset,
      assets,
    });
    return new HolidayGreetingScene(
      id,
      type,
      holiday_image,
      currentAsset,
      assetsList,
      prompt,
      last_prompt,
      subtitle,
      shot_type,
      characters,
      draft ? Draft.fromJSON(draft) : undefined,
      _task as Task<TaskType> | undefined,
      animate_prompt
    );
  }

  constructor(
    id: string,
    type: string,
    readonly holidayImage?: string,
    currentAsset?: SceneAsset<SceneAssetType>,
    assets?: SceneAsset<SceneAssetType>[],
    prompt?: string,
    lastPrompt?: string,
    subtitle?: string,
    shotType?: number,
    characters?: {name: string; prompt?: string}[],
    draft?: Draft,
    task?: Task<TaskType>,
    animatePrompt?: string
  ) {
    super(
      id,
      type,
      currentAsset,
      assets,
      prompt,
      lastPrompt,
      subtitle,
      shotType,
      characters,
      draft,
      task,
      animatePrompt
    );
  }

  patch(
    data: Partial<{
      [K in
        | 'subtitle'
        | 'draft'
        | 'task'
        | 'currentAsset'
        | 'assets']: HolidayGreetingScene[K];
    }>
  ) {
    const {
      id,
      type,
      prompt,
      lastPrompt,
      subtitle,
      shotType,
      characters,
      draft,
      task,
      animatePrompt,
      currentAsset,
      assets,
      holidayImage,
    } = Object.assign({}, this, data);
    return new HolidayGreetingScene(
      id,
      type,
      holidayImage,
      currentAsset,
      assets,
      prompt,
      lastPrompt,
      subtitle,
      shotType,
      characters,
      draft,
      task,
      animatePrompt
    );
  }

  toJSON(): HolidayGreetingSceneJSON {
    return {
      ...super.toJSON(),
      holiday_image: this.holidayImage,
    };
  }

  toClosed(): HolidayGreetingClosedScene {
    return {
      ...super.toClosed(),
      holiday_image: this.holidayImage,
    };
  }

  get canAnimate() {
    return false;
  }
}
