// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as AvatarLayoutIcon} from 'assets/svg/outline/AvatarLayout.svg';
import {AvatarCharacter} from 'contexts/ResourceManager.type';
import {Collapse} from 'pages/components/Collapse';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './AvatarLayout.module.scss';
import {Props} from './AvatarLayout.types';
import {CharacterSelect} from './CharacterSelect';
import {LayoutPreview} from './LayoutPreview';

export function AvatarLayout({
  avatar,
  avatarData: {characters, layouts},
  onChange,
}: Props) {
  const {t} = useTranslation('project');

  const avatarCharacter = useMemo(
    () => avatar?.character ?? characters[0].name,
    [avatar?.character, characters]
  );
  const avatarLayout = useMemo(() => avatar?.layout ?? null, [avatar?.layout]);

  const onLayoutChange = useCallback(
    (layout: string | null) => {
      onChange({
        character: avatarCharacter,
        layout,
      });
    },
    [avatarCharacter, onChange]
  );
  const onChangeCharacter = useCallback(
    (character: string) => {
      onChange({
        character,
        layout: avatarLayout,
      });
    },
    [avatarLayout, onChange]
  );
  const avatarImage = useMemo(() => {
    return (
      characters.find(
        character => character.name === avatarCharacter
      ) as AvatarCharacter
    ).image;
  }, [characters, avatarCharacter]);

  return (
    <Collapse
      icon={<AvatarLayoutIcon className={styles['label-icon']} />}
      title={<span>{t('AI avatar layout')}</span>}
      className={styles['collapse-header']}
      hideArrowWhenOpen={true}
    >
      <div className={styles.setting}>
        <CharacterSelect
          characters={characters}
          disabled={avatarLayout === null}
          value={avatarCharacter}
          onChange={onChangeCharacter}
        />
      </div>
      <div className={styles.layoutGrid}>
        <LayoutPreview
          label={t('No avatar')}
          value={null}
          isSelected={avatarLayout === null}
          avatarImage={avatarImage}
          onSelect={onLayoutChange}
        />
        {layouts.map(option => (
          <LayoutPreview
            key={option.name}
            label={t(option.display_name)}
            value={option.name}
            isSelected={option.name === avatarLayout}
            avatarImage={avatarImage}
            onSelect={onLayoutChange}
          />
        ))}
      </div>
    </Collapse>
  );
}
