// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ColorSceneAssetJSON} from '../types';
import {BaseSceneAsset} from './BaseSceneAsset';

export class ColorSceneAsset extends BaseSceneAsset<'color'> {
  static fromJSON({id, value}: ColorSceneAssetJSON): ColorSceneAsset {
    return new ColorSceneAsset(id, value);
  }
  get type(): 'color' {
    return 'color';
  }
  toJSON(): ColorSceneAssetJSON {
    return {
      ...super.toJSON(),
    };
  }
}
