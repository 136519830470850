// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as NewIcon} from 'assets/svg/3.0/New.svg';
import {ReactComponent as LoadingSvg} from 'assets/svg/outline/LoadingLine.svg';
import classNames from 'classnames';
import {ObjectsEditor} from 'components/ObjectsEditor';
import {Ratio} from 'lib/ratio';
import {ProjectType} from 'modules/project/types';
import {BilingualDialogueScene} from 'modules/scene/models/BilingualDialogueScene';
import {BilingualStoryScene} from 'modules/scene/models/BilingualStoryScene';
import {ThumbnailLoader} from 'pages/components/ThumbnailLoader';
import {useTranslation} from 'react-i18next';

import {BilingualSubtitleEditor} from '../BilingualSubtitleEditor/BilingualSubtitleEditor';
import {SceneActionMenu} from '../SceneActionMenu/SceneActionMenu';
import {AnimationType} from '../SceneList.type';
import {SubtitleEditor} from '../SubtitleEditor';
import styles from './SceneItem.module.scss';
import {Props} from './SceneItem.types';

const RATIO_WITH_SIZE_MAP: Record<Ratio, {width: number; height: number}> = {
  '16:9': {width: 120, height: 67},
  '1:1': {width: 75, height: 75},
  '9:16': {width: 67, height: 120},
};

export function SceneItem({
  scene,
  title,
  ratio,
  isNew,
  isProcessing,
  animateStatus,
  isSelected,
  onSelected,
  getImageWithObjectKey,
  refreshImage,
  onUpdateScene,
  sceneSubtitleMaxLength,
  ...props
}: Props<ProjectType>) {
  const {subtitle, currentAsset} = scene;
  const {t} = useTranslation('project');

  function onSubtitleChange(subtitle: string) {
    onUpdateScene(scene.id, {subtitle});
  }

  function onNativeSubtitleChange(nativeSubtitle: string) {
    onUpdateScene(scene.id, {nativeSubtitle});
  }

  function renderTitleEditor() {
    const baseProps = {
      subtitle: subtitle + '',
      isSelected: isSelected,
      isProcessing: isProcessing,
      isAnimating:
        (animateStatus & AnimationType.isAnimating) ===
        AnimationType.isAnimating,
      onChange: onSubtitleChange,
      editorType: 'Medium',
      onFocus: onSelected,
      subtitleMaxLength: sceneSubtitleMaxLength,
    } as const;
    if (scene instanceof BilingualStoryScene) {
      return (
        <BilingualSubtitleEditor
          {...baseProps}
          nativeSubtitle={scene.nativeSubtitle + ''}
          onNativeSubtitleChange={onNativeSubtitleChange}
        />
      );
    }
    if (scene instanceof BilingualDialogueScene) {
      return (
        <BilingualSubtitleEditor
          {...baseProps}
          nativeSubtitle={scene.nativeSubtitle + ''}
          onNativeSubtitleChange={onNativeSubtitleChange}
          speaker={scene.speaker}
        />
      );
    }
    return <SubtitleEditor {...baseProps} />;
  }
  return (
    <li
      className={classNames(
        styles.container,
        isSelected && !isProcessing && styles.selected
      )}
      onClick={onSelected}
    >
      <div className={styles['menu-box']}>
        <SceneActionMenu
          {...props}
          animateStatus={animateStatus}
          scene={scene}
        />
      </div>
      <div className={styles.header}>
        <p className={classNames(styles.title, isSelected && styles.selected)}>
          {title}
        </p>
        {(animateStatus & AnimationType.isAnimating) ===
          AnimationType.isAnimating && (
          <LoadingSvg className={styles.rotateLoading} />
        )}
        <p className={classNames(styles.new, isNew && styles.show)}>
          <NewIcon className={styles.icon} />
          <span>{t('New')}</span>
        </p>
      </div>

      <div className={styles.info}>
        <div
          className={classNames(
            styles['preview-box'],
            isSelected && styles.selected
          )}
          style={{...RATIO_WITH_SIZE_MAP[ratio]}}
        >
          {currentAsset && currentAsset.type !== 'video' && scene.draft ? (
            <ObjectsEditor
              draft={scene.draft}
              className={styles.preview}
              outerHeight={RATIO_WITH_SIZE_MAP[ratio].height}
              outerWidth={RATIO_WITH_SIZE_MAP[ratio].width}
              getImageWithObjectKey={getImageWithObjectKey}
              refreshImage={refreshImage}
              disabled
              animating={
                (animateStatus & AnimationType.isAnimating) ===
                AnimationType.isAnimating
              }
              background={
                (scene.currentAsset && scene.currentAsset.value) || ''
              }
              previewOnly
            />
          ) : (
            <ThumbnailLoader
              src={scene.currentImage || ''}
              isDisabled={
                (animateStatus & AnimationType.isAnimating) ===
                AnimationType.isAnimating
              }
              className={styles.preview}
              isProcessing={isProcessing}
              isVideo={currentAsset && currentAsset.type === 'video'}
            />
          )}
        </div>
        {renderTitleEditor()}
      </div>
    </li>
  );
}
