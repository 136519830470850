// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {BaseSceneAssetJSON, SceneAssetType} from '../types';

export abstract class BaseSceneAsset<T extends SceneAssetType> {
  constructor(readonly id: string, readonly value: string) {}
  abstract get type(): T;

  toJSON(): BaseSceneAssetJSON<T> {
    return {
      id: this.id,
      type: this.type,
      value: this.value,
    };
  }
}
