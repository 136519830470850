// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {DraftJSON} from '../types';
import {fromJSON as objectFromJSON, SceneObject} from './SceneObject';

export class Draft {
  static fromJSON({objects, image}: DraftJSON) {
    return new Draft(objects.map(objectFromJSON), image);
  }

  constructor(readonly objects: SceneObject[], readonly image?: string) {}

  patch(data: Partial<Draft>) {
    const {objects, image} = Object.assign({}, this, data);
    return new Draft(objects, image);
  }

  isValid() {
    return this.objects.some(o => o.isValid());
  }

  toJSON(): DraftJSON {
    return {
      objects: this.objects.filter(o => o.isValid()).map(o => o.toJSON()),
      image: this.image,
    };
  }
}
