// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {VideoSceneAssetJSON} from '../types';
import {BaseSceneAsset} from './BaseSceneAsset';

export class VideoSceneAsset extends BaseSceneAsset<'video'> {
  static fromJSON({
    id,
    value,
    ref_image,
  }: VideoSceneAssetJSON): VideoSceneAsset {
    return new VideoSceneAsset(id, value, ref_image);
  }
  constructor(id: string, value: string, readonly refImage: string) {
    super(id, value);
  }
  get type(): 'video' {
    return 'video';
  }

  toJSON(): VideoSceneAssetJSON {
    return {
      ...super.toJSON(),
      ref_image: this.refImage,
    };
  }
}
