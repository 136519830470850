// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Secret, Token} from 'fernet/fernetBrowser';

export function obfuscate_string(base: string, input: string) {
  if (input.length === 0) return base;
  const step = Math.floor(base.length / input.length);
  let remaining = `${input}`;
  let target = `${base}`;
  while (remaining.length !== 0) {
    let current: string;
    if ((remaining.length - 1) % 2 === 0) {
      current = remaining.slice(0, 1);
      remaining = remaining.slice(1);
    } else {
      current = remaining.slice(remaining.length - 1);
      remaining = remaining.slice(0, remaining.length - 1);
    }
    let index = remaining.length * step;
    index = remaining.length % 2 === 0 ? index : target.length - index;
    target = `${target.slice(0, index)}${current}${target.slice(index)}`;
  }
  return target;
}

export function disassemble_string(input: string, length: number) {
  if (length <= 0 || input.length < length) {
    throw new Error('Invalid input');
  }
  const step = Math.floor(input.length / length) - 1;
  let base = `${input}`;
  let target = '';
  while (target.length !== length) {
    let index = target.length * step;
    index = target.length % 2 === 0 ? index : base.length - 1 - index;
    const current = base.slice(index, index + 1);
    base = `${base.slice(0, index)}${base.slice(index + 1)}`;
    if (target.length % 2 === 0) {
      target = `${current}${target}`;
    } else {
      target = `${target}${current}`;
    }
  }
  return [base, target];
}

function encrypt_data_v1(data: object, time: number) {
  const key = btoa(getRandomLengthString(32, 32));
  const secret = new Secret(key);
  const token = new Token({secret, time: new Date(time * 1000)});
  const encrypted_data = token.encode(JSON.stringify(data));
  const key_prefix = key.slice(0, key.length - 2);
  const key_suffix = key.slice(key.length - 2);
  const final_key = btoa(
    obfuscate_string(key_prefix, String(time)) + key_suffix
  );
  const next_data = btoa(
    JSON.stringify({data: encrypted_data, key: final_key})
  );
  const data_prefix = next_data.slice(0, next_data.length - 2);
  const data_suffix = next_data.slice(next_data.length - 2);
  return btoa(obfuscate_string(data_prefix, String(time)) + data_suffix);
}

export function encrypt_data(data: object, version: number, time: number) {
  switch (version) {
    case 1:
      return encrypt_data_v1(data, time);
    default:
      return data;
  }
}

export function getRandomLengthString(
  minLength: number,
  maxLength: number
): string {
  const length =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}
