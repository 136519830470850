// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as PlusIcon} from 'assets/svg/3.0/AddAsset.svg';
import {ReactComponent as CloseBtn} from 'assets/svg/3.0/CloseBtn.svg';
import classNames from 'classnames';
import {Toast} from 'pages/components/Toast';
import React, {useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './Uploader.module.scss';
import {UploadProps} from './Uploader.type';

export const Uploader = ({
  accept,
  disabled,
  errorToastVisible,
  errorInfo,
  className,
  isMultiple = false,
  errorType,
  icon,
  handleFileChange,
  handleDrop,
  hideErrorToast,
  handleUpload,
}: UploadProps) => {
  const _handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleDrop(event);
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const {t} = useTranslation('workspace');
  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    },
    []
  );
  return (
    <div
      className={classNames(styles.uploadContainer, className)}
      onClick={() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        inputRef.current?.click();
      }}
    >
      <div
        className={styles.file}
        onDragOver={handleDragOver}
        onDrop={_handleDrop}
      >
        <input
          className={styles.fileInput}
          type="file"
          onChange={handleFileChange}
          accept={accept?.join(',')}
          disabled={disabled}
          ref={inputRef}
          multiple={isMultiple}
        />
      </div>
      {icon ?? <PlusIcon />}
      <Toast
        title={
          errorType === 'uploadFailed' ? t('Upload failed') : t('Upload files')
        }
        visible={errorToastVisible}
        showCloseBtn={false}
        confirmText={errorType === 'uploadFiles' ? t('Upload') : t('Retry')}
        cancelText={t('Cancel')}
        onCancel={hideErrorToast}
        onOk={errorType === 'uploadFiles' ? handleUpload : hideErrorToast}
        showCancel={errorType === 'uploadFiles'}
      >
        <div className={styles.errorToastContent}>
          {errorInfo.maximumError && errorType !== 'uploadFailed' && (
            <div>
              <div className={styles.errorTitle}>
                {errorInfo.maximumError.message}
              </div>
            </div>
          )}
          {errorInfo.otherError.length > 0 && (
            <>
              <div>
                {errorType === 'uploadFailed' ? (
                  <div className={styles.errorTitle}>
                    {t(
                      'All files can not be uploaded, please revise and submit again.'
                    )}
                  </div>
                ) : (
                  <div className={styles.errorTitle}>
                    {t(
                      'Following files can not be uploaded, please revise and submit again.'
                    )}
                  </div>
                )}

                <div className={styles.split}></div>
                <div className={styles.errorContent}>
                  {errorInfo.otherError.map((info, index) => (
                    <div key={index}>
                      <div className={styles.subtitle}>{info.message}</div>
                      {info.details && (
                        <div>
                          {info.details.map((detail, idx) => (
                            <div key={idx} className={styles.subInfo}>
                              <CloseBtn />{' '}
                              <span className={styles.fileName}>
                                <span className={styles['file-name-no-suffix']}>
                                  {detail.name}
                                </span>
                                {detail.extension && (
                                  <span className={styles.suffix}>
                                    {detail.extension}
                                  </span>
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </Toast>
    </div>
  );
};
