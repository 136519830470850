// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {PlanType} from 'modules/payment/types';
import {PROJECT_RULE} from 'modules/payment/utils';
import {ProjectType} from 'modules/project/types';

import {Constraint} from './models/Constraint';
import {RangeConstraintValue} from './models/RangeConstraintValue';

export function getConstraintByProjectAndType<T extends ProjectType>(
  projectType: T,
  promptType: 'idea' | 'content',
  plan: PlanType
): Partial<Constraint<T>> {
  if (projectType === 'holiday_greeting') {
    return {
      promptLength: RangeConstraintValue.fromJSON({
        min: undefined,
        max: 300,
      }),
      sceneQuantity: RangeConstraintValue.fromJSON({
        min: undefined,
        max: 6,
      }),
      sceneSubtitleLength: RangeConstraintValue.fromJSON({
        min: undefined,
        max: 50,
      }),
      imagesQuantity: RangeConstraintValue.fromJSON({
        min: undefined,
        max: 6,
      }),
    } as unknown as Partial<Constraint<T>>;
  }
  if (projectType === 'general_story' || projectType === 'short_video') {
    if (promptType === 'idea') {
      return {
        promptLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxPromptCharacterNumber'],
        }),
        sceneQuantity: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxParagraphsNumber'],
        }),
        sceneSubtitleLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxParagraphCharacterNumber'],
        }),
      } as Partial<Constraint<T>>;
    } else if (promptType === 'content') {
      return {
        promptLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxContentCharacterNumber'],
        }),
        sceneQuantity: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxParagraphsNumber'],
        }),
        sceneSubtitleLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxParagraphCharacterNumber'],
        }),
      } as Partial<Constraint<T>>;
    } else {
      throw new Error(`Unknown promptType: ${promptType}`);
    }
  } else if (
    projectType === 'bilingual_dialogue' ||
    projectType === 'bilingual_story'
  ) {
    if (promptType === 'idea') {
      return {
        promptLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxPromptCharacterNumber'],
        }),
        sceneQuantity: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxParagraphsNumber'],
        }),
        sceneSubtitleLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxParagraphCharacterNumber'],
        }),
        vocabularyPhraseLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: 30,
        }),
        vocabularyPhraseQuantity: RangeConstraintValue.fromJSON({
          min: undefined,
          max: 5,
        }),
      } as unknown as Partial<Constraint<T>>;
    } else if (promptType === 'content') {
      return {
        promptLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxContentCharacterNumber'],
        }),
        sceneQuantity: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxParagraphsNumber'],
        }),
        sceneSubtitleLength: RangeConstraintValue.fromJSON({
          min: undefined,
          max: PROJECT_RULE[plan]['maxParagraphCharacterNumber'],
        }),
      } as Partial<Constraint<T>>;
    } else {
      throw new Error(`Unknown promptType: ${promptType}`);
    }
  } else {
    throw new Error(`Unknown project type: ${projectType}`);
  }
}
