// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ImageSceneAssetJSON} from '../types';
import {BaseSceneAsset} from './BaseSceneAsset';

export class ImageSceneAsset extends BaseSceneAsset<'image'> {
  static fromJSON({id, value}: ImageSceneAssetJSON): ImageSceneAsset {
    return new ImageSceneAsset(id, value);
  }
  get type(): 'image' {
    return 'image';
  }
  toJSON(): ImageSceneAssetJSON {
    return {
      ...super.toJSON(),
    };
  }
}
