// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as RightBtnIcon} from 'assets/svg/outline/RightBtn2.svg';
import classNames from 'classnames';
import React from 'react';

import {SliderGridProps} from './Slider.types';
import styles from './SliderGrid.module.scss';

export const SliderGrid: React.FC<SliderGridProps> = ({
  list,
  onScroll,
  scrollContainerRef,
  sliderGap,
  sliderItemWidth,
}) => {
  const MoveDistance = sliderItemWidth + sliderGap;

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const startX = e.touches[0].clientX;
    const startY = e.touches[0].clientY;
    const handleTouchMove = (moveEvent: TouchEvent) => {
      const currentX = moveEvent.touches[0].clientX;
      const currentY = moveEvent.touches[0].clientY;
      const deltaX = startX - currentX;
      const deltaY = startY - currentY;

      const verticalSwipeThreshold = 30; // Define a threshold for vertical swipe
      if (
        Math.abs(deltaX) > Math.abs(deltaY) &&
        Math.abs(deltaY) < verticalSwipeThreshold
      ) {
        if (deltaX > 50) {
          onScroll('right');
          document.removeEventListener('touchmove', handleTouchMove);
        } else if (deltaX < -50) {
          onScroll('left');
          document.removeEventListener('touchmove', handleTouchMove);
        }
      }
    };
    document.addEventListener('touchmove', handleTouchMove);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const startX = e.clientX;
    const handleMouseMove = (moveEvent: MouseEvent) => {
      const x = moveEvent.clientX - startX;
      onScroll(x);
    };
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    e.currentTarget.addEventListener('mouseleave', handleMouseUp);
  };

  const currentLeft = parseInt(scrollContainerRef.current?.style.left || '0');
  const viewPointWidth =
    scrollContainerRef.current?.getBoundingClientRect().width ?? 0;
  const showLeftBtn = currentLeft < 0;
  const showRightBtn =
    viewPointWidth - currentLeft < list.length * MoveDistance - sliderGap;

  return (
    <div
      className={styles.slideGridWrapper}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      {showLeftBtn && (
        <button
          className={classNames(styles.navigationButton, styles.leftBtn)}
          onClick={() => onScroll('left')}
        >
          <RightBtnIcon />
        </button>
      )}

      <div className={styles.slideGrid} ref={scrollContainerRef}>
        {list}
      </div>
      {showRightBtn && (
        <button
          className={classNames(styles.navigationButton, styles.rightBtn)}
          onClick={() => onScroll('right')}
        >
          <RightBtnIcon />
        </button>
      )}
    </div>
  );
};
