// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {BackendClient, ResponseCode} from 'api/BackendClient';
import {AxiosError} from 'axios';
import {AppError, ErrorType} from 'contexts/ErrorContext';
import {ResourceContext} from 'contexts/ResourceManager.type';
import {UserInfo} from 'contexts/UserContext';
import {BilingualDialoguePreference} from 'modules/preference/models/BilingualDialoguePreference';
import {BilingualStoryPreference} from 'modules/preference/models/BilingualStoryPreference';
import {GeneralStoryPreference} from 'modules/preference/models/GeneralStoryPreference';
import {Preference} from 'modules/preference/models/Preference';
import {ShortVideoPreference} from 'modules/preference/models/ShortVideoPreference';
import {fromJSON} from 'modules/project/models/Project';
import {TEMPLATES} from 'modules/project/templates';
import {ProjectJSON, ProjectType} from 'modules/project/types';
import {
  convertProjectVersion,
  ConvertResult,
  createProjectByUserPreference,
  getCachedProject,
  ProjectCacheSessionStorageMap,
  reviewProject,
} from 'modules/project/utils';
import {isLastVersion} from 'modules/project/version';
import {GeneralStoryHistoryJSON} from 'modules/project-history/types';
import {defer, LoaderFunctionArgs} from 'react-router-dom';

import {LoaderError} from './ProjectPage.error';

async function convertVersion(
  json: ProjectJSON<ProjectType>,
  projectId: string,
  backendClient: BackendClient
): Promise<ProjectJSON<ProjectType>> {
  const convertResult = convertProjectVersion(json) as ConvertResult;
  if (
    convertResult.histories &&
    convertResult.histories.some(h => typeof h !== 'string')
  ) {
    //判断 ProjectJSON 中的histories，如果有值就要上传
    await backendClient.uploadHistories(
      projectId,
      convertResult.histories as GeneralStoryHistoryJSON[]
    );
    convertResult.histories = convertResult.histories.map(
      history => (history as GeneralStoryHistoryJSON).id
    );
  }
  await backendClient.updateProject({
    projectId: convertResult.id,
    projectJsonContent: JSON.stringify(convertResult),
  });
  return convertResult as ProjectJSON<ProjectType>;
}

async function loadProject(
  id: string,
  params: URLSearchParams,
  backendClient: BackendClient,
  userInfo: UserInfo,
  checkoutPreference: <T extends ProjectType>(type: T) => Preference<T>
) {
  if (id === 'new') {
    const type = (params.get('type') ?? 'general_story') as ProjectType;
    const input = params.get('input');
    const promptType = params.get('prompt_type') ?? 'idea';
    const _preference = checkoutPreference(type);
    const projectJSON: ProjectJSON<ProjectType> =
      getCachedProject(
        sessionStorage.getItem(ProjectCacheSessionStorageMap[type])
      ) ??
      createProjectByUserPreference(
        Object.assign({}, TEMPLATES[type]),
        _preference
      );

    if (type === 'bilingual_dialogue') {
      if (projectJSON.prompt_policy.keep_user_content === true) {
        projectJSON.prompt_policy = {
          keep_user_content: false,
          proficiency_level:
            (_preference as BilingualDialoguePreference).proficiencyLevel ??
            "normal language learners'",
        };
      }
    } else {
      if (
        promptType === 'script' &&
        projectJSON.prompt_policy.keep_user_content === false
      ) {
        projectJSON.prompt_policy = {
          keep_user_content: true,
          paragraph_as_shots:
            type === 'holiday_greeting'
              ? true
              : (
                  _preference as
                    | GeneralStoryPreference
                    | ShortVideoPreference
                    | BilingualStoryPreference
                ).paragraphAsShots ?? false,
        };
      } else if (
        promptType === 'idea' &&
        projectJSON.prompt_policy.keep_user_content === true
      ) {
        switch (type) {
          case 'general_story':
          case 'short_video': {
            projectJSON.prompt_policy = {
              keep_user_content: false,
              tone:
                (_preference as GeneralStoryPreference | ShortVideoPreference)
                  .tone ?? 'Neutral',
              ...(type === 'general_story'
                ? {
                    quick_pace:
                      (_preference as GeneralStoryPreference).quickPace ??
                      false,
                  }
                : {}),
            };
            break;
          }
          case 'holiday_greeting': {
            projectJSON.prompt_policy = {
              keep_user_content: false,
            };
            break;
          }
          case 'bilingual_story': {
            projectJSON.prompt_policy = {
              keep_user_content: false,
              proficiency_level:
                (_preference as BilingualStoryPreference).proficiencyLevel ??
                "normal language learners'",
            };
            break;
          }
        }
      }
    }
    projectJSON.author_id = userInfo.userId;
    if (input) {
      projectJSON.prompt = input.slice(
        0,
        projectJSON.constraint?.prompt_length?.max || 2000
      );
    }
    return projectJSON;
  } else {
    try {
      const data = await backendClient.getProject(id, true);
      return {...data, id};
    } catch (err) {
      if (err instanceof AppError && err.type === ErrorType.Network) {
        throw new LoaderError('network');
      } else if (
        err instanceof AxiosError &&
        err.response?.status === 400 &&
        err.response.data.error === ResponseCode.USER_PROJECT_NOT_EXIST
      ) {
        throw new LoaderError('permission');
      } else {
        throw new LoaderError('system');
      }
    }
  }
}

export async function loader(
  {request, params}: LoaderFunctionArgs,
  backendClient: BackendClient,
  resourceManager: ResourceContext,
  userInfo: UserInfo,
  checkoutPreference: <T extends ProjectType>(type: T) => Preference<T>
) {
  const {id} = params;
  const projectJson = await loadProject(
    id ?? 'new',
    new URL(request.url).searchParams,
    backendClient,
    userInfo,
    checkoutPreference
  );
  if (!isLastVersion(projectJson) && id && id !== 'new') {
    return defer({
      project: convertVersion(projectJson, id, backendClient).then(json =>
        reviewProject(fromJSON(json), resourceManager, userInfo.plan)
      ),
    });
  } else {
    return {
      project: reviewProject(
        fromJSON(projectJson),
        resourceManager,
        userInfo.plan
      ),
    };
  }
}
