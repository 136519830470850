// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {Props} from './TurnstileBox.types';

export function TurnstileBox({onVerify}: Props) {
  const {i18n} = useTranslation();
  const turnstileRef = useRef<HTMLDivElement>(null);
  const widgetIdRef = useRef<string | null>(null);

  const removeWidget = useCallback(() => {
    const widgetId = widgetIdRef.current;
    if (widgetId) {
      window.turnstile.remove(widgetId);
      widgetIdRef.current = null;
    }
  }, []);

  const renderWidget = useCallback(() => {
    const turnstileBox = turnstileRef.current;
    if (!turnstileBox) return;
    if (!window.turnstile) {
      throw new Error('Turnstile is not loaded');
    } else {
      widgetIdRef.current = window.turnstile.render(turnstileBox, {
        sitekey: process.env
          .REACT_APP_CLOUDFLARE_MANAGED_WIDGET_SITE_KEY as string,
        callback: onVerify,
      });
    }
  }, [onVerify]);

  useEffect(() => {
    renderWidget();
    return removeWidget;
  }, [removeWidget, renderWidget]);

  return (
    <div
      ref={turnstileRef}
      data-language={i18n.language}
      data-sitekey={process.env.REACT_APP_CLOUDFLARE_MANAGED_WIDGET_SITE_KEY}
    />
  );
}
