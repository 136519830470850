// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {getReferralCode} from 'api/server';
import {ReactComponent as GiftIcon} from 'assets/svg/3.0/Gift.svg';
import classNames from 'classnames';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useUserContext} from 'contexts/UserContext';
import {copy} from 'lib/copy';
import {LoadingPrompt} from 'pages/components/LoadingPrompt';
import {useCallback, useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {REFERRAL_PATH} from 'utils/path';

import styles from './ReferralPanel.module.scss';
import {Props} from './ReferralPanel.types';

function getUserReferralPath(referralCode: string) {
  return `${
    process.env.REACT_APP_ENV === 'dev'
      ? location.origin
      : process.env.REACT_APP_STORYTELLER_URL
  }${REFERRAL_PATH}?code=${referralCode}`;
}

export function ReferralPanel({onClose}: Props) {
  const {showNotification} = useNotificationContext();
  const {recordEvent} = useAnalysis();
  const {
    userInfo: {referralCode},
    updateUserInfo,
  } = useUserContext();

  const {t} = useTranslation('workspace');

  const onCopy = useCallback(
    async (referralCode: string) => {
      try {
        await copy(getUserReferralPath(referralCode));
        recordEvent('Referral');
        showNotification({type: 'SUCCESS', message: 'Copied'});
      } catch {
        showNotification({type: 'ERROR', message: 'Copy failed'});
      }
    },
    [showNotification, recordEvent]
  );

  const initReferralCode = useCallback(async () => {
    try {
      const {data: code} = await getReferralCode();
      updateUserInfo({referralCode: code});
    } catch {
      onClose();
    }
  }, [onClose, updateUserInfo]);

  useEffect(() => {
    if (!referralCode) {
      initReferralCode();
    }
  }, [initReferralCode, referralCode]);

  return (
    <div
      className={classNames(styles.container, !referralCode && styles.center)}
    >
      {!referralCode && <LoadingPrompt type="processing" />}
      {referralCode && (
        <>
          <GiftIcon className={styles.gift} />
          <p className={styles.title}>
            <Trans i18nKey="Invite a friend, get 100 credits!" ns="workspace">
              Invite a friend, get <span> 100 credits!</span> 💰
            </Trans>
          </p>
          <p className={styles.content}>
            {t(
              "Share this link with a friend. You'll both earn 100 credits (valid for 2 billing cycles, max 10,000 reward credits) when your friends signs up."
            )}
          </p>
          <div className={styles['input-box']}>
            <input
              className={styles.input}
              readOnly
              value={getUserReferralPath(referralCode)}
            />
            <button
              className={styles.button}
              onClick={() => onCopy(referralCode)}
            >
              {t('Copy')}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
