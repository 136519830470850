// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useEffect} from 'react';

export function useKeyDown(
  keys: string[],
  callback?: (event: KeyboardEvent) => void
) {
  useEffect(() => {
    if (!callback) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (keys.includes(event.key)) {
        callback(event);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [keys, callback]);
}
