// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Effect} from 'api/server';
import {Size, Tone} from 'modules/project/types';
import {Avatar} from 'modules/project-config/types';

import {BasePreference} from './BasePreference';

export class ShortVideoPreference extends BasePreference {
  static fromJSON(json: Record<string, any>): ShortVideoPreference {
    return new ShortVideoPreference(
      json.size,
      json.language,
      json.style,
      json.paragraphAsShots,
      json.tone,
      json.titleStyle,
      json.subtitleStyle,
      json.effect,
      json.transition,
      json.bgm,
      json.voiceover,
      json.voiceoverSpeed,
      json.avatar
    );
  }
  constructor(
    size?: Size,
    language?: string,
    readonly style?: string,
    readonly paragraphAsShots?: boolean,
    readonly tone?: Tone,
    readonly titleStyle?: string,
    readonly subtitleStyle?: string,
    readonly effect?: Effect,
    readonly transition?: string,
    readonly bgm?: string,
    readonly voiceover?: string,
    readonly voiceoverSpeed?: 'normal' | 'fast',
    readonly avatar?: Avatar
  ) {
    super(size, language);
  }
  patch(data: Partial<ShortVideoPreference>): ShortVideoPreference {
    const {
      size,
      language,
      style,
      paragraphAsShots,
      tone,
      titleStyle,
      subtitleStyle,
      effect,
      transition,
      bgm,
      voiceover,
      voiceoverSpeed,
      avatar,
    } = Object.assign({}, this, data);
    return new ShortVideoPreference(
      size,
      language,
      style,
      paragraphAsShots,
      tone,
      titleStyle,
      subtitleStyle,
      effect,
      transition,
      bgm,
      voiceover,
      voiceoverSpeed,
      avatar
    );
  }
}
