// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/no-unescaped-entities */

import classNames from 'classnames';
import {TurnstileBox} from 'components/TurnstileBox';
import {Button} from 'pages/components/Button';
import {GlassInputBox} from 'pages/components/GlassInputBox/GlassInputBox';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {EmailErrorMessage, PasswordErrorMessage} from './AuthForm.module';
import styles from './AuthForm.module.scss';
import {Props} from './AuthForm.types';
import {TermsAndPolicy} from './TermsAndPolicy';

export function AuthForm({
  authType,
  email,
  password,
  emailErrorType,
  passwordErrorType,
  confirmPassword,
  disabled,
  verifyAllInput,
  setEmail,
  setPassword,
  onConfirm,
  setConfirmPassword,
  onForgotPassword,
  onCancel,
}: Props) {
  const [isCaptchaShowing, setIsCaptchaShowing] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);
  const focusPasswordInput = () => {
    const passwordInput = passwordInputRef.current;
    if (!passwordInput) return;
    passwordInput.focus();
  };
  const focusConfirmPasswordInput = () => {
    const confirmPasswordInput = confirmPasswordInputRef.current;
    if (!confirmPasswordInput) return;
    confirmPasswordInput.focus();
  };

  const {t} = useTranslation('auth');

  const onLogin = useCallback(async () => {
    setIsVerifying(true);
    const isPass = await verifyAllInput();
    if (isPass) await onConfirm();
    setIsVerifying(false);
  }, [onConfirm, verifyAllInput]);

  const onSignup = useCallback(async () => {
    setIsVerifying(true);
    const isPass = await verifyAllInput();
    if (isPass) setIsCaptchaShowing(true);
    setIsVerifying(false);
  }, [verifyAllInput]);

  useEffect(() => {
    if (authType !== 'signup') {
      setIsCaptchaShowing(false);
    }
  }, [authType]);

  useEffect(() => {
    setIsCaptchaShowing(false);
    setIsVerifying(false);
  }, [email, password, confirmPassword]);

  return (
    <div className={styles.auth_form_container}>
      <GlassInputBox
        disabled={disabled}
        className={classNames(styles.email_input, styles.input, 'body-m')}
        placeholder="example@email.com"
        onKeyUp={e => e.key === 'Enter' && focusPasswordInput()}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />

      <GlassInputBox
        disabled={disabled}
        className={classNames(styles.password_input, styles.input, 'body-m')}
        placeholder={t('password (at least 8 characters)')}
        ref={passwordInputRef}
        onKeyUp={e =>
          e.key === 'Enter' &&
          (authType === 'login' ? onLogin() : focusConfirmPasswordInput())
        }
        value={password}
        onChange={e => setPassword(e.target.value)}
        type="password"
      />
      {authType === 'signup' && (
        <GlassInputBox
          disabled={disabled}
          className={classNames(styles.password_input, styles.input, 'body-m')}
          placeholder={t('confirm password')}
          ref={confirmPasswordInputRef}
          onKeyUp={e => e.key === 'Enter' && onSignup()}
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          type="password"
        />
      )}
      {emailErrorType !== null && (
        <EmailErrorMessage
          errorType={emailErrorType}
          className={styles.error_message}
        />
      )}
      {emailErrorType === null && passwordErrorType !== null && (
        <PasswordErrorMessage
          errorType={passwordErrorType}
          className={styles.error_message}
        />
      )}

      {authType === 'signup' && (
        <div className={styles.agree}>
          <div>{t("By signing up,  I agree with Mootion's")}&nbsp;</div>
          <TermsAndPolicy linkString="|" />
        </div>
      )}
      {authType === 'signup' && isCaptchaShowing && (
        <TurnstileBox onVerify={onConfirm} />
      )}

      {authType === 'login' && (
        <div className={classNames(styles.forgotPassword)}>
          <Button
            size="small"
            type="text"
            onClick={onForgotPassword}
            disabled={disabled}
          >
            {t('Forgot password?')}
          </Button>
        </div>
      )}
      {disabled && (
        <div className={classNames(styles.signupBox)}>
          <Button type="button" size="large" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </div>
      )}
      {!disabled && authType === 'signup' && !isCaptchaShowing && (
        <div className={classNames(styles.signupBox)}>
          <Button
            type="button"
            size="large"
            isLoading={isVerifying}
            icon={<></>}
            onClick={onSignup}
            disabled={
              emailErrorType !== null ||
              passwordErrorType !== null ||
              isVerifying
            }
          >
            {t('Sign up')}
          </Button>
        </div>
      )}
      {!disabled && authType === 'login' && (
        <div className={classNames(styles.loginBox)}>
          <Button
            type="button"
            size="large"
            isLoading={isVerifying}
            icon={<></>}
            onClick={onLogin}
            disabled={
              emailErrorType !== null ||
              passwordErrorType !== null ||
              isVerifying
            }
          >
            {t('Log in')}
          </Button>
        </div>
      )}
    </div>
  );
}
