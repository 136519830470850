// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';

import {ObjectEditor} from './ObjectEditor/ObjectEditor';
import styles from './ObjectsEditor.module.scss';
import {ObjectsEditorProps} from './ObjectsEditor.type';

export function ObjectsEditor({
  onLoad,
  className,
  onAction,
  disabled,
  outerHeight,
  outerWidth,
  draft,
  updateObject,
  getImageWithObjectKey,
  refreshImage,
  forceLoading,
  outerEl,
  animating,
  background,
  previewOnly,
}: ObjectsEditorProps) {
  const [selectedObject, setSelectedObject] = useState<number | null>(
    draft.objects.length === 1 && !draft.objects[0].isValid() ? 0 : null
  );
  const [isXLineShowing, setIsXLineShowing] = useState(false);
  const [isYLineShowing, setIsYLineShowing] = useState(false);

  const prevObjectsLength = useRef(draft.objects.length);
  const backgroundType = background.startsWith('#') ? 'color' : 'image';

  useEffect(() => {
    if (prevObjectsLength.current > draft.objects.length) {
      setSelectedObject(null);
    } else if (prevObjectsLength.current < draft.objects.length) {
      // 新增后选中
      setSelectedObject(draft.objects.length - 1);
    }
    prevObjectsLength.current = draft.objects.length;
  }, [draft]);

  if (!draft) return null;

  return (
    <div
      className={classNames(styles['objects-editor'], className)}
      style={{
        background: backgroundType === 'color' ? background : undefined,
      }}
    >
      {animating && <div className={styles['objects-editor-mask']}></div>}
      {backgroundType === 'image' && background && (
        <img
          className={styles.preview}
          src={staticCombiner(background)}
          onLoad={onLoad}
        />
      )}
      <span
        className={classNames(styles.line, styles.x, {
          [styles.show]: isXLineShowing,
        })}
      ></span>
      <span
        className={classNames(styles.line, styles.y, {
          [styles.show]: isYLineShowing,
        })}
      ></span>
      {draft.objects.map((object, index) => (
        <ObjectEditor
          key={index}
          object={object}
          active={!previewOnly && selectedObject === index}
          disabled={disabled}
          onClick={() => setSelectedObject(index)}
          onClickOutSide={() => {
            setSelectedObject(null);
          }}
          onAction={(type, object) => {
            selectedObject !== null && onAction?.(type, object, selectedObject);
          }}
          updateObject={(object, immediately) => {
            !disabled && updateObject?.(index, object, immediately);
          }}
          outerWidth={outerWidth}
          outerHeight={outerHeight}
          getImageWithObjectKey={getImageWithObjectKey}
          refreshImage={refreshImage}
          forceLoading={forceLoading === index}
          outerEl={outerEl}
          setIsXLineShowing={setIsXLineShowing}
          setIsYLineShowing={setIsYLineShowing}
        />
      ))}
    </div>
  );
}
