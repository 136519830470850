// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useFixedRef} from 'lib/hooks';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

export enum ErrorType {
  Network = 'Network',
  Token = 'Token', // 401
  NoCredit = 'NoCredit', // 402
  CapacityLimiting = 'CapacityLimiting', // 503(ai-frontend)
  Service = 'Service', // 500+
  Unknown = 'Unknown',
  NoCaptchaToken = 'NoCaptchaToken',
  ContentViolation = 'ContentViolation',
}

export class AppError extends Error {
  constructor(
    type: ErrorType,
    cause?: unknown,
    readonly callback?: () => void
  ) {
    super(type, {cause});
    this.name = 'AppError';
  }

  get type() {
    return this.message as ErrorType;
  }
}

interface Value {
  error: AppError | null;
  report: (type: ErrorType, cause?: unknown, callback?: () => void) => AppError;
  clear: () => void;
}

const errorContext = createContext<Value>({
  error: null,
  report: (type: ErrorType, cause?: unknown, callback?: () => void) =>
    new AppError(type, cause, callback),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clear: () => {},
});

export function useError() {
  return useContext(errorContext);
}

const ERROR_POLICY: {
  [K in ErrorType]?: {
    minCount: number;
    reportInterval: number;
    displayInterval: number;
  };
} = {
  [ErrorType.Network]: {
    minCount: 3,
    reportInterval: 60000,
    displayInterval: 60000,
  },
};

function useBuffer() {
  const buffer = useFixedRef<{
    [K in ErrorType]?: {
      count: number;
      lastReport?: number;
      lastDisplay?: number;
    };
  }>(() => ({}));

  /**
   * @returns {boolean} should display the error
   */
  const updateBuffer = useCallback(
    (type: ErrorType): boolean => {
      if (!ERROR_POLICY[type]) return true;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const {minCount, reportInterval, displayInterval} = ERROR_POLICY[type]!;
      const {count, lastReport, lastDisplay} = buffer[type] ?? {
        count: 0,
      };
      const now = Date.now();
      const nextCount =
        !lastReport || now - lastReport < reportInterval ? count + 1 : 1;
      if (
        nextCount < minCount ||
        (lastDisplay && now - lastDisplay < displayInterval)
      ) {
        buffer[type] = {count: nextCount, lastReport: now, lastDisplay};
        return false;
      } else {
        buffer[type] = {count: 0, lastDisplay: now};
        return true;
      }
    },
    [buffer]
  );

  return {updateBuffer};
}

export function ErrorProvider({children}: PropsWithChildren) {
  const [error, setError] = useState<AppError | null>(null);
  const {updateBuffer} = useBuffer();

  const report = useCallback(
    (type: ErrorType, cause?: unknown, callback?: () => void) => {
      const error = new AppError(type, cause, callback);
      if (updateBuffer(type)) {
        setError(error);
      }
      return error;
    },
    [updateBuffer]
  );

  const clear = useCallback(() => setError(null), []);

  return (
    <errorContext.Provider value={{error, report, clear}}>
      {children}
    </errorContext.Provider>
  );
}
