// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {JSONUtils} from 'lib/JSONUtils';
import {Ratio} from 'lib/ratio';
import {PlanType} from 'modules/payment/types';
import {
  emailStatusResponse,
  GoogleLoginResponse,
  LoginRequest,
  LoginResponse,
  SignUpRequest,
  verifyDiscordCodeResponse,
} from 'pages/AuthPage/modules/types';
import {
  AssetCreateResponse,
  AssetItemType,
  createAssestRequestType,
  GetProjectResponse,
  ProjectItemListResponse,
  updateAssestStateType,
} from 'pages/WorkspacePage/WorkspacePage.types';

import {axiosFactory} from './axiosFactory';

export const SUCCESS_STATUS_CODE = 200;

export enum ResponseCode {
  USER_NOT_FOUND = 10001,
  TOKEN_INVALID = 10004,
  EMAIL_CONFIRM_TOKEN_INVALID = 10005,
  PASSCODE_NOT_MATCH = 20001,
  REQUEST_PARAM_NOT_MATCH = 20002,
  FILE_NOT_BELONG_TO_USER = 30001,
  FILE_NOT_FOUND = 30002,
  JWT_SIGN_ERROR = 30003,
  USER_RESOURCE_INFO_CREATE_ERROR = 30004,
  USER_RESOURCE_SIZE_NOT_MATCH = 30005,
  USER_RESOURCE_STATUS_UPDATE_ERROR = 30006,
  EMAIL_HAD_REGISTER_YET = 30007,
  USER_REGISTER_ERROR = 30008,
  USER_FILE_CAN_NOT_BE_MODIFIED = 30009,
  GET_UPLOAD_FILE_SIGNED_URL_ERROR = 30010,
  USER_PROJECT_CREATE_ERROR = 30011,
  USER_PROJECT_NOT_EXIST = 30012,
  USER_ID_NOT_MATCH = 30013,
  INVITE_CODE_NOT_EXISTS = 30014,
  ASSET_NOT_FOUND = 30015,
  GETWAILLIST_EMAIL_NOT_SIGNUP = 70010,
  USER_ASSET_CAPACITY_FULL = 30033,
  USER_EMAIL_NOT_CONFIRM_YET = 30035,
  USER_EMAIL_NO_PASSWORD = 30036,
}
export interface Response {
  code: number;
  message: string | string[];
  error?: number;
}
export interface ResponseData<T> extends Response {
  data: T;
}

export const serverApi = axiosFactory({
  baseURL: process.env.REACT_APP_API_URL as string,
});

//=========================project start=========================

interface CreateProjectParams {
  frameRatio: Ratio;
  projectJsonContent: string;
  lang?: string;
}

interface UpdateProjectParams {
  projectId: string;
  projectName?: string;
  thumbnailUrl?: string;
  frameRatio?: string;
  projectJsonContent?: string; // json string
  history?: number;
  lang?: string;
}

export async function getProject(
  projectId: string
): Promise<GetProjectResponse> {
  return serverApi.get(`/story/projects/${projectId}`);
}

export async function createProject(
  params: CreateProjectParams
): Promise<ResponseData<string>> {
  return serverApi.post('/story/projects', params);
}

export async function updateProject({
  projectId,
  ...params
}: UpdateProjectParams): Promise<ResponseData<boolean>> {
  return serverApi.put(`/story/projects/${projectId}`, {projectId, ...params});
}

export function getRecentProjectList(): Promise<
  ResponseData<ProjectItemListResponse>
> {
  return serverApi.get('/story/projects/recent?limit=10');
}
export function getMyProjectList(
  page: number,
  pageSize: number
): Promise<ResponseData<ProjectItemListResponse>> {
  return serverApi.get('/story/projects', {params: {page, pageSize}});
}

export function deleteProjectById(projectId: string): Promise<''> {
  return serverApi.delete('/story/projects/' + projectId);
}

export function duplicateProjectById(
  projectId: string
): Promise<ResponseData<string>> {
  return serverApi.post('/story/projects/duplicate', {
    projectId,
  });
}

//=========================project end=========================

//=========================gallery start=========================
export function getGalleryById(galleryId: string): Promise<{
  galleryId: string;
  galleryName: string;
  thumbnailUrl: string;
  frameRatio: string;
  galleryJsonContent: string; // json string
  prompt: string;
} | null> {
  return serverApi.get('/story/galleries/' + galleryId).then(res => {
    return JSONUtils.parse<{
      galleryId: string;
      galleryName: string;
      thumbnailUrl: string;
      frameRatio: string;
      galleryJsonContent: string; // json string
      prompt: string;
    }>(res.data?.galleryJsonContent);
  });
}

//=========================gallery end=========================

//=========================资源相关 start=========================

export async function getAssetList(
  page: number,
  pageSize: number,
  type: 'image' = 'image'
): Promise<ResponseData<AssetItemType[]>> {
  const res: ResponseData<AssetItemType[]> = await serverApi.get(
    '/story/assets',
    {params: {page, pageSize, type}}
  );
  return res;
}

export function createAssest(
  data: createAssestRequestType
): Promise<ResponseData<AssetCreateResponse>> {
  return serverApi.post('/story/assets', data);
}

export function updateAssestState(
  assetId: updateAssestStateType['assetId'],
  data: updateAssestStateType['data']
): Promise<ResponseData<AssetCreateResponse>> {
  return serverApi.put('/story/assets/' + assetId, data);
}

type getSignedUrlRequestType = {fileName: string}[];
export function getSignedUrl(
  unSignedUrls: getSignedUrlRequestType
): Promise<ResponseData<string[]>> {
  return serverApi.post('/story/assets/generate-signed-urls', unSignedUrls);
}

export function deleteAsset(assetId: string): Promise<''> {
  return serverApi.delete('/story/assets/' + assetId);
}
//=========================资源相关 end=========================

//=======================登录相关 start=======================

export function login(
  loginData: LoginRequest
): Promise<ResponseData<LoginResponse>> {
  return serverApi.post('/auth/login', loginData);
}
export function logOut(): Promise<ResponseData<undefined>> {
  return serverApi.delete('/auth/logout');
}
type SignUpResponseType = {
  email?: string;
  accountMergeStatus?: boolean;
};
export function signUp(
  signUpData: SignUpRequest,
  captchaToken: string
): Promise<ResponseData<SignUpResponseType>> {
  return serverApi.post('/users/v2', signUpData, {
    headers: {'cf-captcha-token': captchaToken},
  });
}
export function isUserCanSignup(
  email: string
): Promise<ResponseData<emailStatusResponse>> {
  return serverApi.post('/users/email-status', {email}).then(res => {
    return res.data.isAvailable;
  });
}

export function verifyDiscordCode(
  code: string
): Promise<ResponseData<verifyDiscordCodeResponse>> {
  return serverApi.get(
    `/auth/discord-login/callback?state=storyteller&code=${code}`
  );
}
export function googleAccount(
  accessToken: string,
  email: string,
  userName: string
): Promise<ResponseData<GoogleLoginResponse>> {
  return serverApi.post('/auth/google-login', {
    accessToken,
    email,
    userName,
  });
}

export function sendEmail(email: string): Promise<ResponseData<undefined>> {
  return serverApi.post('/auth/reset-password', {email});
}
// 通过邮件验证重置密码
export function doResetPasswordByEmail(data: {
  email: string;
  password: string;
  confirmPassword: string;
  token: string;
}): Promise<ResponseData<undefined>> {
  return serverApi.patch('/auth/reset-password', data);
}
//用户注册邮箱确认
export function loginWithEmailAndToken(
  email: string,
  token: string
): Promise<ResponseData<LoginResponse>> {
  return serverApi.post('/users/email-confirm', {email, token});
}
//=======================登录相关 end=======================

//=======================account start=======================
export function getAccountDetails(): Promise<GetAccountDetailsResponseType> {
  return serverApi
    .get('/users/subscriptions/account-details')
    .then(res => res.data);
}
export type GetAccountDetailsResponseType = {
  planType: PlanType;
  planCreditAmount: number;
  creditPackAmount: number;
  creditWalletAmount: number;
};

export type getUserInfoByTokenResponseType = {
  userId: string;
  userName: string;
  portrait: string;
  email: string;
  googleIdentity: boolean;
  emailConfirmStatus: boolean;
  lang: string;
};
export function getUserInfoByToken(): Promise<getUserInfoByTokenResponseType> {
  return serverApi.get('/users/me').then(res => res.data);
}
export function changePassword(data: {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}): Promise<ResponseData<undefined>> {
  return serverApi.put('/users/me/password', data);
}
//=======================account end=======================

//=======================preferences start=======================

export type Effect =
  | null
  | string
  | {
      type: string;
      ratio: number;
    };

//=======================preferences end=======================

//=======================referral start=======================

export function getReferralCode(): Promise<ResponseData<string>> {
  return serverApi.get('/invite-codes/mine');
}

export function recordReferralCode(referralCode: string) {
  return serverApi.post(`/invite-codes/${referralCode}/activations`);
}

export function recordReferralCodeAndEmail(
  referralCode: string,
  email: string
) {
  return serverApi.post('/invite-codes/activations/email', {
    email,
    inviteCode: referralCode,
  });
}

//=======================referral end=======================

//=======================埋点相关 start=====================

export function reportEvent(event: string) {
  return serverApi.post(`/stat/user/${event}`);
}

//=======================埋点相关 end=======================

//=======================Affiliates start=====================

export function bindInviter(via: string): Promise<void> {
  return serverApi.post('/rewardful/register', {via});
}

export function verifyInvitation(): Promise<
  ResponseData<{validateStatus: boolean}>
> {
  return serverApi.post('/rewardful/verify');
}

export function recordAffiliatePayment(outTradeNo: string) {
  return serverApi.post('/rewardful/purchase', {outTradeNo});
}

//=======================Affiliates end=======================
//=======================Ads相关 start=====================

export function recordGoogleCampaignId(campaignId: string) {
  return serverApi.post('/google-analytics/user/register', {
    campaignId,
  });
}

export function recordTiktokCampaignId(campaignId: string) {
  return serverApi.post('/tiktok/user/register', {campaignId});
}

//=======================埋点相关 end=======================

export function saveLog(type: 'exceptions', data: unknown) {
  return serverApi.post(`/logs/${type}/client`, data, {
    headers: {'Content-Type': 'text/plain'},
  });
}
