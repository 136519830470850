// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ProjectType} from 'modules/project/types';

import {BilingualDialogueConstraint} from './BilingualDialogueConstraint';
import {BilingualStoryConstraint} from './BilingualStoryConstraint';
import {GeneralStoryConstraint} from './GeneralStoryConstraint';
import {HolidayGreetingConstraint} from './HolidayGreetingConstraint';
import {ShortVideoConstraint} from './ShortVideoConstraint';

export type Constraint<T extends ProjectType> = T extends 'general_story'
  ? GeneralStoryConstraint
  : T extends 'holiday_greeting'
  ? HolidayGreetingConstraint
  : T extends 'short_video'
  ? ShortVideoConstraint
  : T extends 'bilingual_story'
  ? BilingualStoryConstraint
  : T extends 'bilingual_dialogue'
  ? BilingualDialogueConstraint
  : never;

export function patch<P extends Constraint<ProjectType>>(
  constraint: P,
  data: Partial<P>
): P {
  if (constraint instanceof GeneralStoryConstraint) {
    return constraint.patch(data as Partial<GeneralStoryConstraint>) as P;
  } else if (constraint instanceof HolidayGreetingConstraint) {
    return constraint.patch(data as Partial<HolidayGreetingConstraint>) as P;
  } else if (constraint instanceof ShortVideoConstraint) {
    return constraint.patch(data as Partial<ShortVideoConstraint>) as P;
  } else if (constraint instanceof BilingualStoryConstraint) {
    return constraint.patch(data as Partial<BilingualStoryConstraint>) as P;
  } else if (constraint instanceof BilingualDialogueConstraint) {
    return constraint.patch(data as Partial<BilingualDialogueConstraint>) as P;
  } else {
    throw new Error(`Unknown constraint type: ${constraint}`);
  }
}
