// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Button} from 'components/Button';
import {useUserContext} from 'contexts/UserContext';
import {getFileCapacityLimitByPlan} from 'modules/user-asset/utils';
import {Modal} from 'pages/components/Modal';
import {Toast} from 'pages/components/Toast';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ErrorType} from '../AssetsLibrary/AssetsLibrary.type';
import {ImageGrid} from './ImageGrid';
import {ImageGridInModalProps} from './ImageGrid.type';
import styles from './ImageGridInModal.module.scss';

export const ImageGridInModal = function ({
  visible,
  hideModal,
  handleClickAssets,

  tempList,
  assetItemList,
  deleteConfirmVisible,
  isAssetsFetchLoading,
  loadMoreRef,
  hasMore,
  errorToastVisible,
  isMultiple,
  maxQuantity,
  deleteAssetById,
  refreshAssetList,
  hideDeleteConfirmModal,
  onConfirmDelete,
  handleUploadTempList,
  handleImageError,
  handleUploadFileAndEditAsset,

  errorToastType,
  hideErrorToast,
  onOk,
}: ImageGridInModalProps) {
  const {
    userInfo: {plan},
  } = useUserContext();
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);

  const {t} = useTranslation('project');

  let toastInfo = null;
  if (errorToastVisible) {
    switch (errorToastType) {
      case ErrorType.Capacity:
        toastInfo = {
          title: t('Upload failed'),
          content: t('All files storage capacity is limit.', {
            limit: getFileCapacityLimitByPlan(plan),
          }),
          showCancel: false,
          confirmText: t('OK'),
          onOk: hideErrorToast,
        };
        break;
      case ErrorType.UploadFailed:
        toastInfo = {
          title: t('Upload failed'),
          content: t('Upload failed, please try again.'),
          showCancel: false,
          confirmText: t('OK'),
          onOk: hideErrorToast,
        };
        break;
    }
  }
  return (
    <>
      <Modal
        visible={visible}
        title={t('Choose asset')}
        className={styles.imageGridInModal}
        onCancel={hideModal}
        maskClosable={false}
        footer={
          isMultiple ? (
            <div className={styles.footer}>
              <Button type="ghost" onClick={hideModal}>
                {t('Cancel')}
              </Button>
              <Button
                type="button"
                theme="primary"
                disabled={selectedAssetIds.length === 0}
                onClick={() => {
                  hideModal && hideModal();
                  onOk && onOk(selectedAssetIds);
                }}
              >
                {t('OK')}
              </Button>
            </div>
          ) : null
        }
      >
        <div className={styles.imageGridWrap}>
          <ImageGrid
            isInModal={true}
            isMultiple={isMultiple}
            maxQuantity={maxQuantity}
            onChangeSelectedAssetIds={setSelectedAssetIds}
            tempList={tempList}
            assetItemList={assetItemList}
            deleteConfirmVisible={deleteConfirmVisible}
            deleteAssetById={deleteAssetById}
            refreshAssetList={refreshAssetList}
            hideDeleteConfirmModal={hideDeleteConfirmModal}
            onConfirmDelete={onConfirmDelete}
            handleUploadTempList={handleUploadTempList}
            handleImageError={handleImageError}
            loadMoreRef={loadMoreRef}
            hasMore={hasMore}
            isAssetsFetchLoading={isAssetsFetchLoading}
            uploadFileAndEditAsset={handleUploadFileAndEditAsset}
            handleClickAssets={handleClickAssets}
          />
        </div>
      </Modal>
      {toastInfo && (
        <Toast
          title={toastInfo.title}
          visible={true}
          showCloseBtn={false}
          onOk={toastInfo.onOk}
          showCancel={toastInfo.showCancel}
          confirmText={toastInfo.confirmText}
        >
          {toastInfo.content}
        </Toast>
      )}
    </>
  );
};
