// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CoinIcon} from 'assets/svg/3.0/Coin.svg';
import {ReactComponent as RegenerateIcon} from 'assets/svg/3.0/Regenerate.svg';
import classNames from 'classnames';
import {Button} from 'pages/components/Button';
import {Switch} from 'pages/components/Switch';
import {UnityCanvas} from 'pages/WorkspacePage/UnityCanvas';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './Generator.module.scss';
import {Props} from './Generator.types';
import {GeneratorToast} from './GeneratorToast';
import {PoseGenerator} from './PoseGenerator';
import {PromptEditor} from './PromptEditor';
import {SceneSetting} from './SceneSetting';

export function Generator({
  selectedSceneIndex,
  prompt,
  onPromptChange,
  posePrompt,
  containerRef,
  switchValue,
  onChangeSwitch,
  poseList,
  activePose,
  resUrl,
  onChangePosePrompt,
  onGeneratePoseJson,
  changeActivePose,
  unityCallback,
  popoverVisibleMap,
  updatePopoverVisibleMap,
  settingInfo,
  onChangeSceneSetting,
  onClose,
  handleRegenerate,
  errorToastType,
  errorDialogVisible,
  hideErrorDialog,
  onRegenerate,
}: Props) {
  const promptBodyRef = useRef<HTMLDivElement | null>(null);

  const {t} = useTranslation('project');

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, {[styles.isLarge]: switchValue})}
    >
      <p className={styles.head}>
        <RegenerateIcon className={styles.icon} />
        <span>{t('Regenerate')}</span>
      </p>
      <div className={styles.body} ref={promptBodyRef}>
        <div className={styles.leftPanel}>
          <PromptEditor
            styles={styles}
            selectedSceneIndex={selectedSceneIndex}
            prompt={prompt}
            onPromptChange={onPromptChange}
            switchValue={switchValue}
          />
          <div className={styles.poseContainer}>
            <div className={styles.label}>
              <p className={styles.title}>{t('Pose reference')}</p>
              <Switch
                isDisabled
                value={switchValue}
                onChange={onChangeSwitch}
              />
            </div>
            <PoseGenerator
              styles={styles}
              switchValue={switchValue}
              posePrompt={posePrompt}
              poseList={poseList}
              activePose={activePose}
              resUrl={resUrl}
              onChangePosePrompt={onChangePosePrompt}
              onGeneratePoseJson={onGeneratePoseJson}
              changeActivePose={changeActivePose}
            />
          </div>
        </div>
        <div
          className={classNames(styles.rightPanel, {
            [styles.isHidden]: !switchValue,
          })}
        >
          <div className={classNames(styles.label, styles.posePreviewLabel)}>
            <p className={styles.title}>{t('Pose preview')}</p>
          </div>
          <div className={styles.label}>
            <p className={styles.title}>{t('Pose reference')}</p>
            <Switch isDisabled value={switchValue} onChange={onChangeSwitch} />
          </div>
          <div className={styles.canvasWrap}>
            <UnityCanvas
              className={styles.canvas}
              unityCallback={unityCallback}
            ></UnityCanvas>
          </div>
          <SceneSetting
            styles={styles}
            promptBodyRef={promptBodyRef}
            popoverVisibleMap={popoverVisibleMap}
            updatePopoverVisibleMap={updatePopoverVisibleMap}
            settingInfo={settingInfo}
            onChangeSceneSetting={onChangeSceneSetting}
          />
        </div>
        <div className={styles.bottomPanel}>
          <div>
            <div className={styles.label}>
              <p className={styles.title}>{t('Pose')}</p>
            </div>
            <PoseGenerator
              styles={styles}
              switchValue={switchValue}
              posePrompt={posePrompt}
              poseList={poseList}
              activePose={activePose}
              resUrl={resUrl}
              onChangePosePrompt={onChangePosePrompt}
              onGeneratePoseJson={onGeneratePoseJson}
              changeActivePose={changeActivePose}
            />
          </div>
        </div>
      </div>

      <div className={styles['button-box']}>
        <Button size="middle" type="ghost" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          size="middle"
          theme="primary"
          type="button"
          onClick={handleRegenerate}
          disabled={prompt.length === 0}
        >
          {t('Regenerate')}
          <span className={styles.credits}>
            <CoinIcon className={styles.icon} /> 2
          </span>
        </Button>
      </div>
      <GeneratorToast
        errorDialogVisible={errorDialogVisible}
        errorToastType={errorToastType}
        hideErrorDialog={hideErrorDialog}
        onRegenerate={onRegenerate}
      />
    </div>
  );
}
